import request from '@/utils/request.js'
// 获取字典数据
export function getDictData(type) {
    return new Promise((resolve, reject) => {
        request({
            url: '/system/dict/data/type/' + type, method: 'get',
        }).then((val) => {
            let data = {};
            for (let item of val.data) {
                data[item.dictValue] = item.dictLabel
            }
            resolve(data);
        })
    })

}
