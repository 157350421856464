<template>
  <div>
    <div class="x-tc-cnt flex flex-wrap">

      <div class="x-card margin-top">
        <div class="x-sm-title-bg">
          叶绿素浓度(ug/L)
        </div>
        <div class="x-card-cnt" ref="yeLvSeRef"></div>
      </div>

      <div class="x-card margin-top">
        <div class="x-sm-title-bg">
          总氮(mg/L)
        </div>
        <div class="x-card-cnt" ref="zongDanRef"></div>
      </div>

      <div class="x-card margin-top">
        <div class="x-sm-title-bg">
          总磷(mg/L)
        </div>
        <div class="x-card-cnt" ref="zongLingRef"></div>
      </div>


      <div class="x-card margin-top">
        <div class="x-sm-title-bg">
          悬浮物浓度(mg/L)
        </div>
        <div class="x-card-cnt" ref="xuanFuWuRef"></div>
      </div>

      <div class="x-card">
        <div class="x-sm-title-bg">
          浊度(NTU)
        </div>
        <div class="x-card-cnt" ref="zhuoDuRef"></div>
      </div>

      <div class="x-card">
        <div class="x-sm-title-bg">
          水温(℃)
        </div>
        <div class="x-card-cnt" ref="shuiWenRef"></div>
      </div>

      <div class="x-card">
        <div class="x-sm-title-bg">
          气温(℃)
        </div>
        <div class="x-card-cnt" ref="qiWenRef"></div>
      </div>

      <div class="x-card">
        <div class="x-sm-title-bg">
          高锰酸盐指数(mg/L)
        </div>
        <div class="x-card-cnt" ref="gaoMengSuanJiaRef"></div>
      </div>

      <div class="x-card">
        <div class="x-sm-title-bg">
          CDOM吸收系数(m-1)
        </div>
        <div class="x-card-cnt" ref="CDOMRef"></div>
      </div>

      <div class="x-card">
        <div class="x-sm-title-bg">
          透明度(m)
        </div>
        <div class="x-card-cnt" ref="touMingDuRef"></div>
      </div>

      <div class="x-card">
        <div class="x-sm-title-bg">
          氨氮(mg/L)
        </div>
        <div class="x-card-cnt" ref="anDanRef"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "x-real-time",
  props: [],
  data() {
    return {}
  },
  created() {

  },
  mounted() {
    let frontOneHour = new Date(new Date().getTime());
    frontOneHour = this.$xUtil.parseTime(frontOneHour, '{h}:{i}');
    let frontOneHour_2 = new Date(new Date().getTime() - 2 * 60 * 60 * 1000);
    frontOneHour_2 = this.$xUtil.parseTime(frontOneHour_2, '{h}:{i}');
    let frontOneHour_4 = new Date(new Date().getTime() - 4 * 60 * 60 * 1000);
    frontOneHour_4 = this.$xUtil.parseTime(frontOneHour_4, '{h}:{i}');
    let frontOneHour_6 = new Date(new Date().getTime() - 6 * 60 * 60 * 1000);
    frontOneHour_6 = this.$xUtil.parseTime(frontOneHour_6, '{h}:{i}');
    let frontOneHour_8 = new Date(new Date().getTime() - 8 * 60 * 60 * 1000);
    frontOneHour_8 = this.$xUtil.parseTime(frontOneHour_8, '{h}:{i}');
    let frontOneHour_10 = new Date(new Date().getTime() - 10 * 60 * 60 * 1000);
    frontOneHour_10 = this.$xUtil.parseTime(frontOneHour_10, '{h}:{i}');

    let keys = [frontOneHour_8, frontOneHour_6, frontOneHour_4, frontOneHour_2, frontOneHour];
    this.initEchartsRef(this.$refs.yeLvSeRef, keys, [10, 10.1, 10.5, 11,10.4]);
    this.initEchartsRef(this.$refs.zongDanRef, keys, [3, 3.1, 3.5, 3,3.4]);
    this.initEchartsRef(this.$refs.zongLingRef, keys, [0.1, 0.1, 0.12, 0.13, 0.14]);
    this.initEchartsRef(this.$refs.xuanFuWuRef, keys, [40.1, 42.1, 50.12, 42.13, 41.14]);

    this.initEchartsRef(this.$refs.zhuoDuRef, keys, [60.1, 62.1, 50.12, 42.13, 41.14]);
    this.initEchartsRef(this.$refs.shuiWenRef, keys, [4.1, 4.1, 4.12, 4.13, 4.14]);
    this.initEchartsRef(this.$refs.qiWenRef, keys, [8.1, 8.1, 8.12, 8.13, 8.14]);
    this.initEchartsRef(this.$refs.gaoMengSuanJiaRef, keys, [4.1, 4.1, 4.12, 4.13, 4.14]);

    this.initEchartsRef(this.$refs.CDOMRef, keys, [0.09, 0.09, 0.09, 0.09, 0.09]);
    this.initEchartsRef(this.$refs.touMingDuRef, keys, [0.1, 0.2, 0.3, 0.3, 0.2]);
    this.initEchartsRef(this.$refs.anDanRef, keys, [0.3, 0.3, 0.2, 0.3, 0.3]);
  },
  destroyed() {
  },
  methods: {

    initEchartsRef(dom, xData, yData) {
      let myChart = this.$echarts.init(dom, null);
      let option = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          top: '10%',
          left: '0%',
          right: '10%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false, //坐标轴两边留白
          data: xData,
          axisLabel: { //坐标轴刻度标签的相关设置。
            interval: 0, //设置为 1，表示『隔一个标签显示一个标签』
            //	margin:15,
            textStyle: {
              color: '#eeeeee',
              fontStyle: 'normal',
              fontFamily: '微软雅黑',
              fontSize: 12,
            },
          },
          axisTick: { //坐标轴刻度相关设置。
            show: false,
          },
          axisLine: { //坐标轴轴线相关设置
            lineStyle: {
              color: '#CAFFFD',
              opacity: 0.3
            }
          },
          splitLine: { //坐标轴在 grid 区域中的分隔线。
            show: false,
            lineStyle: {
              color: '#E5E9ED',
              // 	opacity:0.1
            }
          }
        },
        yAxis: [{
          type: 'value',
          axisLabel: {
            textStyle: {
              color: '#EEEEEE',
              fontStyle: 'normal',
              fontFamily: '微软雅黑',
              fontSize: 12,
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#CAFFFD',
              opacity: 0.3
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: 'rgba(202, 255, 253, 0.2)',
              // 	opacity:0.1
            }
          }

        }],
        series: [{
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 5,
          itemStyle: {
            normal: {
              color: 'rgba(32, 248, 134, 1)',
              shadowColor: '#20F886',
              shadowBlur: 10,
              borderColor: '#20F886',
              borderWidth: 2,
              lineStyle: {
                color: "#20F886",
                width: 1
              },
              areaStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                  offset: 0,
                  color: 'rgba(0,0,0,0)'
                }, {
                  offset: 1,
                  color: 'rgba(32, 248, 134, 1)'
                }]),
              }
            }
          },
          data: yData
        },]
      };
      myChart.setOption(option);
    },
  },
}
</script>

<style scoped>
.x-tc-cnt {
  width: 100%;
  height: 730px;
}

.x-card {
  width: 230px;
  height: 190px;
  margin-left: 40px;
}

.x-card-cnt {
  width: 250px;
  height: 190px;
}

.x-sm-title-bg {
  background: url("~@/assets/ZhiNengXiTong/title.png") no-repeat;
  background-size: 100% 100%;
  width: 220px;
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-family: douyuFont-2;
  font-size: 14px;
}
</style>
