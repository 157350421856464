const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  userInfo: state => state.user.userInfo,
  projects: state => state.user.projects,
  name: state => state.user.name,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  dept: state => state.user.dept,
  permissions: state => state.user.permissions,
  permission_routes: state => state.permission.routes,
  topbarRouters:state => state.permission.topbarRouters,
  defaultRoutes:state => state.permission.defaultRoutes,
  sidebarRouters: state => state.permission.sidebarRouters,
  activeList: state => state.jiankong.activeList,
  windowNum: state => state.jiankong.windowNum,
  activeGridding:state => state.jiankong.activeGridding,
  activeHis:state => state.jiankong.activeHis,
}
export default getters
