import pointPumpImg from '@/assets/icon/pointImg/icon-泵房.png'
import pointSluiceImg from '@/assets/icon/pointImg/icon-水闸.png'
import haikangshuizhaImg from '@/assets/icon/pointImg/海康水闸.png'
import pointHongXingImg from '@/assets/icon/pointImg/红星.png'
import pointShuiChangImg from '@/assets/icon/pointImg/icon-河道保留.png'
import hanDongJinShuiZha from '@/assets/icon/pointImg/icon-支渠.png'
import pointJiChangImg from '@/assets/icon/pointImg/机场.png'
import pwkImg from '@/assets/icon/排污口.png'
import drqyshImg from '@/assets/icon/排污口.png'

import axios from "axios";
import {
	getDrainOutlet
} from "@/api/water/waterDetailsList";
import {
	css
} from "vue-tree-list/vue.config";

const state = {
	// 公路显隐
	AirPortShow: true,
	// 河道
	riversInfo: [],
	// 渠道
	channelInfo: [],
	// 水库
	reservoirInfo: [],
	// 黃河
	yellowRiverInfo: [],

	LC_RiversInfo: [],
	LC_shuiku_RiversInfo: [],
	LC_shuizha_RiversInfo: [],

	zhibeituceng_Info: [],
	// 灌区
	irrigatedAreaInfo: [],
	// 水闸
	sluiceInfo: [],
	// 泵房
	pumpInfo: [],
	// 重要地址
	importantAddressInfo: [],
	// 水厂
	waterworksInfo: [],

	// 河道对象
	riversEntitie: [],
	// 河道管理范围
	riversManagementEntitie: [],

	// 渠道对象
	channelEntitie: [],
	// 水库对象
	reservoirEntitie: [],
	// 黃河
	yellowRiverEntitie: [],

	LC_RiversEntitie: [],
	LC_shuiku_RiversEntitie: [],
	LC_shuizha_RiversEntitie: [],

	zhibeituceng_Entitie: [],
	// 灌区对象
	IrrigatedAreaEntitie: [],
	// 闸门对象
	sluiceEntitie: [],
	// 泵房对象
	pumpEntitie: [],
	// 重要地址对象
	importantAddressEntitie: [],
	// 水厂对象
	waterworksEntitie: [],

	//排污口，应该用不到
	riversDrainOutletEntitie: [],
	// 交叉建筑物
	jcjzwEntitie: [],
	// 涵洞进水闸
	hdjszEntitie: [],
	// 取水口弹窗
	qushuikouInfo: [],
	qushuikouEntitie: [],
	// 视频监视站
	SpTVInfoEntitie: [],
	SpTVInfo: [],
	TreeShapesInfo: [],
	// 机场
	airportEntitie: [],
	// 沉砂池
	chenshachiInfo: [],
	chenshachiEntitie: [],
}

const mutations = {
	// 清空
	StorageTotal: (state) => {
		state.riversInfo = [];
		state.channelInfo = [];
		state.reservoirInfo = [];
		state.yellowRiverInfo = [];
		state.LC_RiversInfo = [];
		state.LC_shuiku_RiversInfo = [];
		state.LC_shuizha_RiversInfo = [];
		state.zhibeituceng_Info = [];
		state.irrigatedAreaInfo = [];
		state.sluiceInfo = [];
		state.pumpInfo = [];
		state.importantAddressInfo = [];
		state.waterworksInfo = [];
		state.qushuikouInfo = [];
		state.SpTVInfo = [];
		state.TreeShapesInfo = [];
		state.chenshachiInfo = [];
	},

	SET_RIVER: (state, val) => {
		state.riversInfo.push(val)
	},
	SET_RIVERTWO: (state, val) => {
		state.riversInfo.push(val)
	},
	SET_CHANNEL: (state, val) => {
		if (Array.isArray(val)) {
			state.channelInfo = val
		} else {
			state.channelInfo.push(val)
		}
	},
	SET_CHANNELTWO: (state, val) => {
		if (Array.isArray(val)) {
			state.channelInfo = val
		} else {
			state.channelInfo.push(val)
		}
	},

	SET_RESERVOIR: (state, val) => {
		state.reservoirInfo.push(val)
	},
	SET_RESERVOIRTWO: (state, val) => {
		console.log(val)
		state.reservoirInfo.push(val)
		console.log(state.reservoirInfo)
	},

	SET_YELLOWRIVER: (state, val) => {
		state.yellowRiverInfo = val
		state.yellowRiverEntitie = []
	},

	SET_LC_Rivers: (state, val) => {
		state.LC_RiversInfo = val
		state.LC_RiversEntitie = []
	},
	SET_LC_shuikuRivers: (state, val) => {
		state.LC_shuiku_RiversInfo = val
		state.LC_shuiku_RiversEntitie = []
	},
	SET_zhibeituceng: (state, val) => {
		state.zhibeituceng_Info = val
		state.zhibeituceng_Entitie = []
	},
	SET_LC_shuizhaRivers: (state, val) => {
		state.LC_shuizha_RiversInfo = val
		state.LC_shuizha_RiversEntitie = []
	},


	SET_IRRIGATED_AREA: (state, val) => {
		if (Array.isArray(val)) {
			state.irrigatedAreaInfo = val
		} else {
			state.irrigatedAreaInfo.push(val)
		}
	},

	SET_SLUICE: (state, val) => {
		state.sluiceInfo.push(val)
	},

	SET_PUMP: (state, val) => {
		state.pumpInfo.push(val)
	},

	SET_IMPORTANTADDRESS: (state, val) => {
		state.importantAddressInfo = val
		state.importantAddressEntitie = []
	},

	SET_WATERWORKS: (state, val) => {
		state.waterworksInfo.push(val)
	},

	SET_QUSHUIKOU: (state, val) => {
		state.qushuikouInfo = val
		state.qushuikouEntitie = []
	},

	// 视频监视站
	ShuiPinStation: (state, val) => {
		state.SpTVInfo.push(val)
	},

	TreeShapes: (state, val) => {
		state.TreeShapesInfo = val
	},
	// 公路显隐
	AirPortShow: (state, val) => {
		state.AirPortShow = val
	},
	// 沉砂池
	SET_CHENSHACHI: (state, val) => {
		// state.chenshachiInfo.push(val)
		// state.chenshachiEntitie = []

		if (Array.isArray(val)) {
			state.chenshachiInfo = val
		} else {
			state.chenshachiInfo.push(val)
		}
	},
}


const actions = {

	// 隐藏浪潮微服务水闸点
	hiddenlcshuizhaInfo({
		commit
	}, data = false) {
		for (let item of state.LC_shuizha_RiversEntitie) {
			item.show = data;
		}
	},
	// 隐藏 浪潮微服务河流
	hiddenlcheliuInfo({
		commit
	}, data = false) {
		for (let item of state.LC_RiversEntitie) {
			item.show = data;
		}
	},
	// 隐藏 浪潮微服务水库
	hiddenlcshuikuInfo({
		commit
	}, data = false) {
		for (let item of state.LC_shuiku_RiversEntitie) {
			item.show = data;
		}
	},
	// 隐藏 植物图层
	hiddenzhibeituceng_Info({
		commit
	}, data = false) {
		for (let item of state.zhibeituceng_Entitie) {
			item.show = data;
		}
	},


	// 浪潮微服务水闸点 显示水闸
	showLCriversshuizha() {
		for (let item of state.LC_shuizha_RiversEntitie) {
			viewer.entities.remove(item)
		};
		state.LC_shuizha_RiversEntitie = [];
		console.log(state)
		console.log(state.LC_shuizha_RiversInfo)
		for (let item of state.LC_shuizha_RiversInfo.features) {
			let name = item.properties.NAME;
			let coordinates = item.geometry.coordinates;
			let longitude = coordinates[0];
			let latitude = coordinates[1];
			let html = ``;;
			let entitie = addEntitiePoint(name, longitude, latitude, haikangshuizhaImg, html);
			entitie.show = false;
			state.LC_shuizha_RiversEntitie.push(entitie)
		}
	},
	// 浪潮微服务河流 显示河道
	showLCriversInfo() {
		for (let item of state.LC_RiversEntitie) {
			viewer.entities.remove(item)
		};
		state.LC_RiversEntitie = [];
		let color = '#00ffff';
		let material = Cesium.Color.fromCssColorString(color); //.withAlpha(alpha)
		material = new mars3d.material.LineFlowMaterialProperty({ //动画线材质
			color: Cesium.Color.fromCssColorString(color),
			image: './gis/img/textures/LinkPulse.png',
			speed: 0.3, //速度，建议取值范围1-100
		});
		for (let item of state.LC_RiversInfo.features) {
			console.log(state)
			let name = item.properties.NAME;
			let positions = [];
			let coordinates = item.geometry.coordinates;
			for (let i = 0, len = coordinates.length; i < len; i++) {
				positions.push(
					coordinates[i][0], coordinates[i][1], 30
				);
			}
			let entitie = viewer.entities.add({
				name: name,
				height: 3,
				polyline: {
					positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
					width: 10,
					material: material
				},
				tooltip: {
					html: name,
					anchor: [0, -20],
				},
				click: function(e) {}
			});
			state.LC_RiversEntitie.push(entitie)
			entitie.show = false;
		}
	},


	// 浪潮微服务水库 显示水库
	showLCriversshuiku() {
		for (let item of state.LC_shuiku_RiversEntitie) {
			viewer.entities.remove(item)
		};
		state.LC_shuiku_RiversEntitie = []
		let color = '#55ffff';
		let alpha = 1;
		let material = new Cesium.Material({
			opacity: 1,
			fabric: {
				type: 'Water',
				uniforms: {
					baseWaterColor: Cesium.Color.fromCssColorString(color).withAlpha(1),
					//blendColor: new Cesium.Color(0.0, 0.0, 1.0, 0.5),
					//specularMap: 'gray.jpg',
					//normalMap: '../assets/waterNormals.jpg',
					normalMap: './gis/img/textures/water/waterNormals.jpeg',
					frequency: 200.0,
					animationSpeed: 0.005,
					amplitude: 22.0,
				}
			}
		})
		for (let item of state.LC_shuiku_RiversInfo.features) {
			let name = item.properties.NAME;
			let positions = [];
			let coordinates = item.geometry.coordinates[0][0];
			for (let i = 0, len = coordinates.length; i < len; i++) {
				positions.push(coordinates[i][0], coordinates[i][1]);
			}
			let html = '';
			let waterPrimitive = new Cesium.Primitive({
				show: true, // 默认隐藏
				geometryInstances: new Cesium.GeometryInstance({
					geometry: new Cesium.PolygonGeometry({
						outline: true,
						polygonHierarchy: new Cesium.PolygonHierarchy(Cesium.Cartesian3
							.fromDegreesArray(positions)),
						extrudedHeight: 10, //注释掉此属性可以只显示水面
						// height: 10
						// perPositionHeight: true//注释掉此属性水面就贴地了
					})
				}),
				// 可以设置内置的水面shader
				appearance: new Cesium.EllipsoidSurfaceAppearance({
					material: material,
				}),
			});
			waterPrimitive.tooltip = (val) => {
				return name;
			}
			waterPrimitive.positon = [positions[0], positions[1]]
			viewer.scene.primitives.add(waterPrimitive);
			state.LC_shuiku_RiversEntitie.push(waterPrimitive)

			waterPrimitive.show = false;
		}
	},
	// 植物图层
	showzhibeituceng() {
	    // 清除之前的实体
	    for (let item of state.zhibeituceng_Entitie) {
	        viewer.entities.remove(item);
	    }
	    state.zhibeituceng_Entitie = [];
	    
	    // 水面材质设置
	    let color = '#55ffff';
	    let material = new Cesium.Material({
	        opacity: 1,
	        fabric: {
	            type: 'Water',
	            uniforms: {
	                baseWaterColor: Cesium.Color.fromCssColorString(color).withAlpha(1),
	                // normalMap: './gis/img/textures/water/waterNormals.jpeg',
	                frequency: 200.0,
	                animationSpeed: 0.005,
	                amplitude: 22.0,
	            }
	        }
	    });
	
	    // 遍历几何体信息，绘制多边形
	    for (let item of state.zhibeituceng_Info.geometries) {
	        if (item.type === "Polygon") {
	            let coordinates = item.coordinates[0]; // 获取多边形的坐标
	            let positions = Cesium.Cartesian3.fromDegreesArray(coordinates.flat());
	
	            // 创建多边形的几何实例
	            let polygonInstance = new Cesium.GeometryInstance({
	                geometry: new Cesium.PolygonGeometry({
	                    polygonHierarchy: new Cesium.PolygonHierarchy(positions),
	                    // extrudedHeight: 10,  // 如果需要立体效果，可以取消注释
	                })
	            });
	
	            // 创建水面原始体
	            let waterPrimitive = new Cesium.Primitive({
	                show: true,
	                geometryInstances: polygonInstance,
	                appearance: new Cesium.EllipsoidSurfaceAppearance({
	                    material: material
	                }),
	            });
	
	            // 将水面原始体添加到场景中
	            viewer.scene.primitives.add(waterPrimitive);
	            state.zhibeituceng_Entitie.push(waterPrimitive);
	        }
	    }
	},
	// showzhibeituceng() {
	// 	for (let item of state.zhibeituceng_Entitie) {
	// 		viewer.entities.remove(item)
	// 	};
	// 	state.zhibeituceng_Entitie = []
	// 	let color = '#55ffff';
	// 	let alpha = 1;
	// 	let material = new Cesium.Material({
	// 		opacity: 1,
	// 		fabric: {
	// 			type: 'Water',
	// 			uniforms: {
	// 				baseWaterColor: Cesium.Color.fromCssColorString(color).withAlpha(1),
	// 				//blendColor: new Cesium.Color(0.0, 0.0, 1.0, 0.5),
	// 				//specularMap: 'gray.jpg',
	// 				//normalMap: '../assets/waterNormals.jpg',
	// 				normalMap: './gis/img/textures/water/waterNormals.jpeg',
	// 				frequency: 200.0,
	// 				animationSpeed: 0.005,
	// 				amplitude: 22.0,
	// 			}
	// 		}
	// 	})
	// 	for (let item of state.zhibeituceng_Info.geometries) {
	// 		// let name = item.properties.NAME;
	// 		let positions = [];
	// 		let coordinates = item.coordinates[0];

	// 		positions = coordinates
	// 		console.log(positions)
	// 		let html = '';
	// 		let waterPrimitive = new Cesium.Primitive({
	// 			show: true, // 默认隐藏
	// 			geometryInstances: new Cesium.GeometryInstance({
	// 				geometry: new Cesium.PolygonGeometry({
	// 					outline: true,
	// 					polygonHierarchy: new Cesium.PolygonHierarchy(Cesium.Cartesian3
	// 						.fromDegreesArray(positions)),
	// 					// extrudedHeight: 10, //注释掉此属性可以只显示水面
	// 					// height: 10
	// 					// perPositionHeight: true//注释掉此属性水面就贴地了
	// 				})
	// 			}),
	// 			// 可以设置内置的水面shader
	// 			appearance: new Cesium.EllipsoidSurfaceAppearance({
	// 				material: material,
	// 			}),
	// 		});
	// 		// waterPrimitive.tooltip = (val) => {
	// 		// 	return name;
	// 		// }
	// 		// waterPrimitive.positon = [positions[0], positions[1]]
	// 		// viewer.scene.primitives.add(waterPrimitive);
	// 		// state.zhibeituceng_Entitie.push(waterPrimitive)

	// 		// waterPrimitive.show = false;
	// 	}
	// },

	// 显示河道
	showRiversInfo() {
		// console.log(state)
		let PositionSnList = [{
				id: "370505001000",
				name: "垦利街道"
			},
			{
				id: "370505002000",
				name: "兴隆街道"
			},
			{
				id: "370505101000",
				name: "胜坨镇"
			},
			{
				id: "370505102000",
				name: "郝家镇"
			},
			{
				id: "370505104000",
				name: "永安镇"
			},
			{
				id: "370505402000",
				name: "红光渔业"
			},
			{
				id: "370505106000",
				name: "董集镇"
			},
			{
				id: "370505105000",
				name: "黄河口镇"
			},
		];
		// console.log(state.riversInfo, 'rrrrrrrrrrrr');
		for (let item of state.riversEntitie) {
			viewer.entities.remove(item)
		};
		state.riversEntitie = [];
		let color = '#0048ff';
		let material = Cesium.Color.fromCssColorString(color); //.withAlpha(alpha)
		// material = new mars3d.material.LineFlowMaterialProperty({//动画线材质
		//     color: Cesium.Color.fromCssColorString(color),
		//     image: './gis/img/textures/lineClr.png',
		//     speed: 10, //速度，建议取值范围1-100
		// });

		// material = new mars3d.material.LineFlowMaterialProperty({//动画线材质
		//     color: Cesium.Color.fromCssColorString("#1a9850"),
		//     repeat: new Cesium.Cartesian2(2.0, 1.0),
		//     image: './gis/img/textures/ArrowOpacity.png',
		//     speed: 10, //速度，建议取值范围1-100
		// });
		//
		// material = new mars3d.material.LineFlowMaterialProperty({//动画线材质
		//     color: Cesium.Color.fromCssColorString(color),
		//     image: './gis/img/textures/lineClr.png',
		//     speed: 0, //速度，建议取值范围1-100
		// });
		//
		//
		// material = new mars3d.material.LineFlowMaterialProperty({//动画线材质
		//     color: Cesium.Color.fromCssColorString(color),
		//     repeat: new Cesium.Cartesian2(2.0, 1.0),
		//     image: './gis/img/textures/line.png',
		//     speed: 25, //速度，建议取值范围1-100
		// });

		material = new mars3d.material.LineFlowMaterialProperty({ //动画线材质
			color: Cesium.Color.fromCssColorString(color),
			image: './gis/img/textures/LinkPulse.png',
			speed: 0.3, //速度，建议取值范围1-100
		});
		for (let item of state.riversInfo) {

			if (item.data != null) {
				PositionSnList.forEach(function(info) {
					if (item.data.startPosition == info.id) {
						item.data.startPosition = info.name;
					}
					if (item.data.endPosition == info.id) {
						item.data.endPosition = info.name;
					}
				})
			}

			let name = item.name;
			let id = item.id;
			if (item.space == "" || item.space == null) {
				continue;
			}
			let fanWeiData = JSON.parse(item.space);
			if (fanWeiData.hasOwnProperty("space")) {
				continue;
			}
			let positions = [];
			if (!fanWeiData) {
				continue;
			}
			// console.log(item, '000000000000000')
			let coordinates = fanWeiData.geometry.coordinates;
			for (let i = 0, len = coordinates.length; i < len; i++) {
				positions.push(
					coordinates[i][0], coordinates[i][1], 30
				);
			}

			let html = `
            <div class="x-popup-bg">
            <div class="x-popup-title">${name}</div>
            <div class="x-popup-close cursor" onclick="closePopup()"><img src="./gis/img/关闭.png" style="width: 20px"></div>
            <div class="x-popop-cnt text-sm ">
            <div class="flex ">
                <div>安全研判：</div>
                 <div>A</div>
            </div>
            <div class="flex ">
                <div>河流长度：</div>
                 <div>--km</div>
            </div>
            <div class="flex ">
                <div>流域面积：</div>
                 <div>--km²</div>
            </div>
            <div class="flex ">
                <div>起点位置：</div>
                 <div>--</div>
            </div>
            <div class="flex ">
                <div>讫点位置：</div>
                 <div>--</div>
            </div>
            </div>
            </div>
            `;;
			if (item.data != null) {
				html = `
            <div class="x-popup-bg">
            <div class="x-popup-title">${name}</div>
            <div class="x-popup-close cursor" onclick="closePopup()"><img src="./gis/img/关闭.png" style="width: 20px"></div>
            <div class="x-popop-cnt text-sm ">
            <div class="flex ">
                <div>安全研判：</div>
                 <div>A</div>
            </div>
            <div class="flex ">
                <div>河流长度：</div>
                 <div>${item.data.riverLength || '--'}km</div>
            </div>
            <div class="flex ">
                <div>流域面积：</div>
                 <div>${item.data.drainageArea || '--'}km²</div>
            </div>
            <div class="flex ">
                <div>起点位置：</div>
                 <div>${item.data.startPosition || '--'}</div>
            </div>
            <div class="flex ">
                <div>讫点位置：</div>
                 <div>${item.data.endPosition || '--'}</div>
            </div>
               <div class="flex">
                <div>操作：</div>
                 <div class="x-check-num cursor" onclick="riversClick('${item.id}','${item.bindingId}','${name}')">查看详情</div>
            </div>
            </div>
            </div>
            `;
			}
			let entitie = viewer.entities.add({
				name: name,
				treeId: item.id,
				height: 3,
				polyline: {
					positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
					width: 10,
					material: material
				},
				tooltip: {
					html: name,
					anchor: [0, -20],
				},
				popup: {
					html: html,
					anchor: [0, -12], //左右、上下的偏移像素值。
					onAdd: function(eleId) {
						window._popup_eleId = eleId;
					},
				},
				click: function(e) {}
			});
			state.riversEntitie.push(entitie)

			if (item.managementScopeJson) {
				let managerData = JSON.parse(item.managementScopeJson);
				if (!managerData) {
					continue;
				}
				let managerColor = '#fd062b';
				for (let m_item of managerData.features) {
					let positions_manager = [];
					name += '管理范围';
					let coordinates = m_item.geometry.coordinates;
					for (let i = 0, len = coordinates.length; i < len; i++) {

						for (let j = 0, len = coordinates[i].length; j < len; j++) {
							positions_manager.push(
								coordinates[i][j][0], coordinates[i][j][1], 30
							);
						}

					}
					let entitie = viewer.entities.add({
						name: name,
						show: false,
						treeId: item.id,
						polyline: {
							positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions_manager),
							width: 1,
							material: Cesium.Color.fromCssColorString(managerColor)
						},
						tooltip: {
							html: name,
							anchor: [0, -20],
						},
						popup: {
							html: html,
							anchor: [0, -12], //左右、上下的偏移像素值。
							onAdd: function(eleId) {
								window._popup_eleId = eleId;
							},
						},
						click: function(e) {}
					});
					state.riversManagementEntitie.push(entitie)
				}
			}

		}
	},

	// 显示河道
	showRiversInfotwo() {
		// console.log(state)
		let PositionSnList = [{
				id: "370505001000",
				name: "垦利街道"
			},
			{
				id: "370505002000",
				name: "兴隆街道"
			},
			{
				id: "370505101000",
				name: "胜坨镇"
			},
			{
				id: "370505102000",
				name: "郝家镇"
			},
			{
				id: "370505104000",
				name: "永安镇"
			},
			{
				id: "370505402000",
				name: "红光渔业"
			},
			{
				id: "370505106000",
				name: "董集镇"
			},
			{
				id: "370505105000",
				name: "黄河口镇"
			},
		];
		// console.log(state.riversInfo, 'rrrrrrrrrrrr');
		for (let item of state.riversEntitie) {
			viewer.entities.remove(item)
		};
		state.riversEntitie = [];
		let color = '#0048ff';
		let material = Cesium.Color.fromCssColorString(color); //.withAlpha(alpha)
		// material = new mars3d.material.LineFlowMaterialProperty({//动画线材质
		//     color: Cesium.Color.fromCssColorString(color),
		//     image: './gis/img/textures/lineClr.png',
		//     speed: 10, //速度，建议取值范围1-100
		// });

		// material = new mars3d.material.LineFlowMaterialProperty({//动画线材质
		//     color: Cesium.Color.fromCssColorString("#1a9850"),
		//     repeat: new Cesium.Cartesian2(2.0, 1.0),
		//     image: './gis/img/textures/ArrowOpacity.png',
		//     speed: 10, //速度，建议取值范围1-100
		// });
		//
		// material = new mars3d.material.LineFlowMaterialProperty({//动画线材质
		//     color: Cesium.Color.fromCssColorString(color),
		//     image: './gis/img/textures/lineClr.png',
		//     speed: 0, //速度，建议取值范围1-100
		// });
		//
		//
		// material = new mars3d.material.LineFlowMaterialProperty({//动画线材质
		//     color: Cesium.Color.fromCssColorString(color),
		//     repeat: new Cesium.Cartesian2(2.0, 1.0),
		//     image: './gis/img/textures/line.png',
		//     speed: 25, //速度，建议取值范围1-100
		// });

		material = new mars3d.material.LineFlowMaterialProperty({ //动画线材质
			color: Cesium.Color.fromCssColorString(color),
			image: './gis/img/textures/LinkPulse.png',
			speed: 0.3, //速度，建议取值范围1-100
		});
		for (let item of state.riversInfo) {

			if (item.data != null) {
				PositionSnList.forEach(function(info) {
					if (item.data.startPosition == info.id) {
						item.data.startPosition = info.name;
					}
					if (item.data.endPosition == info.id) {
						item.data.endPosition = info.name;
					}
				})
			}

			let name = item.name;
			let id = item.id;
			if (item.space == "" || item.space == null) {
				continue;
			}
			let fanWeiData = JSON.parse(item.space);
			if (fanWeiData.hasOwnProperty("space")) {
				continue;
			}
			let positions = [];
			if (!fanWeiData) {
				continue;
			}
			// console.log(item, '000000000000000')
			let coordinates = fanWeiData.geometry.coordinates;
			for (let i = 0, len = coordinates.length; i < len; i++) {
				positions.push(
					coordinates[i][0], coordinates[i][1], 30
				);
			}

			let html = `
            <div class="x-popup-bg">
            <div class="x-popup-title">${name}</div>
            <div class="x-popup-close cursor" onclick="closePopup()"><img src="./gis/img/关闭.png" style="width: 20px"></div>
            <div class="x-popop-cnt text-sm ">
            <div class="flex ">
                <div>安全研判：</div>
                 <div>A</div>
            </div>
            <div class="flex ">
                <div>河流长度：</div>
                 <div>--km</div>
            </div>
            <div class="flex ">
                <div>流域面积：</div>
                 <div>--km²</div>
            </div>
            <div class="flex ">
                <div>起点位置：</div>
                 <div>--</div>
            </div>
            <div class="flex ">
                <div>讫点位置：</div>
                 <div>--</div>
            </div>
            </div>
            </div>
            `;;
			if (item.data != null) {
				html = `
            <div class="x-popup-bg">
            <div class="x-popup-title">${name}</div>
            <div class="x-popup-close cursor" onclick="closePopup()"><img src="./gis/img/关闭.png" style="width: 20px"></div>
            <div class="x-popop-cnt text-sm ">
            <div class="flex ">
                <div>安全研判：</div>
                 <div>A</div>
            </div>
            <div class="flex ">
                <div>河流长度：</div>
                 <div>${item.data.riverLength || '--'}km</div>
            </div>
            <div class="flex ">
                <div>流域面积：</div>
                 <div>${item.data.drainageArea || '--'}km²</div>
            </div>
            <div class="flex ">
                <div>起点位置：</div>
                 <div>${item.data.startPosition || '--'}</div>
            </div>
            <div class="flex ">
                <div>讫点位置：</div>
                 <div>${item.data.endPosition || '--'}</div>
            </div>
            </div>
            </div>
            `;
			}
			let entitie = viewer.entities.add({
				name: name,
				treeId: item.id,
				height: 3,
				polyline: {
					positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
					width: 10,
					material: material
				},
				tooltip: {
					html: name,
					anchor: [0, -20],
				},
				// popup: {
				// 	html: html,
				// 	anchor: [0, -12], //左右、上下的偏移像素值。
				// 	onAdd: function(eleId) {
				// 		window._popup_eleId = eleId;
				// 	},
				// },
				click: function(e) {}
			});
			state.riversEntitie.push(entitie)

			if (item.managementScopeJson) {
				let managerData = JSON.parse(item.managementScopeJson);
				if (!managerData) {
					continue;
				}
				let managerColor = '#fd062b';
				for (let m_item of managerData.features) {
					let positions_manager = [];
					name += '管理范围';
					let coordinates = m_item.geometry.coordinates;
					for (let i = 0, len = coordinates.length; i < len; i++) {

						for (let j = 0, len = coordinates[i].length; j < len; j++) {
							positions_manager.push(
								coordinates[i][j][0], coordinates[i][j][1], 30
							);
						}

					}
					let entitie = viewer.entities.add({
						name: name,
						show: false,
						treeId: item.id,
						polyline: {
							positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions_manager),
							width: 1,
							material: Cesium.Color.fromCssColorString(managerColor)
						},
						tooltip: {
							html: name,
							anchor: [0, -20],
						},
						popup: {
							html: html,
							anchor: [0, -12], //左右、上下的偏移像素值。
							onAdd: function(eleId) {
								window._popup_eleId = eleId;
							},
						},
						click: function(e) {}
					});
					state.riversManagementEntitie.push(entitie)
				}
			}

		}
	},


	// 显示渠道
	showChannelInfo() {
		let PositionSnList = [{
				id: "370505001000",
				name: "垦利街道"
			},
			{
				id: "370505002000",
				name: "兴隆街道"
			},
			{
				id: "370505101000",
				name: "胜坨镇"
			},
			{
				id: "370505102000",
				name: "郝家镇"
			},
			{
				id: "370505104000",
				name: "永安镇"
			},
			{
				id: "370505402000",
				name: "红光渔业"
			},
			{
				id: "370505106000",
				name: "董集镇"
			},
			{
				id: "370505105000",
				name: "黄河口镇"
			},
		];

		for (let item of state.channelEntitie) {
			viewer.entities.remove(item)
		};
		state.channelEntitie = []
		let color = '#ffd700';
		let material = Cesium.Color.fromCssColorString(color); //.withAlpha(alpha)
		for (let item of state.channelInfo) {

			if (item.data != null) {

				PositionSnList.forEach(function(info) {
					if (item.data.startPosition == info.id) {
						item.data.startPosition = info.name;
					}
					if (item.data.endPosition == info.id) {
						item.data.endPosition = info.name;
					}
				})
			}


			let name = item.name;
			let id = item.id;
			if (item.space == "" || item.space == null) {
				continue;
			}
			let fanWeiData = JSON.parse(item.space);
			if (fanWeiData.hasOwnProperty("space")) {
				continue;
			}
			let positions = [];
			if (!fanWeiData) {
				continue;
			}
			let coordinates;
			if (fanWeiData.geometry.coordinates.length > 1) {
				coordinates = fanWeiData.geometry.coordinates
			} else {
				coordinates = fanWeiData.geometry.coordinates[0]
			}
			for (let i = 0, len = coordinates.length; i < len; i++) {
				positions.push(
					coordinates[i][0], coordinates[i][1], 30
				);
			}

			let html = `
            <div class="x-popup-bg">
            <div class="x-popup-title">${name}</div>
            <div class="x-popup-close cursor" onclick="closePopup()"><img src="./gis/img/关闭.png" style="width: 20px"></div>
            <div class="x-popop-cnt text-sm ">
           
           <div class="flex ">
               <div>渠道长度：</div>
                <div>--km</div>
           </div>
           <div class="flex ">
               <div>衬砌长度：</div>
                <div>--km</div>
           </div>
           <div class="flex ">
               <div>控制灌溉面积：</div>
                <div>--km²</div>
           </div>
           <div class="flex ">
               <div>管理人员：</div>
                <div>--</div>
           </div>
            </div>
            `;;
			if (item.data != null) {
				html = `
            <div class="x-popup-bg">
            <div class="x-popup-title">${name}</div>
            <div class="x-popup-close cursor" onclick="closePopup()"><img src="./gis/img/关闭.png" style="width: 20px"></div>
            <div class="x-popop-cnt text-sm ">
            <div class="flex ">
                <div>渠道长度：</div>
                 <div>${item.data.canalLength || '--'}km</div>
            </div>
            <div class="flex ">
                <div>衬砌长度：</div>
                 <div>${item.data.liningLength || '--'}km</div>
            </div>
            <div class="flex ">
                <div>控制灌溉面积：</div>
                 <div>${item.data.controlIrrigatedArea || '--'}km²</div>
            </div>
            <div class="flex ">
                <div>管理人员：</div>
                 <div>${item.data.mangerName || '--'}</div>
            </div>
			
			   <div class="flex">
			    <div>操作：</div>
			     <div class="x-check-num cursor" onclick="ganquClick('${item.id}','${item.bindingId}','${name}')">查看详情</div>
			</div>
            </div>
            `;
			}

			let width = 3;
			if (item.name.indexOf("干渠") < 0) {
				width = 1;
			}
			if (item.data == null) {
				item.data = {
					level: ""
				}
			}
			let entitie = viewer.entities.add({
				name: name,
				treeId: item.id,
				level: item.data.level,
				polyline: {
					positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
					width: width,
					material: material
				},
				tooltip: {
					html: name,
					anchor: [0, -20],
				},
				popup: {
					html: html,
					anchor: [0, -12], //左右、上下的偏移像素值。
					onAdd: function(eleId) {
						window._popup_eleId = eleId;
					},
				},
				click: function(e) {}
			});
			if (item.name.indexOf("干渠") < 0 && item.name.indexOf("分干") < 0) {
				entitie.show = false;
			}
			if (item.name.indexOf("纪冯干渠") == 0) {
				entitie.show = false;
			}
			// entitie.level = item.level;
			state.channelEntitie.push(entitie)
		}
	},
	// 显示所有渠道，包括干渠支渠、、灌区内渠道
	showAllChannelInfo() {
		let PositionSnList = [{
				id: "370505001000",
				name: "垦利街道"
			},
			{
				id: "370505002000",
				name: "兴隆街道"
			},
			{
				id: "370505101000",
				name: "胜坨镇"
			},
			{
				id: "370505102000",
				name: "郝家镇"
			},
			{
				id: "370505104000",
				name: "永安镇"
			},
			{
				id: "370505402000",
				name: "红光渔业"
			},
			{
				id: "370505106000",
				name: "董集镇"
			},
			{
				id: "370505105000",
				name: "黄河口镇"
			},
		];

		for (let item of state.channelEntitie) {
			viewer.entities.remove(item)
		};
		state.channelEntitie = []
		let color = '#ffd700';
		let material = Cesium.Color.fromCssColorString(color); //.withAlpha(alpha)
		for (let item of state.channelInfo) {

			if (item.data != null) {

				PositionSnList.forEach(function(info) {
					if (item.data.startPosition == info.id) {
						item.data.startPosition = info.name;
					}
					if (item.data.endPosition == info.id) {
						item.data.endPosition = info.name;
					}
				})
			}


			let name = item.name;
			let id = item.id;
			if (item.space == "" || item.space == null) {
				continue;
			}
			let fanWeiData = JSON.parse(item.space);
			if (fanWeiData.hasOwnProperty("space")) {
				continue;
			}
			let positions = [];
			if (!fanWeiData) {
				continue;
			}
			let coordinates;
			if (fanWeiData.geometry.coordinates.length > 1) {
				coordinates = fanWeiData.geometry.coordinates
			} else {
				coordinates = fanWeiData.geometry.coordinates[0]
			}
			for (let i = 0, len = coordinates.length; i < len; i++) {
				positions.push(
					coordinates[i][0], coordinates[i][1], 30
				);
			}

			let html = `
	          <div class="x-popup-bg">
	          <div class="x-popup-title">${name}</div>
	          <div class="x-popup-close cursor" onclick="closePopup()"><img src="./gis/img/关闭.png" style="width: 20px"></div>
	          <div class="x-popop-cnt text-sm ">
	        
	        <div class="flex ">
	            <div>渠道长度：</div>
	             <div>--km</div>
	        </div>
	        <div class="flex ">
	            <div>衬砌长度：</div>
	             <div>--km</div>
	        </div>
	        <div class="flex ">
	            <div>控制灌溉面积：</div>
	             <div>--km²</div>
	        </div>
	        <div class="flex ">
	            <div>管理人员：</div>
	             <div>--</div>
	        </div>
	          </div>
	          `;;
			if (item.data != null) {
				html = `
	          <div class="x-popup-bg">
	          <div class="x-popup-title">${name}</div>
	          <div class="x-popup-close cursor" onclick="closePopup()"><img src="./gis/img/关闭.png" style="width: 20px"></div>
	          <div class="x-popop-cnt text-sm ">
	          <div class="flex ">
	              <div>渠道长度：</div>
	               <div>${item.data.canalLength || '--'}km</div>
	          </div>
	          <div class="flex ">
	              <div>衬砌长度：</div>
	               <div>${item.data.liningLength || '--'}km</div>
	          </div>
	          <div class="flex ">
	              <div>控制灌溉面积：</div>
	               <div>${item.data.controlIrrigatedArea || '--'}km²</div>
	          </div>
	          <div class="flex ">
	              <div>管理人员：</div>
	               <div>${item.data.mangerName || '--'}</div>
	          </div>
			
	          </div>
	          `;
			}

			let width = 3;
			if (item.name.indexOf("干渠") < 0) {
				width = 1;
			}
			if (item.data == null) {
				item.data = {
					level: ""
				}
			}
			let entitie = viewer.entities.add({
				name: name,
				treeId: item.id,
				level: item.data.level,
				polyline: {
					positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
					width: width,
					material: material
				},
				tooltip: {
					html: name,
					anchor: [0, -20],
				},
				popup: {
					html: html,
					anchor: [0, -12], //左右、上下的偏移像素值。
					onAdd: function(eleId) {
						window._popup_eleId = eleId;
					},
				},
				click: function(e) {}
			});
			/* if (item.name.indexOf("干渠") < 0 && item.name.indexOf("分干") < 0) {
				entitie.show = false;
			} */
			if (item.name.indexOf("纪冯干渠") == 0) {
				entitie.show = false;
			}
			// entitie.level = item.level;
			state.channelEntitie.push(entitie)
		}
	},
	// 显示所有渠道，包括干渠支渠、、灌区内渠道(仅展示)
	showAllChannelInfotwo() {
		let PositionSnList = [{
				id: "370505001000",
				name: "垦利街道"
			},
			{
				id: "370505002000",
				name: "兴隆街道"
			},
			{
				id: "370505101000",
				name: "胜坨镇"
			},
			{
				id: "370505102000",
				name: "郝家镇"
			},
			{
				id: "370505104000",
				name: "永安镇"
			},
			{
				id: "370505402000",
				name: "红光渔业"
			},
			{
				id: "370505106000",
				name: "董集镇"
			},
			{
				id: "370505105000",
				name: "黄河口镇"
			},
		];

		for (let item of state.channelEntitie) {
			viewer.entities.remove(item)
		};
		state.channelEntitie = []
		let color = '#ffd700';
		let material = Cesium.Color.fromCssColorString(color); //.withAlpha(alpha)
		for (let item of state.channelInfo) {

			if (item.data != null) {

				PositionSnList.forEach(function(info) {
					if (item.data.startPosition == info.id) {
						item.data.startPosition = info.name;
					}
					if (item.data.endPosition == info.id) {
						item.data.endPosition = info.name;
					}
				})
			}


			let name = item.name;
			let id = item.id;
			if (item.space == "" || item.space == null) {
				continue;
			}
			let fanWeiData = JSON.parse(item.space);
			if (fanWeiData.hasOwnProperty("space")) {
				continue;
			}
			let positions = [];
			if (!fanWeiData) {
				continue;
			}
			let coordinates;
			if (fanWeiData.geometry.coordinates.length > 1) {
				coordinates = fanWeiData.geometry.coordinates
			} else {
				coordinates = fanWeiData.geometry.coordinates[0]
			}
			for (let i = 0, len = coordinates.length; i < len; i++) {
				positions.push(
					coordinates[i][0], coordinates[i][1], 30
				);
			}

			// let html = ` `;
			let width = 3;
			if (item.name.indexOf("干渠") < 0) {
				width = 1;
			}
			if (item.data == null) {
				item.data = {
					level: ""
				}
			}
			let entitie = viewer.entities.add({
				name: name,
				treeId: item.id,
				level: item.data.level,
				polyline: {
					positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
					width: width,
					material: material
				},
				tooltip: {
					html: name,
					anchor: [0, -20],
				},
				// popup: {
				// 	html: html,
				// 	anchor: [0, -12], //左右、上下的偏移像素值。
				// 	onAdd: function(eleId) {
				// 		window._popup_eleId = eleId;
				// 	},
				// },
				click: function(e) {}
			});
			/* if (item.name.indexOf("干渠") < 0 && item.name.indexOf("分干") < 0) {
				entitie.show = false;
			} */
			if (item.name.indexOf("纪冯干渠") == 0) {
				entitie.show = false;
			}
			// entitie.level = item.level;
			state.channelEntitie.push(entitie)
		}
	},
	// 农村水利显示渠道
	showSLChannelInfo() {
		let color = '#ffd700';
		let material = Cesium.Color.fromCssColorString(color); //.withAlpha(alpha)
		for (let item of state.channelInfo) {
			// console.log(item.name, item, 'ooooooooooooooo')
			let name = item.name;
			let id = item.id;
			if (item.channelLineJson == null || item.channelLineJson == "") {
				continue;
			}
			let fanWeiData = JSON.parse(item.channelLineJson);
			// console.log(fanWeiData, '获取到了')
			let positions = [];
			if (!fanWeiData) {
				continue;
			}
			let coordinates;
			if (fanWeiData.features[0].geometry.coordinates.length > 1) {
				coordinates = fanWeiData.features[0].geometry.coordinates
			} else {
				coordinates = fanWeiData.features[0].geometry.coordinates[0]
			}
			for (let i = 0, len = coordinates.length; i < len; i++) {
				positions.push(
					coordinates[i][0], coordinates[i][1], 30
				);
			}
			let html = `
            <div class="x-popup-bg">
            <div class="x-popup-title">${name}</div>
            <div class="x-popup-close cursor" onclick="closePopup()"><img src="./gis/img/关闭.png" style="width: 20px"></div>
            <div class="x-popop-cnt text-sm ">
            <div class="flex ">
                <div>安全研判：</div>
                 <div>A</div>
            </div>
            <div class="flex ">
                <div>河流长度：</div>
                 <div>${item.riverLength || '--'}km</div>
            </div>
            <div class="flex ">
                <div>流域面积：</div>
                 <div>${item.drainageArea || '--'}km2</div>
            </div>
            <div class="flex ">
                <div>起点位置：</div>
                 <div>${item.startPosition || '--'}</div>
            </div>
            <div class="flex ">
                <div>讫点位置：</div>
                 <div>${item.endPosition || '--'}</div>
            </div>
               <div class="flex">
                <div>操作：</div>
                 <div class="x-check-num cursor" onclick="riversClick('${item.id}')">查看详情</div>
            </div>
            </div>
            </div>
            `;

			let width = 3;
			if (item.level !== '10') {
				width = 1;
			}
			let entitie = viewer.entities.add({
				name: name,
				serviceId: item.id,
				polyline: {
					positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
					width: width,
					material: material
				},
				/* tooltip: {
					 html: html,
					 anchor: [0, -20],
				 },
								  popup: {
					 html: html,
					 anchor: [0, -12],//左右、上下的偏移像素值。
					 onAdd: function (eleId) {
						 window._popup_eleId = eleId;
					 },
				 }, */
				click: function(e) {}
			});
			if (item.name.indexOf("干渠") < 0 && item.name.indexOf("分干") < 0) {
				entitie.show = false;
			}
			if (item.name.indexOf("纪冯干渠") == 0) {
				entitie.show = false;
			}
			// if (item.level !== '10') {
			//     entitie.show = false;
			// }
			entitie.level = item.level;

			// console.log(item.name,item.name.indexOf("路南"));
			state.channelEntitie.push(entitie)
		}
	},

	// 显示水库
	showReservoirInfo() {
		for (let item of state.reservoirEntitie) {
			viewer.entities.remove(item)
		};
		state.reservoirEntitie = []
		let color = '#01a0d1';
		let alpha = 1;
		// let material = new Cesium.ImageMaterialProperty({
		//     image: './gis/img/textures/movingRiver.png',
		// })
		let material = new Cesium.Material({
			opacity: 1,
			fabric: {
				type: 'Water',
				uniforms: {
					baseWaterColor: Cesium.Color.fromCssColorString(color).withAlpha(1),
					//blendColor: new Cesium.Color(0.0, 0.0, 1.0, 0.5),
					//specularMap: 'gray.jpg',
					//normalMap: '../assets/waterNormals.jpg',
					normalMap: './gis/img/textures/water/waterNormals.jpeg',
					frequency: 200.0,
					animationSpeed: 0.005,
					amplitude: 22.0,
				}
			}
		})
		for (let item of state.reservoirInfo) {
			let name = item.name;
			let id = item.id;
			if (item.space == "" || item.space == null) {
				continue;
			}
			let fanWeiData = JSON.parse(item.space);
			if (!fanWeiData.hasOwnProperty("features")) {
				continue;
			}
			let positions = [];
			let coordinates = fanWeiData.features[0].geometry.coordinates[0][0];
			for (let i = 0, len = coordinates.length; i < len; i++) {
				positions.push(coordinates[i][0], coordinates[i][1]);
			}
			let html = '';
			if (item.data != null) {
				html = `
            <div class="x-popup-bg">
            <div class="x-popup-title">${name}</div>
            <div class="x-popup-close cursor" onclick="closePopup()"><img src="./gis/img/关闭.png" style="width: 20px"></div>
            <div class="x-popop-cnt text-sm ">
            <div class="flex ">
                <div>安全研判：</div>
                 <div>A</div>
            </div>
            <div class="flex ">
                <div>设计蓄水位：</div>
                 <div>${item.data.designWaterLevel || '--'}m</div>
            </div>
            <div class="flex ">
                <div>设计蓄水量：</div>
                 <div>${item.data.designStorageCapacity || '--'} 万m³</div>
            </div>
            <div class="flex ">
                <div>坝顶高程：</div>
                 <div>${item.data.crestElevation || '--'}m</div>
            </div>
               <div class="flex">
                <div>操作：</div>
                 <div class="x-check-num cursor" onclick="reservoirClick('${item.id}','${item.bindingId}','${name}')">查看详情</div>
            </div>
            </div>
            </div>
            `;
			}

			// <div class="flex ">
			//     <div>实时水位：</div>
			//      <div>${item.data.realTimeWaterLevel || '--'} m</div>
			// </div>
			//     <div class="flex ">
			//     <div>实时库容：</div>
			//      <div>${item.data.realTimeStorageCapacity || '--'} 万m³</div>
			// </div>
			let waterPrimitive = new Cesium.Primitive({
				show: true, // 默认隐藏
				geometryInstances: new Cesium.GeometryInstance({
					geometry: new Cesium.PolygonGeometry({
						outline: true,
						polygonHierarchy: new Cesium.PolygonHierarchy(Cesium.Cartesian3
							.fromDegreesArray(positions)),
						extrudedHeight: 10, //注释掉此属性可以只显示水面
						// height: 10
						// perPositionHeight: true//注释掉此属性水面就贴地了
					})
				}),
				// 可以设置内置的水面shader
				appearance: new Cesium.EllipsoidSurfaceAppearance({
					material: material,
				}),
			});
			waterPrimitive.tooltip = (val) => {
				return name;
			}

			waterPrimitive.popup = (val) => {
				return html
			}

			waterPrimitive.positon = [positions[0], positions[1]]
			waterPrimitive.treeId = item.id;
			viewer.scene.primitives.add(waterPrimitive);
			// let entitie = viewer.entities.add(
			//     {
			//         name: name,
			//         treeId: item.id,
			//         tooltip: {
			//             html: html,
			//             anchor: [0, -10],
			//         },
			//         // polyline: {
			//         //     positions: Cesium.Cartesian3.fromDegreesArray(positions),
			//         //     width: 1,
			//         //     material: new Cesium.PolylineDashMaterialProperty({
			//         //         dashLength: 4,
			//         //         color: Cesium.Color.fromCssColorString(color),
			//         //     })
			//         // },
			//         polygon: {
			//             height: 150,
			//             hierarchy: Cesium.Cartesian3.fromDegreesArray(positions),
			//             material: material,
			//         },
			//         popup: {
			//             html: html,
			//             anchor: [0, -12],//左右、上下的偏移像素值。
			//             onAdd: function (eleId) {
			//                 window._popup_eleId = eleId;
			//             },
			//         },
			//         click: function (e) {//单击后的回调
			//         }
			//     });
			state.reservoirEntitie.push(waterPrimitive)
		}
	},
	// 仅展示水库
	showReservoirInfotwo() {
		for (let item of state.reservoirEntitie) {
			viewer.entities.remove(item)
		};
		state.reservoirEntitie = []
		let color = '#01a0d1';
		let alpha = 1;
		// let material = new Cesium.ImageMaterialProperty({
		//     image: './gis/img/textures/movingRiver.png',
		// })
		let material = new Cesium.Material({
			opacity: 1,
			fabric: {
				type: 'Water',
				uniforms: {
					baseWaterColor: Cesium.Color.fromCssColorString(color).withAlpha(1),
					//blendColor: new Cesium.Color(0.0, 0.0, 1.0, 0.5),
					//specularMap: 'gray.jpg',
					//normalMap: '../assets/waterNormals.jpg',
					normalMap: './gis/img/textures/water/waterNormals.jpeg',
					frequency: 200.0,
					animationSpeed: 0.005,
					amplitude: 22.0,
				}
			}
		})
		for (let item of state.reservoirInfo) {
			let name = item.name;
			let id = item.id;
			if (item.space == "" || item.space == null) {
				continue;
			}
			let fanWeiData = JSON.parse(item.space);
			if (!fanWeiData.hasOwnProperty("features")) {
				continue;
			}
			let positions = [];
			let coordinates = fanWeiData.features[0].geometry.coordinates[0][0];
			for (let i = 0, len = coordinates.length; i < len; i++) {
				positions.push(coordinates[i][0], coordinates[i][1]);
			}
			let html = '';

			let waterPrimitive = new Cesium.Primitive({
				show: true, // 默认隐藏
				geometryInstances: new Cesium.GeometryInstance({
					geometry: new Cesium.PolygonGeometry({
						outline: true,
						polygonHierarchy: new Cesium.PolygonHierarchy(Cesium.Cartesian3
							.fromDegreesArray(positions)),
						extrudedHeight: 10, //注释掉此属性可以只显示水面
						// height: 10
						// perPositionHeight: true//注释掉此属性水面就贴地了
					})
				}),
				// 可以设置内置的水面shader
				appearance: new Cesium.EllipsoidSurfaceAppearance({
					material: material,
				}),
			});
			waterPrimitive.tooltip = (val) => {
				return name;
			}

			waterPrimitive.popup = (val) => {
				return html
			}

			waterPrimitive.positon = [positions[0], positions[1]]
			waterPrimitive.treeId = item.id;
			viewer.scene.primitives.add(waterPrimitive);
			// let entitie = viewer.entities.add(
			//     {
			//         name: name,
			//         treeId: item.id,
			//         tooltip: {
			//             html: html,
			//             anchor: [0, -10],
			//         },
			//         // polyline: {
			//         //     positions: Cesium.Cartesian3.fromDegreesArray(positions),
			//         //     width: 1,
			//         //     material: new Cesium.PolylineDashMaterialProperty({
			//         //         dashLength: 4,
			//         //         color: Cesium.Color.fromCssColorString(color),
			//         //     })
			//         // },
			//         polygon: {
			//             height: 150,
			//             hierarchy: Cesium.Cartesian3.fromDegreesArray(positions),
			//             material: material,
			//         },
			//         popup: {
			//             html: html,
			//             anchor: [0, -12],//左右、上下的偏移像素值。
			//             onAdd: function (eleId) {
			//                 window._popup_eleId = eleId;
			//             },
			//         },
			//         click: function (e) {//单击后的回调
			//         }
			//     });
			state.reservoirEntitie.push(waterPrimitive)
		}
	},

	// 涵洞进水闸.geojson
	initHanDongJinShuiZha() {
		let data = [{
			id: 'lzgq_hdjsz_',
			name: '路庄灌区',
			url: './gisData/路庄灌区/涵洞进水闸.geojson',
		}];
		for (let item of data) {
			axios.get(item.url, {}).then((response) => {
				let features = response.data.features;
				let idi = 0;
				for (let c_item of features) {
					let name = c_item.properties.O_Name;
					let O_Comment = c_item.properties.O_Comment || '';
					let O_Lat = c_item.properties.O_Lat;
					let O_Lng = c_item.properties.O_Lng;
					let entitie = addEntitiePoint(name, O_Lng, O_Lat, hanDongJinShuiZha, name + O_Comment);
					entitie.treeId = item.id;
					entitie.show = false;
					state.hdjszEntitie.push(entitie)
					idi++;
				}

			});
		}
	},


	// 初始化交叉建筑物
	initJiaoChaJianZhuWu() {
		let color = '#aa00ff';
		let material = Cesium.Color.fromCssColorString(color); //.withAlpha(alpha)
		let data = [{
			id: 'lzgq_jcjzw',
			name: '路庄灌区',
			url: './gisData/路庄灌区/交叉建筑物.geojson',
		}, {
			id: 'lzgq_pstx_',
			name: '路庄灌区',
			url: './gisData/路庄灌区/排水工程体系.geojson',
		}];

		let width = 2;
		for (let item of data) {
			axios.get(item.url, {}).then((response) => {
				let features = response.data.features;
				let i = 0;
				for (let c_item of features) {
					let name = c_item.properties.O_Name;
					let geometry = c_item.geometry.coordinates[0];
					let positions = [];
					for (let i = 0, len = geometry.length; i < len; i++) {
						positions.push(geometry[i][0], geometry[i][1], 30);
					}
					let entitie = viewer.entities.add({
						name: name,
						treeId: item.id,
						polyline: {
							positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
							width: width,
							material: material
						},
						tooltip: {
							html: name,
							anchor: [0, -20],
						},
						click: function(e) {}
					});
					entitie.show = false;
					state.jcjzwEntitie.push(entitie)
					i++;
				}
			});
		}
	},


	// 显示沉砂池
	initChenShaChi() {
		console.log("显示沉砂池")
		console.log(state.chenshachiInfo)
		for (let item of state.chenshachiEntitie) {
			viewer.entities.remove(item)
		};
		state.chenshachiEntitie = []
		let color = '#0581a7';
		let material = new Cesium.Material({
			opacity: 1,
			fabric: {
				type: 'Water',
				uniforms: {
					baseWaterColor: Cesium.Color.fromCssColorString(color).withAlpha(1),
					normalMap: './gis/img/textures/water/waterNormals.jpeg',
					frequency: 200.0,
					animationSpeed: 0.005,
					amplitude: 22.0,
				}
			}
		})
		for (let item of state.chenshachiInfo) {
			let name = item.name;
			let id = item.id;
			if (item.space == "" || item.space == null) {
				continue;
			}
			let fanWeiData = JSON.parse(item.space);
			let positions = [];
			let coordinates = fanWeiData.geometry.coordinates;
			for (let i = 0, len = coordinates.length; i < len; i++) {
				positions.push(coordinates[i][0], coordinates[i][1]);
			}
			let waterPrimitive = new Cesium.Primitive({
				show: true, // 默认隐藏
				geometryInstances: new Cesium.GeometryInstance({
					geometry: new Cesium.PolygonGeometry({
						outline: true,
						polygonHierarchy: new Cesium.PolygonHierarchy(Cesium.Cartesian3
							.fromDegreesArray(positions)),
						extrudedHeight: 10, //注释掉此属性可以只显示水面
						// height: 10
						// perPositionHeight: true//注释掉此属性水面就贴地了
					})
				}),
				// 可以设置内置的水面shader
				appearance: new Cesium.EllipsoidSurfaceAppearance({
					material: material,
				}),
			});
			waterPrimitive.tooltip = (val) => {
				return name;
			}

			waterPrimitive.positon = [positions[0], positions[1]]
			waterPrimitive.treeId = item.id;
			viewer.scene.primitives.add(waterPrimitive);
			state.chenshachiEntitie.push(waterPrimitive)
		}

	},



	// 显示黄河
	showYellowRiver() {
		let color = '#8d680c';
		let alpha = 1;
		for (let item of state.yellowRiverInfo.features) {
			let positions = [];
			if (!item.geometry) {
				continue;
			}
			let coordinates = item.geometry.coordinates[0][0];
			for (let i = 0, len = coordinates.length; i < len; i++) {
				positions.push(
					coordinates[i][0], coordinates[i][1]
				);
			}

			let material = new Cesium.Material({
				opacity: 1,
				fabric: {
					type: 'Water',
					uniforms: {
						baseWaterColor: Cesium.Color.fromCssColorString(color).withAlpha(1),
						//blendColor: new Cesium.Color(0.0, 0.0, 1.0, 0.5),
						//specularMap: 'gray.jpg',
						//normalMap: '../assets/waterNormals.jpg',
						normalMap: './gis/img/textures/water/waterNormals.jpeg',
						frequency: 200.0,
						animationSpeed: 0.005,
						amplitude: 22.0,
					}
				}
			})

			let waterPrimitive = new Cesium.Primitive({
				show: true, // 默认隐藏
				geometryInstances: new Cesium.GeometryInstance({
					geometry: new Cesium.PolygonGeometry({
						outline: true,
						polygonHierarchy: new Cesium.PolygonHierarchy(Cesium.Cartesian3
							.fromDegreesArray(positions)),
						extrudedHeight: 10, //注释掉此属性可以只显示水面
						// height: 10
						// perPositionHeight: true//注释掉此属性水面就贴地了
					})
				}),
				// 可以设置内置的水面shader
				appearance: new Cesium.EllipsoidSurfaceAppearance({
					material: material,
				}),
			});
			waterPrimitive.tooltip = (val) => {
				return "黄河"
			}
			viewer.scene.primitives.add(waterPrimitive);
		}
	},

	// 显示灌区
	showIrrigatedAreaInfo({
		commit
	}, type) {
		for (let item of state.IrrigatedAreaEntitie) {
			viewer.entities.remove(item)
		};
		state.IrrigatedAreaEntitie = []
		let IrrigatedAreaColor = ['#C5CAE9', '#ee6092', '#41B883', '#00FF25FF', '#FFB300', '#80CBCF', '#a16afe',
			'#FF7129FF', '#f8c28d', '#f88df4'
		]
		let alpha = .9;
		// localStorage.setItem("routertype", "2");
		for (let [index, item] of state.irrigatedAreaInfo.entries()) {
			let name = item.name;
			let id = item.id;
			// let color = item.irrigatedPolygonColor || '#61C6FA';
			let color = IrrigatedAreaColor[index] || '#61C6FA';
			if (item.space == "" || item.space == null) {
				continue;
			}
			let fanWeiData = JSON.parse(item.space);
			if (fanWeiData.hasOwnProperty("space")) {
				continue;
			}
			let html = '';
			if (item.data != null) {
				html = `
            <div class="x-popup-bg">
            <div class="x-popup-title">${name}</div>
            <div class="x-popup-close cursor" onclick="closePopup()"><img src="./gis/img/关闭.png" style="width: 20px"></div>
            <div class="x-popop-cnt text-sm ">
            <div class="flex ">
              
            </div>
            <div class="flex ">
                <div>安全研判：</div>
                 <div>A</div>
            </div>
            <div class="flex ">
                <div>设计灌溉面积：</div>
                 <div>${item.data.designedIrrigationArea || '--'} </div>
            </div>
            <div class="flex ">
                <div>有效灌溉面积：</div>
                 <div>${item.data.designStorageCapacity || '--'} </div>
            </div>
            <div class="flex ">
                <div>受益人口：</div>
                 <div>${item.data.crestElevation || '--'} 万人</div>
            </div>
               <div class="flex">
                <div>操作：</div>
                 <div class="x-check-num cursor" onclick="irrigatedAreaClick('${item.id}','${item.bindingId}','${name}')">查看详情</div>
            </div>
            </div>
            </div>
            `;
			}
			let coordinates = fanWeiData.geometry.coordinates;
			let positions = [];
			for (let i = 0; i < coordinates.length; i++) {
				if (type === "polygon") {
					positions.push(coordinates[i][0], coordinates[i][1]);
				} else {
					positions.push(coordinates[i][0], coordinates[i][1], 100);
				}
			}
			let entitie = "";
			if (type === "polygon") {
				entitie = viewer.entities.add({
					name: name,
					show: false,
					treeId: item.id,
					tooltip: {
						html: html,
						anchor: [0, -10],
					},
					// polyline: {
					//     positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
					//     width: 2,
					//     material: Cesium.Color.fromCssColorString('#ee00ff')
					// },

					polygon: {
						height: 40,
						hierarchy: Cesium.Cartesian3.fromDegreesArray(positions),
						material: Cesium.Color.fromCssColorString(color).withAlpha(alpha),
					},

					popup: {
						html: html,
						anchor: [0, -12], //左右、上下的偏移像素值。
						onAdd: function(eleId) {
							window._popup_eleId = eleId;
						},
					},
					click: function(e) { //单击后的回调
					}
				});
			} else {
				entitie = viewer.entities.add({
					name: name,
					show: false,
					treeId: item.id,
					tooltip: {
						html: html,
						anchor: [0, -10],
					},
					polyline: {
						positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
						width: 2,
						material: Cesium.Color.fromCssColorString('#ee00ff')
					},

					// polygon: {
					//     height: 40,
					//     hierarchy: Cesium.Cartesian3.fromDegreesArray(positions),
					//     material: Cesium.Color.fromCssColorString(color).withAlpha(alpha),
					// },

					popup: {
						html: html,
						anchor: [0, -12], //左右、上下的偏移像素值。
						onAdd: function(eleId) {
							window._popup_eleId = eleId;
						},
					},
					click: function(e) { //单击后的回调
					}
				});
			}
			state.IrrigatedAreaEntitie.push(entitie)
		}
	},

	// 灌区舆图显示
	showSLIrrigatedAreaInfo({
		commit
	}, type) {
		let alpha = .9;
		for (let item of state.irrigatedAreaInfo) {
			let name = item.name;
			let id = item.id;
			let color = item.irrigatedPolygonColor || '#61C6FA';
			if (item.irrigatedPolygonJson == null || item.irrigatedPolygonJson == "") {
				continue;
			}
			let fanWeiData = JSON.parse(item.irrigatedPolygonJson);
			console.log("灌区范围", item.name, fanWeiData)

			let html = `
            <div class="x-popup-bg">
            <div class="x-popup-title">${name}</div>
            <div class="x-popup-close cursor" onclick="closePopup()"><img src="./gis/img/关闭.png" style="width: 20px"></div>
            <div class="x-popop-cnt text-sm ">
            <div class="flex ">
                <div>安全研判：</div>
                 <div>A</div>
            </div>
            <div class="flex ">
                <div>设计灌溉面积：</div>
                 <div>${item.designedIrrigationArea || '--'}亩</div>
            </div>
            <div class="flex ">
                <div>有效灌溉面积：</div>
                 <div>${item.effectiveIrrigationArea || '--'} </div>
            </div>
            <div class="flex ">
                <div>受益人口：</div>
                 <div>${item.beneficialPopulation || '--'} 万人</div>
            </div>
               <div class="flex">
                <div>操作：</div>
                 <div class="x-check-num cursor" onclick="gqirrigatedAreaClick('${item.id}')">查看详情</div>
            </div>
            </div>
            </div>
            `;


			let coordinates = fanWeiData.features[0].geometry.coordinates[0];
			for (let coordinates of fanWeiData.features[0].geometry.coordinates) {
				let positions = [];
				for (let i = 0; i < coordinates.length; i++) {
					if (type === "polygon") {
						positions.push(coordinates[i][0], coordinates[i][1]);
					} else {
						positions.push(coordinates[i][0], coordinates[i][1], 100);
					}
				}
				let entitie = "";
				if (type === "polygon") {
					entitie = viewer.entities.add({
						name: name,
						show: true,
						serviceId: item.id,
						tooltip: {
							html: html,
							anchor: [0, -10],
						},
						// polyline: {
						//     positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
						//     width: 2,
						//     material: Cesium.Color.fromCssColorString('#ee00ff')
						// },
						polygon: {
							height: 30,
							hierarchy: Cesium.Cartesian3.fromDegreesArray(positions),
							material: Cesium.Color.fromCssColorString(color).withAlpha(alpha),
						},
						popup: {
							html: html,
							anchor: [0, -12], //左右、上下的偏移像素值。
							onAdd: function(eleId) {
								window._popup_eleId = eleId;
							},
						},
						click: function(e) { //单击后的回调
						}
					});
				} else {
					entitie = viewer.entities.add({
						name: name,
						show: true,
						serviceId: item.id,
						tooltip: {
							html: html,
							anchor: [0, -10],
						},
						polyline: {
							positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
							width: 2,
							material: Cesium.Color.fromCssColorString('#ee00ff')
						},
						// polygon: {
						//     height: 40,
						//     hierarchy: Cesium.Cartesian3.fromDegreesArray(positions),
						//     material: Cesium.Color.fromCssColorString(color).withAlpha(alpha),
						// },
						popup: {
							html: html,
							anchor: [0, -12], //左右、上下的偏移像素值。
							onAdd: function(eleId) {
								window._popup_eleId = eleId;
							},
						},
						click: function(e) { //单击后的回调
						}
					});
				}
				state.IrrigatedAreaEntitie.push(entitie)
			}


			// for (let i = 0, len = coordinates.length; i < len; i++) {
			//     if (type === "polygon") {
			//         positions.push(coordinates[i][0], coordinates[i][1]);
			//     } else {
			//         positions.push(coordinates[i][0], coordinates[i][1], 100);
			//     }
			// }


		}
	},

	// 显示水闸
	showSluice() {
		for (let item of state.sluiceEntitie) {
			viewer.entities.remove(item)
		};
		state.sluiceEntitie = [];
		// console.log(state.sluiceInfo)
		console.log("显示水闸方法")
		for (let item of state.sluiceInfo) {
			let name = item.name;
			let id = item.id;
			if (item.space == "" || item.space == null) {
				continue;
			}
			let fanWeiData = JSON.parse(item.space);
			if (fanWeiData.hasOwnProperty("space")) {
				continue;
			}
			let coordinates = fanWeiData.geometry.coordinates;
			let longitude = coordinates[0];
			let latitude = coordinates[1];
			let tuimg = item.img


			let html = `
			 <div class="x-popup-bg">
			 <div class="x-popup-title">${name}</div>
			 <div class="x-popup-close cursor" onclick="closePopup()"><img src="./gis/img/关闭.png" style="width: 20px"></div>
			 <div class="x-popop-cnt text-sm ">
			 <div class="flex ">
			     <div>安全研判：</div>
			      <div>A</div>
			 </div>
			 <div class="flex ">
			     <div>工程编码：</div>
			      <div>--</div>
			 </div>
			 <div class="flex ">
			     <div class="textWidth">工程管理单位：</div>
			     <div class="textColor">--</div>
			 </div>
			 <div class="flex ">
			     <div>工程管理单位负责人：</div>
			      <div>--</div>
			 </div>
			<div class="flex ">
			     <div>负责人员：</div>
			      <div>--</div>
			 </div>
			  <div class="flex ">
			     <div>负责人员联系方式：</div>
			      <div>--</div>
			 </div>
			 </div>
			 </div>
			 `;;
			if (item.data != null) {
				html = `
			 <div class="x-popup-bg">
			 <div class="x-popup-title">${name}</div>
			 <div class="x-popup-close cursor" onclick="closePopup()"><img src="./gis/img/关闭.png" style="width: 20px"></div>
			 <div class="x-popop-cnt text-sm ">
			 <div class="flex ">
			     <div>安全研判：</div>
			      <div>A</div>
			 </div>
			 <div class="flex ">
			     <div>工程编码：</div>
			      <div>${item.data.code || '--'}</div>
			 </div>
			 <div class="flex ">
			     <div class="textWidth">工程管理单位：</div>
			     <div class="textColor">${item.data.engineeringManageUnit || '--'}</div>
			 </div>
			 <div class="flex ">
			     <div>工程管理单位负责人：</div>
			      <div>${item.data.engineeringManageFzr || '--'}</div>
			 </div>
			<div class="flex ">
			     <div>负责人员：</div>
			      <div>--</div>
			 </div>
			  <div class="flex ">
			     <div>负责人员联系方式：</div>
			      <div>--</div>
			 </div>
			    <div class="flex">
			     <div>操作：</div>
			      <div class="x-check-num cursor" onclick="sluiceClick('${item.id}','${item.bindingId}','${name}')">查看详情</div>
			 </div>
			 </div>
			 </div>
			 `;
			}

			let entitie = addEntitiePoint(name, longitude, latitude, tuimg, html, 0.29);
			entitie.show = false;
			entitie.treeId = item.id;
			state.sluiceEntitie.push(entitie)
		}
	},

	// 显示泵房
	showPumpInfo() {
		for (let item of state.pumpEntitie) {
			viewer.entities.remove(item)
		};
		state.pumpEntitie = []
		for (let item of state.pumpInfo) {
			let name = item.name;
			let id = item.id;
			if (item.space == "" || item.space == null) {
				continue;
			}
			let fanWeiData = JSON.parse(item.space);
			if (fanWeiData.hasOwnProperty("space")) {
				continue;
			}
			let coordinates = fanWeiData.geometry.coordinates;
			let longitude = coordinates[0];
			let latitude = coordinates[1];
			let yongimg = item.img;

			let html = `
            <div class="x-popup-bg">
            <div class="x-popup-title">${name}</div>
            <div class="x-popup-close cursor" onclick="closePopup()"><img src="./gis/img/关闭.png" style="width: 20px"></div>
            <div class="x-popop-cnt text-sm ">
            <div class="flex ">
                <div>安全研判：</div>
                 <div>A</div>
            </div>
            <div class="flex ">
                <div>泵组数量：</div>
                 <div>--</div>
            </div>
            <div class="flex ">
                <div>开泵时间：</div>
                 <div>--</div>
            </div>
            <div class="flex ">
                <div>持续时长：</div>
                 <div>--</div>
            </div>
           <div class="flex ">
                <div>负责人：</div>
                 <div>--</div>
            </div>
             <div class="flex ">
                <div>负责人员联系方式：</div>
                 <div>--</div>
            </div>
            </div>
            </div>
            `;
			if (item.data != null) {
				html = `
            <div class="x-popup-bg">
            <div class="x-popup-title">${name}</div>
            <div class="x-popup-close cursor" onclick="closePopup()"><img src="./gis/img/关闭.png" style="width: 20px"></div>
            <div class="x-popop-cnt text-sm ">
            <div class="flex ">
                <div>安全研判：</div>
                 <div>A</div>
            </div>
            <div class="flex ">
                <div>泵组数量：</div>
                 <div>--</div>
            </div>
            <div class="flex ">
                <div>开泵时间：</div>
                 <div>--</div>
            </div>
            <div class="flex ">
                <div>持续时长：</div>
                 <div>--</div>
            </div>
           <div class="flex ">
                <div>负责人：</div>
                 <div>--</div>
            </div>
             <div class="flex ">
                <div>负责人员联系方式：</div>
                 <div>--</div>
            </div>
               <div class="flex">
                <div>操作：</div>
                 <div class="x-check-num cursor" onclick="pumpClick('${item.id}','${item.bindingId}','${name}')">查看详情</div>
            </div>
            </div>
            </div>
            `;
			}
			let entitie = addEntitiePoint(name, longitude, latitude, yongimg, html, 0.29);
			entitie.treeId = item.id;
			entitie.show = false;
			state.pumpEntitie.push(entitie)
		}
	},

	// 显示视频监视站
	showTVInfo() {
		for (let item of state.SpTVInfoEntitie) {
			viewer.entities.remove(item)
		};
		state.SpTVInfoEntitie = []
		for (let item of state.SpTVInfo) {
			let name = item.name;
			let id = item.id;
			if (item.space == "" || item.space == null) {
				continue;
			}
			let fanWeiData = JSON.parse(item.space);
			if (fanWeiData.hasOwnProperty("space")) {
				continue;
			}
			let coordinates = fanWeiData.geometry.coordinates;
			let longitude = coordinates[0];
			let latitude = coordinates[1];
			let yongimg = item.img;

			let html = '';
			if (item.data != null) {
				html = `
            <div class="x-popup-bg">
            <div class="x-popup-title">${name}</div>
            <div class="x-popup-close cursor" onclick="closePopup()"><img src="./gis/img/关闭.png" style="width: 20px"></div>
            <div class="x-popop-cnt text-sm ">
            <div class="flex ">
                <div>安全研判：</div>
                 <div>A</div>
            </div>
            <div class="flex ">
                <div>视频数量：</div>
                 <div>--</div>
            </div>
            <div class="flex ">
                <div>开泵时间：</div>
                 <div>--</div>
            </div>
            <div class="flex ">
                <div>持续时长：</div>
                 <div>--</div>
            </div>
           <div class="flex ">
                <div>负责人：</div>
                 <div>--</div>
            </div>
             <div class="flex ">
                <div>负责人员联系方式：</div>
                 <div>--</div>
            </div>
               <div class="flex">
                <div>操作：</div>
                 <div class="x-check-num cursor" onclick="pumpClick('${item.id}','${item.bindingId}','${name}')">查看详情</div>
            </div>
            </div>
            </div>
            `;
			}


			let entitie = addEntitiePoint(name, longitude, latitude, yongimg, html, 0.29);
			entitie.treeId = item.id;
			entitie.show = false;
			state.SpTVInfoEntitie.push(entitie)
		}
	},

	// 显示重要地址
	showImportantAddress() {
		for (let item of state.importantAddressInfo) {
			let name = item.name;
			let id = item.id;
			// if (item.space == "" || item.space == null) {
			//     continue;
			// }
			// let fanWeiData = JSON.parse(item.space);
			// if (fanWeiData.hasOwnProperty("space")) {
			//     continue;
			// }
			// let coordinates = fanWeiData.geometry.coordinates;
			// let longitude = coordinates[0];
			// let latitude = coordinates[1];
			let longitude = item.longitude;
			let latitude = item.latitude;

			let scale = 0.11;
			if (name === "垦利区人民政府") {
				scale = 0.2;
			}
			let entitie = addEntitiePoint(name, longitude, latitude, pointHongXingImg, "", scale);
			state.importantAddressEntitie.push(entitie)
		}
	},


	// 显示水厂
	showWaterworks() {
		for (let item of state.waterworksEntitie) {
			viewer.entities.remove(item)
		};
		state.waterworksEntitie = []
		for (let item of state.waterworksInfo) {
			let name = item.name;
			let id = item.id;
			if (item.space == "" || item.space == null) {
				continue;
			}
			let fanWeiData = JSON.parse(item.space);
			if (fanWeiData.hasOwnProperty("space")) {
				continue;
			}
			let coordinates = fanWeiData.geometry.coordinates;
			let longitude = coordinates[0];
			let latitude = coordinates[1];

			let entitie = addEntitiePoint(name, longitude, latitude, pointShuiChangImg, "", );
			entitie.show = false;
			entitie.treeId = item.id;
			state.waterworksEntitie.push(entitie)
		}
	},

	// 隐藏河道
	hiddenRiversInfo({
		commit
	}, data = false) {
		for (let item of state.riversEntitie) {
			item.show = data;
		}
	},

	// 隐藏视频监视站
	hiddenTvInfo({
		commit
	}, data = false) {
		for (let item of state.SpTVInfoEntitie) {
			item.show = data;
		}
	},

	// 隐藏干渠
	hiddenChannelInfo({
		commit
	}, data = false) {
		for (let item of state.channelEntitie) {
			if (item.level === '10') {
				item.show = data;
			}
		}
	},

	// 隐藏支渠
	hiddenChannelBranchInfo({
		commit
	}, data = false) {
		for (let item of state.channelEntitie) {
			if (item.level !== '10') {
				item.show = data;
			}
		}
	},


	// 根据ID显示干渠
	showChannelInfoOfId({
		commit
	}, id) {
		for (let item of state.channelEntitie) {
			if (item.treeId.toString() === id.toString() && item.name != '纪冯干渠（规划）') {
				// animationFun(item);
				item.show = true;
				// viewer.mars.flyTo(item, {
				// 	scale: 2.5,
				// 	radius: 2000
				// });
			} else {
				item.show = false;
			}
		}
	},
	// 根据ID数组显示干渠
	showChannelInfoOfIdArr({
		commit
	}, ids) {
		for (let item of state.channelEntitie) {
			if (ids.includes(item.treeId) && item.name != '纪冯干渠（规划）') {
				item.show = true;
			} else {
				item.show = false;
			}
		}
	},

	// 根据ID飞行干渠
	flyChannelInfoOfId({
		commit
	}, id) {
		for (let item of state.channelEntitie) {
			if (item.treeId.toString() === id.toString()) {
				item.show = true;
				viewer.mars.flyTo(item, {
					scale: 2.5,
					radius: 2000
				});
				// animationFun(item);
				return;
			}
		}
	},


	// 根据ID显示河道
	showRiversInfoOfId({
		commit
	}, id) {
		for (let item of state.riversEntitie) {
			if (item.treeId.toString() === id.toString()) {
				// animationFun(item);
				item.show = true;
				viewer.mars.flyTo(item, {
					// scale: 2.5,
					// radius: 2000
				});
			} else {
				item.show = false;
			}
		}
	},

	// 飞行河道
	flyRiversInfoOfId({
		commit
	}, id) {
		for (let item of state.riversEntitie) {
			if (item.treeId.toString() === id.toString()) {
				// animationFun(item);
				item.show = true;
				viewer.mars.flyTo(item, {
					scale: 2.5,
					radius: 2000
				});
			}
		}
	},


	// 根据ID数组显示河道
	showRiversInfoOfIdArr({
		commit
	}, ids) {
		for (let item of state.riversEntitie) {
			if (ids.includes(item.treeId)) {
				item.show = true;
			} else {
				item.show = false;
			}
		}
	},

	// 根据ID数组显示河道管理范围
	showRiversManagerInfoOfIdArr({
		commit
	}, ids) {
		for (let item of state.riversManagementEntitie) {
			if (ids.includes(item.treeId)) {
				item.show = true;
			} else {
				item.show = false;
			}
		}
	},

	// 显示排污口信息
	showRiversDrainOutletInfoOfIdArr({
		commit
	}, ids) {
		for (let item of state.riversDrainOutletEntitie) {
			viewer.entities.remove(item)
		}
		state.riversDrainOutletEntitie = [];
		if (ids.length === 0) {
			return;
		}
		getDrainOutlet(ids).then((val) => {
			let scale = 0.4;
			for (let item of val) {
				let name = item.name;
				let longitude = item.longitude;
				let latitude = item.latitude;

				let html = '';
				if (item.data != null) {
					html = `
                        <div class="x-popup-bg">
                        <div class="x-popup-title" title="${name}">${name}</div>
                        <div class="x-popup-close cursor" onclick="closePopup()"><img src="./gis/img/关闭.png" style="width: 20px"></div>
                        <div class="x-popop-cnt text-sm ">
                
                        <div class="flex ">
                            <div>排口类型：</div>
                             <div>${item.data.pklx}</div>
                        </div>
                        <div class="flex ">
                            <div>主管部门：</div>
                             <div>${item.data.zgbm}</div>
                        </div>
                        <div class="flex ">
                            <div>整治标准及措施：</div>
                             <div>${item.data.zzbzjcs}</div>
                        </div>
                       <div class="flex ">
                            <div>是否完成整治：</div>
                             <div>${item.data.sfwczj}</div>
                        </div>
                         <div class="flex ">
                            <div>是否纳入排污许可管理：</div>
                             <div>${item.data.sfnrpwxkgl}</div>
                        </div>
                       <div class="flex ">
                            <div>整治进展：</div>
                             <div>${item.data.zzjz}</div>
                        </div>
                          <div class="flex ">
                            <div>是否树标志牌：</div>
                          <div>${item.data.zzjz}</div>
                        </div>
                        </div>
                        </div>
                        </div>
                        `;
				}
				let entitie = addEntitiePoint(name, longitude, latitude, pwkImg, html, scale);
				state.riversDrainOutletEntitie.push(entitie)
			}
		});
	},


	// 隐藏水库
	hiddenReservoirInfo({
		commit
	}, data = false) {
		for (let item of state.reservoirEntitie) {
			item.show = data;
		}
	},
	// 根据ID显示水库
	showReservoirInfoOfId({
		commit
	}, id) {
		for (let item of state.reservoirEntitie) {
			if (item.treeId.toString() === id.toString()) {
				item.show = true;
				viewer.mars.flyTo(item, {
					scale: 2.5,
					radius: 2000
				});
			} else {
				item.show = false;
			}
		}
	},

	// 根据ID数组显示水库
	showReservoirInfoOfIdArr({
		commit
	}, ids) {
		for (let item of state.reservoirEntitie) {
			if (ids.includes(item.treeId)) {
				item.show = true;
			} else {
				item.show = false;
			}
		}
	},

	// 根据ID飞行水库
	flyReservoirInfoOfId({
		commit
	}, id) {
		for (let item of state.reservoirEntitie) {
			if (item.treeId.toString() === id.toString()) {
				item.show = true;
				viewer.camera.flyTo({
					destination: Cesium.Cartesian3.fromDegrees(item.positon[0], item.positon[1], 15000),
					// orientation: {//设置相机的Heading，Pitch，Roll，参照上图
					//     heading: Cesium.Math.toRadians(0),
					//     pitch: Cesium.Math.toRadians(0),
					//     roll: 0.0
					// },
					duration: 3
				})
				// animationFun(item);
				return;
			}
		}
	},

	// 隐藏沉砂池
	hiddenReservoirCSCInfo({
		commit
	}, data = false) {
		for (let item of state.chenshachiEntitie) {
			item.show = data;
		}
	},

	// 根据ID数组显示沉砂池
	showReservoirCSCInfoOfIdArr({
		commit
	}, ids) {
		for (let item of state.chenshachiEntitie) {
			if (ids.includes(item.treeId)) {
				item.show = true;
			} else {
				item.show = false;
			}
		}
	},

	// 根据ID飞行水库沉砂池
	flyReservoirCSCInfoOfId({
		commit
	}, id) {
		for (let item of state.chenshachiEntitie) {
			if (item.treeId.toString() === id.toString()) {
				if (item.show == true) {
					viewer.camera.flyTo({
						destination: Cesium.Cartesian3.fromDegrees(item.positon[0], item.positon[1], 15000),
						// orientation: {//设置相机的Heading，Pitch，Roll，参照上图
						//     heading: Cesium.Math.toRadians(0),
						//     pitch: Cesium.Math.toRadians(0),
						//     roll: 0.0
						// },
						duration: 3
					})
					// animationFun(item);
					return;
				}
			}
		}
	},


	// 隐藏灌区
	hiddenIrrigatedAreaInfo({
		commit
	}, data = false) {
		for (let item of state.IrrigatedAreaEntitie) {
			item.show = data;
		}
	},

	// 根据ID显示灌区
	showIrrigatedAreaInfoOfId({
		commit
	}, id) {
		for (let item of state.IrrigatedAreaEntitie) {
			if (item.treeId.toString() == id.toString()) {
				item.show = true;
				viewer.mars.flyTo(item, {
					scale: 2.5,
					radius: 2000
				});
			} else {
				item.show = false;
			}
		}
	},

	// 根据ID数组显示灌区
	showIrrigatedAreaOfId({
		commit
	}, ids) {
		for (let item of state.IrrigatedAreaEntitie) {
			if (ids.includes(item.treeId)) {
				item.show = true;
			} else {
				item.show = false;
			}
		}
	},

	// 飞行灌区
	flyIrrigatedAreaOfId({
		commit
	}, id) {
		for (let item of state.IrrigatedAreaEntitie) {
			if (item.treeId.toString() === id.toString()) {
				item.show = true;
				viewer.mars.flyTo(item, {
					scale: 2.5,
					radius: 2000
				});
				// animationFun(item);
				return;
			}
		}
	},

	// 隐藏水闸
	hiddenSluiceInfo({
		commit
	}, data = false) {
		for (let item of state.sluiceEntitie) {
			item.show = data;
		}
	},
	// 根据ID显示水闸
	showSluiceOfId({
		commit
	}, id) {
		console.log(state)
		for (let item of state.sluiceEntitie) {
			if (item.treeId.toString() == id.toString()) {
				item.show = true;
				console.log(item)
				viewer.mars.flyTo(item, {
					scale: 2.5,
					radius: 2000
				});
			} else {
				item.show = false;
			}
		}
	},

	// 根据ID数组显示水闸
	showSluiceInfoOfIdArr({
		commit
	}, ids) {
		// console.log(state.sluiceEntitie)
		for (let item of state.sluiceEntitie) {
			if (ids.includes(item.treeId)) {
				item.show = true;
			} else {
				item.show = false;
			}
		}
	},

	// 根据ID飞行水闸
	flySluiceInfoOfId({
		commit
	}, id) {
		for (let item of state.sluiceEntitie) {
			if (item.treeId.toString() === id.toString()) {
				item.show = true;
				viewer.mars.flyTo(item, {
					scale: 2.5,
					radius: 2000
				});
				// animationFun(item);
				return;
			}
		}
	},

	// 隐藏泵房
	hiddenPumpInfo({
		commit
	}, data = false) {
		for (let item of state.pumpEntitie) {
			item.show = data;
		}
	},

	// 根据ID显示泵房
	showPumpOfId({
		commit
	}, id) {
		for (let item of state.pumpEntitie) {
			if (item.treeId.toString() === id.toString()) {
				item.show = true;
				viewer.mars.flyTo(item, {
					scale: 2.5,
					radius: 2000
				});
			} else {
				item.show = false;
			}
		}
	},

	// 根据ID数组显示泵房
	showPumpInfoOfIdArr({
		commit
	}, ids) {

		// console.log(state.pumpEntitie)
		for (let item of state.pumpEntitie) {
			if (ids.includes(item.treeId)) {
				item.show = true;
			} else {
				item.show = false;
			}
		}
	},

	// 根据ID飞行泵房
	flyPumpInfoOfId({
		commit
	}, id) {
		for (let item of state.pumpEntitie) {
			if (item.treeId.toString() === id.toString()) {
				item.show = true;
				viewer.mars.flyTo(item, {
					scale: 2.5,
					radius: 2000
				});
				// animationFun(item);
				return;
			}
		}
	},

	// 隐藏水厂
	hiddenWaterworksInfo({
		commit
	}, data = false) {
		for (let item of state.waterworksEntitie) {
			item.show = data;
		}
	},
	// 根据ID数组显示水厂
	showWaterworksInfoOfIdArr({
		commit
	}, ids) {
		for (let item of state.waterworksEntitie) {
			if (ids.includes(item.treeId)) {
				item.show = true;
			} else {
				item.show = false;
			}
		}
	},


	// 根据ID飞行水厂
	flyWaterworksInfoOfId({
		commit
	}, id) {
		for (let item of state.waterworksEntitie) {
			if (item.treeId.toString() === id.toString()) {
				item.show = true;
				viewer.mars.flyTo(item, {
					scale: 2.5,
					radius: 2000
				});
				// animationFun(item);
				return;
			}
		}
	},

	// 隐藏交叉建筑物
	hiddenJCJZWInfo({
		commit
	}, data = false) {
		for (let item of state.jcjzwEntitie) {
			item.show = data;
		}
	},

	// 隐藏交叉建筑物
	hiddenHDJSZInfo({
		commit
	}, data = false) {
		for (let item of state.hdjszEntitie) {
			item.show = data;
		}
	},

	// 根据ID数组显示交叉建筑物
	showJCJZWInfoOfIdArr({
		commit
	}, ids) {
		for (let item of state.jcjzwEntitie) {
			if (ids.includes(item.treeId)) {
				item.show = true;
			} else {
				item.show = false;
			}
		}
	},

	// 根据ID数组显示涵洞进水闸
	showHDJSZInfoOfIdArr({
		commit
	}, ids) {
		for (let item of state.hdjszEntitie) {
			if (ids.includes(item.treeId)) {
				item.show = true;
			} else {
				item.show = false;
			}
		}
	},
	// 根据ID飞行生产桥
	flyJcjzwInfoOfId({
		commit
	}, id) {
		for (let item of state.jcjzwEntitie) {
			if (item.treeId.toString() === id.toString()) {
				item.show = true;
				viewer.mars.flyTo(item, {
					scale: 2.5,
					radius: 2000
				});
				// animationFun(item);
				return;
			}
		}
	},

	// 显示取水口弹窗
	showQushuikou() {
		for (let item of state.qushuikouInfo) {
			let longitude = item.longitude;
			let latitude = item.latitude;
			let entitie = addEntitiePoint(item.name, longitude, latitude, pointShuiChangImg, item.name, 0.15);
			// entitie.show = false;
			entitie.treeId = item.id;
			state.qushuikouEntitie.push(entitie)
		}
	},

	// 根据ID飞行取水口-弹窗
	flyqushuikouInfoOfId({
		commit
	}, id) {
		for (let item of state.qushuikouEntitie) {
			if (item.treeId.toString() === id.toString()) {
				item.show = true;
				viewer.mars.flyTo(item, {
					scale: 2.5,
					radius: 2000
				});
				// animationFun(item);
				return;
			}
		}
	},
	/**
	 * 添加自定义点位
	 */
	// 机场显示
	addPoint() {
		let entitie = addEntitiePoint("胜利机场", 118.787593, 37.502530, pointJiChangImg, "", 0.15);
		entitie.show = true;
		state.airportEntitie.push(entitie)
	},
	// 机场隐藏
	hiddenAirportInfo({
		commit
	}, data = false) {
		for (let item of state.airportEntitie) {
			item.show = data;
		}
	},

	// 隐藏重要地址
	hiddenImportantAddress({
		commit
	}) {
		for (let item of state.importantAddressEntitie) {
			if (item.show == true) {
				item.show = false
			} else {
				item.show = true
			}
		}
	},
	// hiddenImportantAddress({
	// 	commit
	// }, data = false) {
	// 	for (let item of state.importantAddressEntitie) {
	// 		item.show = data;
	// 	}
	// },

	ImportantAddress({
		commit
	}, data = false) {
		for (let item of state.importantAddressEntitie) {
			item.show = data;
		}
	},


	// 点击数隐藏之前的闪动选项
	hideAll({
		commit
	}) {
		// for (let item of state.sluiceEntitie) {
		// 	item.show = false;
		// };
		// for (let item of state.pumpEntitie) {
		// 	item.show = false;
		// };
		// for (let item of state.reservoirEntitie) {
		// 	item.show = false;
		// };
		// for (let item of state.IrrigatedAreaEntitie) {
		// 	item.show = false;
		// };
		// for (let item of state.riversEntitie) {
		// 	item.show = false;
		// };
	}


};


export default {
	namespaced: true,
	state,
	mutations,
	actions
}

let interval;
let checkItem;

function animationFun(item) {
	if (interval) {
		clearInterval(interval)
		if (checkItem) {
			checkItem.show = true;
		}
	}

	checkItem = item;
	interval = setInterval(() => {
		item.show = !item.show;
	}, 800)

	setTimeout(() => {
		clearInterval(interval)
	}, 1000 * 10)
}

/**
 * 添加点位数据
 * @param name
 * @param lon
 * @param lat
 * @param pointImg
 * @param html
 */
function addEntitiePoint(name, lon, lat, pointImg, html, scale = 0.11) {
	let entitie = viewer.entities.add({
		position: Cesium.Cartesian3.fromDegrees(lon, lat),
		show: true,
		billboard: {
			image: pointImg,
			scale: scale, //原始大小的缩放比例
			horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
			verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
			scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 8.0e6, 0.1),
			disableDepthTestDistance: Number.POSITIVE_INFINITY, //一直显示，不被地形等遮挡
		},
		label: {
			show: false,
			text: name,
			font: "normal small-caps normal 19px 楷体",
			style: Cesium.LabelStyle.FILL_AND_OUTLINE,
			fillColor: Cesium.Color.fromCssColorString("#ffffff").withAlpha(1),
			outlineWidth: 2,
			horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
			verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
			pixelOffset: new Cesium.Cartesian2(20, -10), //偏移量
			heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, //贴地
		},
		tooltip: {
			html: name,
			anchor: [0, -10],
		},
		popup: {
			html: html,
			anchor: [0, -12], //左右、上下的偏移像素值。
			onAdd: function(eleId) {
				window._popup_eleId = eleId;
			},
		},
		click: function(e) { //单击后的回调
		}
	});
	return entitie;
}