<template>
  <div style="pointer-events: auto">
    <x-dialog-comp title="永镇水库简介" @on-close="$emit('on-close')">
      <div style="text-align: left;padding: 20px">
        永镇水库
        位于垦利县城以东约20公里处，由山东省水利勘测设计院设计。1998年投资3000万元，建成土围坝水库。2001年列入日元贷款项目，投资8201万元，对水库进行扩建增容。扩建增容工程设计蓄水量3972万立方米，
        坝顶高程10.4米，
        防浪墙高程11.6米，设计蓄水位9.12米，坝顶宽度8.0米，坝体内外边坡均为1︰3。围坝上游坝坡采用平面槽式预制混凝土板护坡，自上而下共分3层，依次为140毫米厚C20混凝土预制板、100毫米厚粒径5～20毫米碎石垫层和复合土工膜。整个工程2001年4月开工，12月竣工验收合格。
      </div>
      
      <div>
        <img src="@/assets/YongZhenShuiKu/jj/1.png">
      </div>
    </x-dialog-comp>
  </div>
</template>

<script>
import XDialogComp from "@/components/x-dialog-comp";
import XDialogCompSmall from "@/components/x-dialog-comp-small";
import XTitleBtn from "@/components/x-title-btn";

export default {
  name: "x-yzskjj",
  components: {XDialogComp, XTitleBtn, XDialogCompSmall},
  data: () => {
    return {
      name: "",
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    /**
     * 关闭点击
     */
    closeClick() {
      this.$emit('on-close')
    },

  }
}
</script>

<style scoped>
.x-tc-page-bg {
  background: url("~@/assets/common/tanChuang.png") no-repeat;
  background-size: 100% 100%;
  width: 1132px;
  height: 763px;
  position: fixed;
  top: 100px;
  left: 400px;
  z-index: 99999;
  margin-left: 55px;
}

.x-tc-title {
  font-size: 22px;
  position: absolute;
  left: 80px;
  top: 15px;
  color: white;
  letter-spacing: 5px;
}

.x-close {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 30px;
  cursor: pointer;
}

.x-tc-cnt {
  width: 1080px;
  margin-left: 25px;
  height: 700px;
  overflow: auto;
  position: absolute;
  top: 58px;
  overflow: hidden;
  background-color: white;
}

.x-table-title-list-bg {
  width: 98%;
  margin-left: 1%;
  text-align: center;
  line-height: 40px;
  height: 40px;
}

.x-table-title-list-bg div {
  flex: 1;
}

.x-table-title-list-bg:hover {
  background: #032e5a;
}
</style>
