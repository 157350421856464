import request from '@/utils/request'


// 查询公示牌基础信息详细
export function getBillboard(id) {
  return request({
    url: '/elements/billboard/' + id,
    method: 'get'
  })
}

// 查询界桩基础信息详细
export function getJzjcxx(id) {
  return request({
    url: '/elements/jzjcxx/' + id,
    method: 'get'
  })
}
// 查询界址点详细
export function getBoundarypoint(id) {
  return request({
    url: '/water/boundarypoint/' + id,
    method: 'get'
  })
}
