import request from '@/utils/request'

// 查询设备简短列表，主页列表数据
export function jiankongsn(query) {
  return request({
    url: '/iot/device/shortList',
    method: 'get',
    params: query
  })
}
// 查询设备详细
export function getDevice(deviceId) {
    return request({
        url: '/iot/device/' + deviceId,
        method: 'get'
    })
}