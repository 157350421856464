import request from '@/utils/request'

// 河道
export function getRivers(query) {
    return request({
        url: '/water/rivers/list',
        method: 'get',
        params: query
    })
}

// 水库
export function getReservoir(query) {
    return request({
        url: '/water/reservoir/list',
        method: 'get',
        params: query
    })
}

// 灌区
export function getIrrigatedArea(query) {
    return request({
        url: '/water/irrigatedArea/list',
        method: 'get',
        params: query
    })
}

// 渠道
export function getChannel(query) {
    return request({
        url: '/water/channel/list',
        method: 'get',
        params: query
    })
}

// 水闸
export function getSluice(query) {
    return request({
        url: '/water/sluice/list',
        method: 'get',
        params: query
    })
}

// 泵房
export function getPumpHouse(query) {
    return request({
        url: '/water/pumpHouse/list',
        method: 'get',
        params: query
    })
}

// 重要地点
export function getImportantAddress(query) {
    return request({
        url: '/elements/importantAddress/list',
        method: 'get',
        params: query
    })
}

// 查询水厂基础信息列表
export function getWaterworks(query) {
    return request({
        url: '/elements/waterworks/list',
        method: 'get',
        params: query
    })
}

// 查询排污口基础信息列表
export function getDrainOutlet(id) {
    return request({
        url: '/elements/drainOutlet/getInfos/' + id,
        method: 'get'
    })
}
