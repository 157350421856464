<template>
  <div>
    <x-dialog-comp title="全景图" @on-close="$emit('on-close')">
      <iframe :src="src" style="width: 100%;height: 100%"  frameborder="no" border="0" marginwidth="0" marginheight="0" scrolling="no" allowtransparency="yes"></iframe>
    </x-dialog-comp>
  </div>
</template>

<script>
import XDialogComp from "@/components/x-dialog-comp";
import {listFloorData} from "@/api/data/floorData";

export default {
  name: "x-overallView",
  components: {XDialogComp},
  props: [
    'associatedReservoirId',
    'associatedRiversId',
    'associatedChannelId',
    'associatedIrrigatedAreaId',
    'associatedPumpHouseId',
    'associatedSluiceId',
  ],
  data() {
    return {
      searchData: {
        pageNum: 1,
        pageSize: 999,
        associatedReservoirId: '',
        associatedRiversId: '',
        associatedChannelId: '',
        associatedIrrigatedAreaId: '',
        associatedPumpHouseId: '',
        associatedSluiceId: '',
      },
			src:window.$GISIMAGEURL + '/zhslp/%E5%85%A8%E6%99%AF%E5%9B%BE/%E6%B0%B8%E9%95%87%E6%B0%B4%E5%BA%93/tour.html',
    }
  },
  created() {
    if (this.associatedReservoirId) {
      this.searchData.associatedReservoirId = "reservoir" + this.associatedReservoirId;
    }
    if (this.associatedRiversId) {
      this.searchData.associatedRiversId = "rivers" + this.associatedRiversId;
    }
    if (this.associatedChannelId) {
      this.searchData.associatedChannelId = "channel" + this.associatedChannelId;
    }
    if (this.associatedIrrigatedAreaId) {
      this.searchData.associatedIrrigatedAreaId = "irrigatedArea" + this.associatedIrrigatedAreaId;
    }
    if (this.associatedPumpHouseId) {
      this.searchData.associatedPumpHouseId = "pump " + this.associatedPumpHouseId;
    }
    if (this.associatedSluiceId) {
      this.searchData.associatedSluiceId = "sluice" + this.associatedSluiceId;
    }
    this.initData();
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    initData(){
      listFloorData(this.searchData).then((val) => {
      });
    }
  },
}
</script>

<style scoped>

</style>
