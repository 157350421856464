<template>
  <div style="pointer-events: auto;z-index: 1000;">
    <x-dialog-comp title="水位监测" @on-close="$emit('on-close')">
      <x-title-btn :menu-data.sync="titleMenuData"></x-title-btn>

      <div v-if="titleMenuData[0].checkType">
        <div class="x-table-title-bg x-tab-title-bg" style="width:100%;text-align: center;font-size: 14px">
          <div style="flex:1"><strong>名称</strong></div>
          <div style="flex:1"><strong>数值</strong></div>
        </div>

        <div style="height: 680px;overflow-y: auto;overflow-x: hidden;font-size: 14px" class="cursor">
          <div v-for="(item,index) in resData" ref="resDataListRef">
            <div class="x-table-title-list-bg"
                 style="width: 98%;margin-left: 1%;text-align: center;line-height:40px;height:40px;">
              <div style="flex:1">{{ item.name }}</div>
              <div style="flex:1">{{ item.value }}</div>
              <div style="flex:1" v-if="shangqingShow">{{ item.yuzhi }}</div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="titleMenuData[1].checkType" class="x-tc-cnt">

        <div class="x-card" v-for="(item,index) in resData" v-if="item.key!='caijiTime'">
          <div class="x-sm-title-bg">{{item.name}}</div>
          <HZEcharts class="x-card-cnt" width="250px" height="210px"></HZEcharts>
        </div>

      </div>

    </x-dialog-comp>

    <gongcheng ref="dialog" v-if="bzpt" :bzptId="bzptId" @on-point="bzpt=false">

    </gongcheng>

  </div>
</template>

<script>
import XDialogComp from "@/components/x-dialog-comp";
import HZEcharts from "@/components/hz-echarts/echarts";
import XTitleBtn from "@/components/x-title-btn";
import gongcheng from "@/components/x-device-ShangQing/tanchuang/gongcheng";

export default {
  name: "x-device-ShuiWei",
  props: ['compData', 'titleName'],

  components: {
    XTitleBtn,
    XDialogComp,
    HZEcharts,
    gongcheng,
  },

  data: () => {
    return {

      bzpt: false,
      bzptId: null, //选中的id
      resData: [],
      shangqingShow: false,

      titleMenuData: [{
        checkType: true,
        name: '实时数据',
      }, {
        checkType: false,
        name: '历史数据',
      }],
    }
  },
  created() {},
  mounted() {
    this.$request({
      url: `${window.$baseUrl_hz_bz}/api/guanqu/lastRecordBySn?serialNumber=${this.compData.bindingId}`,
      type: 'get',
    }).then((val) => {
      this.resData = val.data;
    });

  },
  beforeDestroy() {},
  methods: {
    handleClickbzpt(data) {
      this.bzptId = data;
      this.bzpt = true;
    },
    /**
     * 关闭点击
     */
    closeClick() {
      this.$emit('on-close')
    },

  }
}
</script>

<style scoped>
.x-tc-page-bg {
  background: url("~@/assets/common/tanChuang.png") no-repeat;
  background-size: 100% 100%;
  width: 1132px;
  height: 763px;
  position: fixed;
  top: 100px;
  left: 400px;
  z-index: 99999;
  margin-left: 55px;
}

.x-tc-page-twobg {
  background: url("~@/assets/common/tanChuang.png") no-repeat;
  background-size: 100% 100%;
  width: 1132px;
  height: 763px;
  position: fixed;
  top: 100px;
  left: 400px;
  z-index: 99999;
  margin-left: 55px;
}

.x-tc-title {
  font-size: 22px;
  position: absolute;
  left: 80px;
  top: 15px;
  color: white;
  letter-spacing: 5px;
}

.x-close {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 30px;
  cursor: pointer;
}

.x-tc-cnt {
  height: 680px;
  width: 1050px;
  overflow: auto;
}

.x-card {
  width: 230px;
  height: 270px;
  margin-right: 33px;
  float: left;
}

.x-card:nth-child(4n) {
  margin-right: 0px;
}

.x-card-cnt {
  width: 250px;
  height: 230px;
}

.x-sm-title-bg {
  background: url("~@/assets/ZhiNengXiTong/title.png") no-repeat;
  background-size: 100% 100%;
  width: 220px;
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-family: douyuFont-2;
  font-size: 14px;
}
</style>
