import request from '@/utils/request'

// 查询泵房基础信息列表
export function listPumpHouse(query) {
  return request({
    url: '/water/pumpHouse/list',
    method: 'get',
    params: query
  })
}

// 查询泵房基础信息详细
export function getPumpHouse(id) {
  return request({
    url: '/water/pumpHouse/' + id,
    method: 'get'
  })
}

// 新增泵房基础信息
export function addPumpHouse(data) {
  return request({
    url: '/water/pumpHouse',
    method: 'post',
    data: data
  })
}

// 修改泵房基础信息
export function updatePumpHouse(data) {
  return request({
    url: '/water/pumpHouse',
    method: 'put',
    data: data
  })
}

// 删除泵房基础信息
export function delPumpHouse(id) {
  return request({
    url: '/water/pumpHouse/' + id,
    method: 'delete'
  })
}
