<template>
	<div>
		<x-dialog-comp title="位移监测" @on-close="$emit('on-close')">
			<div v-loading="loadingType" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)">
				<x-title-btn :menu-data.sync="titleMenuData"></x-title-btn>

				<!-- <x-real-time v-if="titleMenuData[0].checkType" :device-id="compData.bindingId"></x-real-time>
				<x-history v-if="titleMenuData[1].checkType" :deviceid="compData.bindingId" :stationInfo="compData"></x-history>
				<x-details v-if="titleMenuData[2].checkType" :res-data="resData"></x-details>
				<div v-if="titleMenuData[3].checkType">
					<!-- <x-an-quan-fen-xi></x-an-quan-fen-xi> - ->
					<div class="x-aq-cntt">
						<x-an-quan-fen-xi :deviceId="compData.bindingId"></x-an-quan-fen-xi>
					</div>
					<div>
						<liebiao :deviceId="compData.bindingId"></liebiao>
					</div>
				</div> -->
				
				<x-real-time v-if="titleMenuData[0].checkType" :device-id="compData.bindingId"></x-real-time>
				<x-details v-if="titleMenuData[1].checkType" :res-data="resData"></x-details>
				<div v-if="titleMenuData[2].checkType">
					<!-- <x-an-quan-fen-xi></x-an-quan-fen-xi> -->
					<div class="x-aq-cntt">
						<x-an-quan-fen-xi :deviceId="compData.bindingId"></x-an-quan-fen-xi>
					</div>
					<div>
						<liebiao :deviceId="compData.bindingId"></liebiao>
					</div>
				</div>

			</div>
		</x-dialog-comp>
	</div>
</template>

<script>
	import XDialogComp from "@/components/x-dialog-comp";
	import XTitleBtn from "@/components/x-title-btn";
	import XRealTime from "@/components/x-device-displacement-dialog/components/x-real-time";
	import XDetails from "@/components/x-device-displacement-dialog/components/x-details";
	// import XAnQuanFenXi from "@/components/x-AnQuanFenXi";
	import XHistory from "@/components/x-device-displacement-dialog/components/x-history";
	import {
		getDisplacement
	} from "@/api/device/displacement";
	import XAnQuanFenXi from "@/components/x-AnQuanFenXi/yututanchaung";

	import liebiao from "@/components/x-AnQuanFenXi/liebiao";

	export default {
		name: "x-device-displacement-dialog",
		components: {
			XHistory,
			XAnQuanFenXi,
			XDetails,
			XRealTime,
			XDialogComp,
			XTitleBtn,
			liebiao
		},
		props: ['compData'],
		data() {
			return {
				loadingType: false,
				resData: {},
				titleMenuData: [{
					checkType: true,
					name: '实时数据',
				}, 
				/* {
					checkType: false,
					name: '历史数据',
				}, */
				{
					checkType: false,
					name: '设备详情',
				}, {
					checkType: false,
					name: '安全研判',
				}],
			}
		},
		created() {
			this.initData();
		},
		mounted() {},
		destroyed() {},
		methods: {
			initData() {
				getDisplacement(this.compData.bindingId).then((val) => {
					this.loadingType = false;
					this.resData = val.data;
				})
			},

		},
	}
</script>

<style scoped>
	.x-aq-cnt {
		width: 100%;
		height: 500px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.x-aq-cntt {
		width: 100%;
		margin-left: 0%;
		margin-top: 0px;
	}
</style>