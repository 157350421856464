/**
* @Author liYang
* @Date 2022/10/8 22:37
* @Description: TODO
* @Version 1.0
*/
<template>
  <x-dialog-comp title="告警信息" class="SourceHanSans-M">
    <div class="flex" style="width: 100%;height: 100%;">
      <div class="x-m-left">

        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="当前告警" name="当前告警"></el-tab-pane>
          <el-tab-pane label="历史告警" name="历史告警"></el-tab-pane>
        </el-tabs>

        <div class="flex">
          <div class="flex margin-top">
            <div>
              名 称：
            </div>
            <el-input v-model="searchData.name" placeholder="请输入内容" size="mini" style="width: 120px"></el-input>
          </div>
          <div class="margin-left margin-top">
            <el-button type="primary" size="mini">搜索</el-button>
          </div>
        </div>

        <div class="x-m-table margin-top">
          <div class="flex">
            <div class="flex-sub">
              告警标题
            </div>
            <div style="width: 150px">
              操作
            </div>
          </div>
          <div>
            <img src="@/assets/common/组 1568.png">
          </div>
        </div>

        <div style="height: 480px;overflow: auto">
          <div class="flex text-sm margin-bottom-sm" style="background-color: rgba(27,130,183,0.31);line-height: 35px"
               v-for="i in 25">
            <div class="flex-sub">
              宋圈村水位超境界线
            </div>
            <div style="width: 150px">
              查看
            </div>
          </div>
        </div>

        <div class="x-pagination margin-top">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="100"
              layout="sizes, prev, pager, next"
              :total="1400">
          </el-pagination>
        </div>
      </div>

      <div style="flex: 1;height: 100%;">

        <table style="border-collapse:collapse;">
          <tbody>
          <tr data-row="row-0" data-line="quill-table-6">
            <td rowspan="1" colspan="3" class="x-td-border">
              <div class="x-td-div">告警标题</div>
            </td>
            <td rowspan="1" colspan="3" class="x-td-border">
              <div class="x-td-div">宋圈村水位超境界线宋圈村水位超境界线宋圈村水位超境界线宋圈村水位超境界线宋圈村水位超境界线</div>
            </td>
            <td rowspan="1" colspan="3" class="x-td-border">
              <div class="x-td-div">告警标题</div>
            </td>
            <td rowspan="1" colspan="3" class="x-td-border">
              <div class="x-td-div">告警标题</div>
            </td>
          </tr>
          </tbody>
        </table>


      </div>

    </div>
  </x-dialog-comp>
</template>

<script>

import XDialogComp from "@/components/x-dialog-comp";

export default {
  name: "x-baoJingShuLiang",
  components: {XDialogComp},
  data() {
    return {
      activeName: '当前告警',
      searchData: {
        name: '',
      },
    }
  },

  created() {
  },

  mounted() {
  },

  beforeDestroy() {

  },

  methods: {
    handleClick() {

    },

    // 分页改变
    handleSizeChange(val) {
      this.searchData.limit = val;
      this.initYuAnData();
    },

    // 分页改变
    handleCurrentChange(val) {
      this.searchData.page = val;
      this.initYuAnData();
    },
  }
}
</script>

<style scoped>

.x-m-left {
  width: 500px;
  height: 100%;
  border-right: 1px solid rgba(255, 255, 255, 0.21);
  padding-left: 5px;
}


.x-m-left >>> .el-tabs__item {
  color: #ffffff;
}

.x-m-left >>> .el-tabs__item.is-active {
  color: #47d1ff;
}

.x-m-left >>> .el-tabs__active-bar {
  background-color: #47d1ff;
}


.x-pagination {
  width: 100px;
}

.x-pagination >>> .el-pagination__total {
  color: #ffffff;
}

.x-pagination >>> .el-input__inner {
  background-color: rgba(255, 255, 255, 0);
  color: #ffffff;
  border: 0;
}

.x-pagination >>> .btn-prev {
  background-color: rgba(255, 255, 255, 0);
  color: #ffffff;
}

.x-pagination >>> .btn-next {
  background-color: rgba(255, 255, 255, 0);
  color: #ffffff;
}


.x-pagination >>> .el-pager li {
  padding: 0 4px;
  background: #fff0;
}

.x-pagination >>> .el-pagination__jump {
  color: #ffffff;
}

.x-pagination >>> .el-pager li.active {
  color: #ffffff;
}

.x-pagination >>> .el-pager li.number {
  color: #ffffff;
}

.x-pagination >>> .el-pager li.btn-quicknext, .el-pager li.btn-quickprev {
  color: #ffffff;
}

.x-td-div{
  height: 50px;
  line-height: 50px;
  width: 140px;
}

.x-td-border{
  border: 1px solid rgb(204, 204, 204);
}
</style>
