import Recorder from 'recorder-core';
import 'recorder-core/src/engine/wav.js';
import 'recorder-core/src/engine/pcm.js';
import 'recorder-core/src/extensions/buffer_stream.player.js';

/**websocket连接服务器，播放语音**/
var RTP2Voice=function(config){
	this.set={
		wsUrl:"" //websocket地址
		,device:{ //设备信息
			ip:"" //ip地址
			,port:0 //端口
			,user:"" //用户名
			,pwd:"" //密码
			,channel:0 //要连接的通道
		}
		,shareDevice:true //共享设备连接，默认共享
		
		,onDisconnect:function(msg){  } //掉线时回调，可以重新open连接服务器
		
		,onPcmFrame:function(pcm,sampleRate,msgObj,binary,rawBytes){ } //接收到pcm数据回调
		,onUpdateTime:function(currentTime,state){  } //播放时间回调，单位ms，state：1播放中 2缓冲中 3已暂停 4已停止
	};
	for(var k in config){
		this.set[k]=config[k];
	}
	this.SID=0;//同步操作
	
	this.ws=null; //websocket对象
};
RTP2Voice.prototype={

/**打开连接，连上服务器**/
open:function(success,fail){
	var This=this, sid=++this.SID;
	this.__close(true);
	This.__openPlayer(sid, function(){
		This.__openWs(sid, success, fail);
	},fail);
}
/**关闭连接**/
,close:function(){
	++this.SID;
	this.__close();
}
,__close:function(keepPlay){
	if(!keepPlay && this.bsPlayer){
		try{ this.bsPlayer.stop() }catch(e){}
		this.bsPlayer=null;
	}
	var ws=this.ws; this.ws=null;
	if(ws){
		try{ ws.closeWs() }catch(e){}
		this.log("已关闭连接");
	}
}

/**继续播放**/
,resume:function(){
	if(this.bsPlayer){
		this.bsPlayer.resume();
		this.log("已继续播放");
	}
}
/**暂停播放**/
,pause:function(){
	if(this.bsPlayer){
		this.bsPlayer.pause();
		this.log("已暂停播放");
	}
}


,log:function(msg,err){
	var now=new Date();
	var t=("0"+now.getMinutes()).substr(-2)
		+":"+("0"+now.getSeconds()).substr(-2)
		+"."+("00"+now.getMilliseconds()).substr(-3);
	var arr=["["+t+" RTP2Voice]"+msg];
	var a=arguments;
	var i=2,fn=console.log;
	if(typeof(err)=="number"){
		fn=err==1?console.error:err==3?console.warn:fn;
	}else{
		i=1;
	};
	for(;i<a.length;i++){
		arr.push(a[i]);
	};
	fn.apply(console,arr);
}


//播放服务器发送来的pcm数据
,__playData:function(data,binary,rawBytes){
	var ws=this.ws;
	var pcm=new Int16Array(binary.buffer);
	var sr=data.sampleRate;
	if(sr>48000){ //转换采样率，原始数据192000的采样率
		sr=48000;
		ws.sdBuffers=ws.sdBuffers||[];
		ws.sdBuffers.push(pcm);
		var chunk=Recorder.SampleData(ws.sdBuffers,data.sampleRate,sr,ws.prevChunk);
		for(var i=ws.prevChunk?ws.prevChunk.index:0;i<chunk.index;i++){
			ws.sdBuffers[i]=null;
		};
		ws.prevChunk=chunk;
		pcm=chunk.data;
	}
	this.sampleRate=sr;
	try{
		this.set.onPcmFrame(pcm,sr,data,binary,rawBytes);
	}catch(e){
		this.log("onPcmFrame抛异常",1,e);
	}
	
	var stream=this.bsPlayer;
	if(!stream)return;
	if(!stream.isPause && stream.__isStart && stream._ctx && stream._ctx.state!="running"){
		this.log("播放器状态不正确，重新创建",1);
		stream.stop();
		this.bsPlayer=null;
		this.__openPlayer(this.SID,function(){},function(){});
		return;
	}
	
	//先缓冲500ms数据，先缓冲再播放
	if(!ws.isSendIB){
		var iBf=ws.inputBuffers||new Int16Array(0);
		var tmp=new Int16Array(iBf.length+pcm.length);
		tmp.set(iBf); tmp.set(pcm, iBf.length);
		iBf=ws.inputBuffers=tmp;
		if(iBf.length<sr/1000*500-99){
			return;
		}
		ws.isSendIB=true;
		pcm=iBf;
	}
	pcm=new Int16Array(pcm.buffer.slice(0));
	Recorder.BufferStreamPlayer.FadeInOut(pcm, sr);
	stream.input(pcm);
}
//打开播放器
,__openPlayer:function(sid,success,fail){
	var This=this;
	if(This.bsPlayer){
		success();
		return;
	}
	var stream=Recorder.BufferStreamPlayer({
		decode:false
		,realtime:{ maxDelay:2000,discardAll:true }
		,onInputError:function(errMsg, inputIndex){
			This.log("第"+inputIndex+"次的音频片段input输入出错: "+errMsg,3);
		}
		,onUpdateTime:function(){
			var state=1; //播放中
			if(stream.isStop) state=4; //已停止
			else if(stream.isPause) state=3; //已暂停
			else if(stream.isPlayEnd) state=2; //缓冲中
			This.set.onUpdateTime(stream.currentTime, state);
		}
		,transform:function(inputData,sampleRate,True,False){
			True(inputData, This.sampleRate);
		}
	});
	This.bsPlayer=stream;
	
	This.log("正在打开播放器...");
	stream.start(function(){
		if(This.SID!=sid) return;
		stream.__isStart=true;
		This.log("已打开播放器");
		success();
	},function(err){
		if(This.SID!=sid) return;
		This.log("打开播放器出错："+err,1);
		fail("打开播放器出错："+err);
	});
}


//打开websocket连接
,__openWs:function(sid,success,fail){
	var This=this,set=this.set,setD=set.device||{};
	var isEndCall=false;
	var endCall=function(err,obj){
		isEndCall=true;
		var fn1=success,fn2=fail; success=fail=null;
		if(err) fn2&&fn2(err); else fn1&&fn1(obj);
	};
	
	var ws=this.ws=new WebSocket(set.wsUrl);
	ws.binaryType="arraybuffer";
	ws.sendCalls={};//发送回调
	ws.sendNo=0;//发送的消息编号
	ws.aliveTime=Date.now();//收发了数据的最后时间
	ws.aliveSendTime=Date.now();//发送了数据的最后时间，长时间没给服务器发消息就发心跳
	ws.isReady=false;//连接是否已准备好
	ws.closeState=0; //关闭状态，0未关闭，1已正常关闭，2未正常关闭
	
	//关闭连接，清理资源
	ws.closeWs=function(){
		clearTimeout(ws._openTimer);
		clearInterval(ws.aliveInterval);
		clearInterval(ws.pcmCheckTimer);
		ws.isReady=false;
		if(ws.closeState==0){
			ws.closeState=1;
			ws.close();
		}
		if(!isEndCall){
			ws.closeState=1;
			endCall("服务器连接已断开");
		}
	};
	
	ws.pcmCheckTimer=setInterval(()=>{ //检测pcm数据是否按时回调了
		if(ws.isReady && Date.now()-ws.receivePcmTime>3000){
			if(This.SID!=sid) return;
			ws.closeState=2;
			ws.close();
			ws.closeMsg="无pcm数据";
			ws.closeWs();
		}
	},1000);
	ws._openTimer=setTimeout(() => {
		This.log("socket.open timeout",1);
		endCall("服务器连接超时");
		ws.closeWs();
	}, 5000);
	ws.onopen=function(e){
		clearTimeout(ws._openTimer);
		if(This.SID!=sid) return;
		
		//激活心跳
		ws.aliveInterval=setInterval(function(){
			if(This.SID!=sid) return;
			if(Date.now()-ws.aliveSendTime>20000){
				This.__sendMsg("alive",{}); //长时间没有发送消息，给服务器发一个消息
			}
		},5000);
		
		//连接设备
		var args={
			useShare:!!set.shareDevice, ip:setD.ip, port:setD.port
			,user:setD.user, pwd:setD.pwd, channel:setD.channel
		};
		This.log("socket.onopen 正在调用打开设备连接",args);
		This.__sendMsg("open",args,{},function(info){
			if(This.SID!=sid) return;
			This.log("调用打开设备连接成功",info);
			ws.isReady=true;
			ws.receivePcmTime=Date.now();
			endCall("",info);
		},function(err,timeout,serverErr){
			if(This.SID!=sid) return;
			This.log("调用打开设备连接失败",1,err,timeout,serverErr);
			endCall(err||"-");
			ws.closeWs();
		});
	};
	ws.onmessage=function(e){
		if(This.SID!=sid) return;
		ws.aliveTime=Date.now();
		try{
			This.receiveMsg(e.data);
		}catch(e){
			This.log("socket.onmessage",1,e);
		}
	};
	ws.onerror=function(e){
		if(ws.closeState==0){
			ws.closeState=2;
			This.log("socket.onerror",1,e);
			ws.onclose(e);
		}
	};
	ws.onclose=function(e){
		if(ws.closeState==0){
			ws.closeState=2;
			This.log("socket.onclose",1,e);
		}
		ws.closeWs(); //清理资源
		if(This.SID!=sid) return;
		
		if(isEndCall && ws.closeState==2){
			var msg=this.closeMsg||"掉线了";
			This.log("socket"+msg,1);
			set.onDisconnect(msg);
		}
	};
}
//发送消息
,sendMsg:function(action,data,set,True,False){
	var ws=this.ws;
	if(!ws || !ws.isReady){
		var tips="未连接服务器，不能发送消息";
		this.log(tips,1,action,data);
		False&&False(tips);
		return;
	}
	this.__sendMsg(action,data,set,True,False);
}
,__sendMsg:function(action,data,set,True,False){
	var ws=this.ws;
	if(!ws){
		False&&False("服务器连接已关闭",false,true);
		return;
	}
	set=set||{};
	var msgNo=++ws.sendNo;
	
	//需要回调结果
	if(True){
		False=False||function(){};
		var timer=setTimeout(function(){
			delete ws.sendCalls[msgNo];
			False("请求超时",true);
		},set.timeout||10000);
		ws.sendCalls[msgNo]={t:True,f:False,timer:timer};
	}
	
	var cmd=msgNo+"|"+action+"|"+JSON.stringify(data);
	
	try{
		if(set.binary){//换行拼接二进制内容
			var str=unescape(encodeURIComponent(cmd));
			var u8arr=new Uint8Array(str.length);
			for(var i=0;i<str.length;i++)u8arr[i]=str.charCodeAt(i);
			
			var arr=new Uint8Array(u8arr.length+1+set.binary.length);
			arr.set(u8arr);
			arr[u8arr.length]=10;
			arr.set(set.binary, u8arr.length+1);
			u8arr=arr;
			ws.send(u8arr.buffer);
		}else{
			ws.send(cmd+'\n');
		}
	}catch(e){
		var cb=ws.sendCalls[msgNo];
		if(cb){
			delete ws.sendCalls[msgNo];
			clearTimeout(cb.timer);
			cb.f("请求未发出",false,true);
		}
		return;
	}
	ws.aliveTime=Date.now();
	ws.aliveSendTime=Date.now();
}
//接收到服务器消息，解析处理
,receiveMsg:function(raw){
	var ws=this.ws;
	var tag="socket.onmessage ";
	var binary=null,rawTxt=raw;
	if (raw instanceof ArrayBuffer) {//二进制内容，提取第一行文本
		var u8arr=new Uint8Array(raw);
		var str="",bIdx=u8arr.length;
		for(var i=0;i<u8arr.length;i++){
			if(u8arr[i]==10){ bIdx=i+1; break }
			else str+=String.fromCharCode(u8arr[i]);
		}
		rawTxt=decodeURIComponent(escape(str));
		binary=new Uint8Array(u8arr.buffer.slice(bIdx));
	}
	
	if(/^(\d+)\|([^|]+)\|(.+)/.test(rawTxt)){
		var mid=RegExp.$1,action=RegExp.$2,str=RegExp.$3;
		var data=JSON.parse(str);
	}else{
		this.log(tag+"未知格式："+rawTxt);
		return;
	}
	
	if(/^response.(\d+)$/.test(action)){//响应回调
		var msgNo=+RegExp.$1;
		var cb=ws.sendCalls[msgNo];
		if(cb){
			delete ws.sendCalls[msgNo];
			clearTimeout(cb.timer);
			if(data.code!==0){
				cb.f(data.message||"-",false,data.code==500);
			}else{
				cb.t(data.value);
			}
		}
		return;
	}
	if(!ws.isReady){
		this.log(tag+"还未准备好，但收到了消息: "+rawTxt,3);
		return; //还未准备好，其他消息全丢弃
	}
	
	if(action=="voicePcm"){//服务器发来的语音内容
		if(!binary || !binary.length) return;
		ws.receivePcmTime=Date.now();
		//推入播放
		this.__playData(data,binary,u8arr);
		return;
	}
	
	if(action=="firstFrame"){//首帧信息，里面包含了采样率
		var str="";
		for(var i=0;i<binary.length;i++)if(binary[i]>9)str+=String.fromCharCode(binary[i]);
		this.log(tag+"获取到了首帧信息", str);
		return;
	}
	
	if(action=="keep"){
		return;
	}
	this.log(tag+"未知消息: "+rawTxt,3);
}


};

export default RTP2Voice;