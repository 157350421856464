<template>
  <div>
    <div class="x-tc-cnt flex flex-wrap">

      <div class="flex margin-top">
        <el-radio-group v-model="typeRadio" size="small" @change="typeRadioChange">
          <el-radio-button label="实时水位"></el-radio-button>
          <el-radio-button label="1小时平均水位"></el-radio-button>
          <el-radio-button label="24小时平均水位"></el-radio-button>
          <el-radio-button label="时间段查询"></el-radio-button>
          <el-radio-button label="按年查询"></el-radio-button>
        </el-radio-group>
      </div>

      <div class="margin-top flex">
        <img src="@/assets/YongZhenShuiKu/渗压监测效果.png" style="width: 1060px;height: 350px">
      </div>

      <div>
        <div class="x-table-title-bg margin-top x-tab-title-bg" style="width: 1060px;text-align: center">
          <div style="min-width: 224px;">断面名称</div>
          <div style="min-width: 224px">测点名称</div>
          <div style="min-width: 224px">测点水位高程(m)</div>
          <div style="min-width: 224px" v-if="showTime">更新时间</div>
          <div style="min-width: 224px" >阈值(m)</div>
        </div>

        <div style="height: 270px;overflow-y: auto;overflow-x: hidden;font-size: 14px" class="cursor">
          <div v-for="(item, index) in resData" ref="resDataListRef">
            <div class="x-table-title-list-bg" :class="index % 2 === 0 ? '' : 'x-tab-title-bg'"
              style="width: 1060px;text-align: center">
              <div style="min-width: 224px;">{{ item.stationname }}</div>
              <div style="min-width: 224px;">{{ item.name }}</div>
              <div style="min-width: 224px;">{{ item.elevation }}</div>
              <div style="min-width: 224px;" v-if="showTime">{{ item.TT }}</div>
              <div style="min-width: 224px;">{{ (item.elevation*1.3).toFixed(2) }}</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "x-real-time",
  props: ['deviceId'],
  data() {
    return {
      // 是否显示更新时间字段
      showTime: true,
      typeRadio: '实时水位',
      resData: [],
      // 水库水位高程(m)
      shuiKuShuiWeiGaoCheng: '',
    }
  },
  created() {
    this.initData();
  },
  mounted() {

  },
  destroyed() {
  },

  methods: {

    emitLoading(type) {
      this.$emit('on-loading', type)
    },


    /**
     * @Description: 获取渗流断面实时数据
     * @Author: LiYang
     * @Date:  2022/9/16  0:48
     */
    initData() {
      this.emitLoading(true);
      this.$request(
        {
          url: '/RequestRelay',
          method: 'post',
          headers: {
            isToken: true
          },
          data: {
            url: `${window.$baseUrl_hz_sk}/TjcUpbuMEk.php/irriinfo/stationinfo/seeprecord/ajaxNowdata?stationinfo_id=${this.deviceId}`,
            type: 'get',
          }
        }
      ).then((val) => {
        let data = JSON.parse(val.msg);
        this.resData = data.data;
        this.shuiKuShuiWeiGaoCheng = data.curElevation;
        this.loadingType = false;
        this.emitLoading(false);
      });
    },


    /**
     * @Description: 获取一段时间内的平均数据
     * @Author: LiYang
     * @Date:  2022/9/16  0:48
     */
    initTimeSlotData(val) {
      this.emitLoading(true);
      this.$request(
        {
          url: '/RequestRelay',
          method: 'post',
          headers: {
            isToken: true
          },
          data: {
            url: `${window.$baseUrl_hz_sk}/TjcUpbuMEk.php/irriinfo/stationinfo/seeprecord/ajaxbetweenavg?stationinfo_id=289&hours=${val}`,
            type: 'get',
          }
        }
      ).then((val) => {
        let data = JSON.parse(val.msg);
        this.resData = data.data;
        this.shuiKuShuiWeiGaoCheng = data.curElevation;
        this.loadingType = false;
        this.emitLoading(false);
      });
    },

    typeRadioChange(val) {
      switch (val) {
        case '实时水位':
          this.initData();
          break;
        case '1小时平均水位':
          this.initTimeSlotData(1);
          break;
        case '24小时平均水位':
          this.initTimeSlotData(24);
          break;
        case '时间段查询':
          this.initData();
          break;
        case '按年查询':
          this.initData();
          break;
      }
      console.log(11, val);
    },

  }
}
</script>

<style scoped>
.x-tc-cnt {
  width: 100%;
  height: 730px;
}
</style>
