import request from '@/utils/request'

// 查询湖泊基础信息列表
export function listLake(query) {
  return request({
    url: '/weather/lake/list',
    method: 'get',
    params: query
  })
}

// 查询湖泊基础信息详细
export function getLake(id) {
  return request({
    url: '/weather/lake/' + id,
    method: 'get'
  })
}

// 新增湖泊基础信息
export function addLake(data) {
  return request({
    url: '/weather/lake',
    method: 'post',
    data: data
  })
}

// 修改湖泊基础信息
export function updateLake(data) {
  return request({
    url: '/weather/lake',
    method: 'put',
    data: data
  })
}

// 删除湖泊基础信息
export function delLake(id) {
  return request({
    url: '/weather/lake/' + id,
    method: 'delete'
  })
}
