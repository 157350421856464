<template>
	<div class="niandudeyangshi">
		<span class="xuanzewenzi" style="margin-right: 0px;">查询年份：</span>
		<div class="rq" style="float: left;">
			<el-date-picker v-model="xuanzedenian" type="year" value-format="yyyy" placeholder="选择年" @change="zxn" class="niandudeyangshitwo">
			</el-date-picker>
		</div>
		<div class="chaxunanniu" @click="childMethod()"><span>查询</span></div>
		<div class="chaxunanniu" @click="childdaochu()"><span>导出</span></div>
	</div>

</template>

<script>
	export default {
		name: "index",
		components: {},
		props: {
			getlist: {
				type: Function,
				default: () => {
					return Function;
				},
			},
			getdaochu: {
				type: Function,
				default: () => {
					return Function;
				},
			},
		},
		data() {
			return {
				xuanzedenian: '',
			}
		},

		created() {},
		mounted() {
			this.dangqianshijian()
			this.childMethod()
		},
		beforeDestroy() {},
		methods: {
			
			childdaochu() {
				this.getdaochu();
				console.log('调用getdaochu父组件方法')
			},
			childMethod() {
				this.getlist();
				console.log('调用父组件方法')
			},
			dangqianshijian() {
				let now = new Date();
				let oneHourAgo = new Date(now.getTime());
				let year = oneHourAgo.getFullYear(); // 获取年份
				this.xuanzedenian = year
				this.xuanzedenian = String(this.xuanzedenian)
				this.$emit('change', this.xuanzedenian);
				this.childMethod()


			},
			zxn() {
				this.$emit('change', this.xuanzedenian);
			},




		}
	}
</script>
<style>
	.niandudeyangshitwo .el-input__icon {
		display: none;
	}

	.niandudeyangshi .el-select .el-input .el-select__caret {
		color: #ffffff;
		font-size: 16px;
		margin-top: 5px;
	}

	.niandudeyangshi .el-select>.el-input {
		display: block;
		width: 75px;
	}

	.niandudeyangshi .el-input__inner {
		background-color: #1a6e9f;
		color: #fff;
		border: 0px solid #fff;
	}

	.niandudeyangshi .el-input--prefix .el-input__inner {
		padding-left: 10px !important;
		height: 30px;
		margin-top: 4px;
	}

	.niandudeyangshi .el-input--suffix .el-input__inner {
		padding-right: 10px !important;
		height: 30px;
	}


	.niandudeyangshi .rq .el-date-editor.el-input,
	.niandudeyangshi .el-date-editor.el-input__inner {
		width: 120px !important;
	}
</style>
<style scoped>
	.el-button--primary {
		color: #FFF;
		background-color: #2191d1;
		border-color: #409EFF;
		width: 80px;
		margin-top: 3px;
		margin-left: 20px;
	}



	.sanshijianbj span {
		margin-left: 10px;
		color: #fff;
		margin-left: 10px;
		float: left;
		line-height: 32px;
		width: 41px;
		height: 32px;
		margin-top: 3px;
		font-size: 16px;
		text-align: center;
		border-radius: 10%;
	}

	.sanshijianbj span.active {
		background-color: #1a6e9f;
		margin-left: 10px;
		text-align: center;
		color: #fff;
		margin-left: 10px;
		float: left;
		line-height: 32px;
		width: 41px;
		height: 32px;
		margin-top: 3px;
		font-size: 16px;
		border-radius: 10%;
	}

	.el-button {
		padding: 9px 0px;
	}

	.el-button--primary {
		color: #FFF;
		background-color: #409EFF;
		border-color: #409EFF;
		width: 80px;
		margin-top: 3px;
		margin-left: 20px;
	}

	.el-button--primary:focus,
	.el-button--primary:hover {
		background: #66b1ff;
		border-color: #66b1ff;
		color: #FFF;
		width: 80px;
	}


	.sanxuan {
		color: #fff;
		margin-left: 10px;
		float: left;
		line-height: 32px;
		width: 41px;
		height: 32px;
		margin-top: 3px;
		font-size: 16px;
		/* background-color: #1a6e9f;	 */

	}

	.chaxunanniu {
		color: #fff;
		margin-left: 15px;
		float: left;
		line-height: 32px;
		width: 72px;
		height: 32px;
		margin-top: 3px;
		border-radius: 10%;
		font-size: 16px;
		background-color: #1a6e9f;

	}

	.chaxunanniu:hover {
		color: #fff;
		margin-left: 15px;
		float: left;
		line-height: 32px;
		width: 72px;
		height: 32px;
		margin-top: 3px;
		border-radius: 10%;
		font-size: 16px;
		background-color: #218fce;

	}

	.xuanzewenzi {
		color: #fff;
		margin-left: 10px;
		margin-right: 10px;
		float: left;
		font-size: 16px;
		line-height: 39px;
	}


	.niandudeyangshi {
		/* background-color: #104d7b; */
		/* width: 100%; */
		height: 50px;
		margin-top: 10px;
		margin-left: 0px;
		float: left;
		/* border: #fff solid 1px; */
	}
</style>