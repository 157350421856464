/**
* @Author liYang
* @Date 2022/9/16 1:10
* @Description: TODO
* @Version 1.0
*/
<template>
  <div>
    <div class="flex" style="height: 500px">

      <div class="flex" style="width: 400px">
        <div>
          <div class="text-left">
            <img src="@/assets/common/基础信息.png" style="width: 250px">
          </div>
          <div class="flex">
            <div>
              <img src="@/assets/YongZhenShuiKu/组 1430.png">
            </div>
            <div class="x-info-bg margin-left margin-top-sm">
              <div class="margin-left">
                <div class="x-info-title" style="position: relative;top: -15px;">
                  {{ operation_company[resData.operationMaintenanceCompany]  || '--'}}
                </div>
                <div class="flex text-sm text-left">
                  <div style="width: 120px">联系人：{{ resData.operationMaintenancePersonnel  || '--'}}</div>
                  <div class="flex-sub" style="white-space:nowrap">电话：{{ resData.operationMaintenancePhone  || '--'}}</div>
                </div>
                <div class="flex text-sm text-left margin-top-sm">
                  地址：{{ resData.position  || '--'}}
                </div>
              </div>
            </div>
          </div>

          <div class="text-left margin-top">

            <div class="flex align-center">
              <div class="x-xiaoDian"></div>
              <div class="x-kuandu">测站名称 :</div>
              <div class="margin-left">{{ resData.name  || '--'}}</div>
            </div>

            <div class="flex align-center">
              <div class="x-xiaoDian"></div>
              <div class="x-kuandu">稳定级别 :</div>
              <div class="margin-left text-green">A</div>
            </div>

            <div class="flex align-center">
              <div class="x-xiaoDian"></div>
              <div class="x-kuandu">维护次数 :</div>
              <div class="margin-left text-green">5</div>
            </div>

            <div class="flex align-center">
              <div class="x-xiaoDian"></div>
              <div class="x-kuandu">设备品牌 :</div>
              <div class="margin-left">{{ equipment_brand[resData.brand]  || '--'}}</div>
            </div>

            <div class="flex align-center">
              <div class="x-xiaoDian"></div>
              <div class="x-kuandu">设备型号 :</div>
              <div class="margin-left">{{ resData.equipmentModel  || '--'}}</div>
            </div>

            <div class="flex align-center">
              <div class="x-xiaoDian"></div>
              <div class="x-kuandu">设备类型 :</div>
              <div class="margin-left">{{ resData.type  || '--'}}</div>
            </div>

            <div class="flex align-center">
              <div class="x-xiaoDian"></div>
              <div class="x-kuandu">安装日期 :</div>
              <div class="margin-left">{{ resData.installDate || '--' }}</div>
            </div>

            <div class="flex align-center">
              <div class="x-xiaoDian"></div>
              <div class="x-kuandu">运维单位 :</div>
              <div class="margin-left"> {{ operation_company[resData.operationMaintenanceCompany] || '--' }}</div>
            </div>

            <div class="flex align-center">
              <div class="x-xiaoDian"></div>
              <div class="x-kuandu">运维联系人 :</div>
              <div class="margin-left">{{ resData.operationMaintenancePersonnel || '--'}}</div>
            </div>

            <div class="flex align-center">
              <div class="x-xiaoDian"></div>
              <div class="x-kuandu">联系方式 :</div>
              <div class="margin-left">{{ resData.operationMaintenancePhone || '--' }}</div>
            </div>

          </div>
        </div>
      </div>

      <div class="flex flex-sub text-left">
        <div>
          <img src="@/assets/common/测站图像.png" style="width: 250px">
          <div>
            <img src="@/assets/device/waterQuality.jpg" style="width: 650px;height: 650px;border-radius: 10px">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {getDictData} from "@/utils/dict";

export default {
  name: "x-details",
  props: {
    resData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      operation_company: {},
      equipment_brand: {},
      resData: {},
      ceZhanYingXiang: ''
    }
  },

  created() {
    getDictData("operation_company").then((val)=>{
      this.operation_company = val;
    });

    getDictData("equipment_brand").then((val)=>{
      this.equipment_brand = val;
    });
  },

  mounted() {
  },

  beforeDestroy() {

  },

  methods: {
  }
}
</script>

<style scoped>
.x-info-bg {
  width: 259px;
  height: 82px;
  background: url("~@/assets/YongZhenShuiKu/组 1429.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.x-info-title {
  min-width: 186px;
  height: 25px;
  font-size: 16px;
  font-style: italic;
  text-align: left;
  color: #D1D6DF;
  line-height: 32px;
  font-weight: bolder;
  background: linear-gradient(0deg, #ACDDFF 0%, #FFFFFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.x-xiaoDian {
  width: 31px;
  height: 31px;
  background: url("~@/assets/YongZhenShuiKu/小点.png") no-repeat;
  background-size: 100% 100%;
}

.x-kuandu {
  width: 95px;
  text-align-last: justify;

}
</style>
