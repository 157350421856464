<template>
	<div style="pointer-events: auto;z-index: 1000;color:#fff;">
		<x-dialog-comp :title="compData.deviceName+'实时监测'" @on-close="$emit('on-close')">
			<x-title-btn :menu-data.sync="titleMenuData"></x-title-btn>

			<!-- <template v-if="shangshuizhiShow">
				<img src="../../assets/ShengLiShuiKu/beijingjing.png"
					style="float: left;margin-top: -37px; margin-left: 189px;height: 35px;width: 153px;">
				<span
					style="float: left;margin-top: -37px; margin-left: 189px;line-height: 33px;font-size: 15px;height: 35px;width: 153px;"
					@click="handleClickbzpt(1)">智慧水利平台</span>
				</img>
			</template> -->

			<div v-if="titleMenuData[0].checkType" v-loading="!resDataFlag">
				<div class="x-table-title-bg x-tab-title-bg"
					style="width:98%;margin-left: 1%;text-align: center;font-size: 14px">
					<div style="flex:1;min-width:150px;"><strong>名称</strong></div>
					<div style="flex:1;min-width:150px;"><strong>数值</strong></div>
					<div style="flex:1;min-width:150px;"><strong>状态</strong></div>
					<div style="flex:1">阀值</div>

				</div>
				<div style="height: 680px;overflow-y: auto;overflow-x: hidden;font-size: 14px" class="cursor">
					<div v-for="(item,index) in resData" ref="resDataListRef">
						<div class="x-table-title-list-bg"
							style="width: 98%;margin-left: 1%;text-align: center;line-height:40px;height:40px;">
							<div style="flex:1">{{ item.name }}</div>
							<div style="flex:1" v-if="item.color==null">{{ item.value }}</div>
							<div style="flex:1;display: flex;justify-content: center;align-items: center;" v-else>
								<div class="dikuang" :style="{ 'background-color': item.color }">{{ item.value }}</div>
							</div>
							<!-- <div style="flex:1;
  display: flex;
  justify-content: center;
  align-items: center;" v-if="item.yunxingzhuangtai=='yunxing'">
								<div class="dikuang" style="background-color: rgb(0 195 0);">{{ item.value }}</div>
							</div>
							<div style="flex:1;
  display: flex;
  justify-content: center;
  align-items: center;" v-if="item.yunxingzhuangtai=='tingzhi'">
								<div class="dikuang" style="background-color: rgb(215 3 3);">{{ item.value }}</div>
						</div> -->
						<!-- <div style="flex:1">{{ item.yuzhi }}</div> -->
						<!-- <div style="flex:1" v-if="shangqingShow">{{ item.yuzhi }}</div> -->

						<!-- <div style="flex:1" v-if="shangqingShow">{{ item.yuzhi }}</div> -->

						<div style="flex:1">{{ item.status != null && item.status != '' ? item.status :
								'' }}
						</div>
						<template>
							<template v-if="item.yuzhiList != null && item.yuzhiList != ''">
								<div style="flex:1">
									{{ item.yuzhiList[0].symbol }}
									{{ item.yuzhiList[0].value }}{{item.unit}}
								</div>
							</template>
							<template v-else>
								<div style="flex:1"></div>
							</template>
						</template>


					</div>
				</div>
			</div>

	</div>

	<div v-if="titleMenuData[1].checkType">
		<shijianxuanze :beginTime="beginTime" :endTime="endTime" :getlist="getlist" @change="costPlannedAmountChange">
		</shijianxuanze>
		<div class="x-tc-cnt" style="top: 100px;" v-loading="!dataFlag">
			<div class="x-card" v-for="(item, index) in historyData" v-if="dataFlag" :key="index"
				@click="dianjiecharts(item,index)">
				<div class="x-sm-title-bg">{{ item.title }}</div>
				<HZEcharts class="x-card-cnt" width="250px" height="210px" :name="item.title"
					:chartData="{ xAxisData: item.dateList, seriesData: item.valueList }"></HZEcharts>
			</div>
		</div>

	</div>

	<fangdaecharts ref="dialog" v-if="fdec" :name="fdecname"
		:chartData="{ xAxisData: fdecdateList, seriesData: fdecvalueList }" @on-point="fdec = false">
	</fangdaecharts>

	</x-dialog-comp>
	<gongcheng ref="dialog" v-if="bzpt" :bzptId="bzptId" @on-point="bzpt = false">

	</gongcheng>

	</div>
</template>

<script>
	import XDialogComp from "@/components/x-dialog-comp";
	import HZEcharts from "@/components/hz-echarts/echarts";
	import fangdaecharts from "@/components/hz-echarts/fangdaecharts";
	import XTitleBtn from "@/components/x-title-btn";
	import gongcheng from "@/components/x-device-ShangQing/tanchuang/gongcheng";

	import shijianxuanze from "@/components/x-device-comp/shijianxuanze";
	export default {
		name: "x-device-ShangQing",
		props: ['compData', 'titleName'],

		components: {
			XTitleBtn,
			XDialogComp,
			HZEcharts,
			fangdaecharts,
			gongcheng,
			shijianxuanze,
		},

		data: () => {
			return {


				fdec: false,
				fdecname: "", //选中的id
				fdecvalueList: [],
				fdecdateList: [],
				dakaidexiabiao: -1,


				bzpt: false,
				bzptId: null, //选中的id
				resData: [],
				historyData: [], //历史
				shangqingShow: false,

				titleMenuData: [{
					checkType: true,
					name: '实时数据',
				}, {
					checkType: false,
					name: '历史数据',
				}],

				beginTime: "",
				endTime: '',
				dataFlag: false,
				resDataFlag: false,
				shangshuizhiShow: false,
				shuizhiShow: false,
			}
		},
		created() {
			//只有墒情、气象设备，显示遥感智慧水利平台弹窗
			if (this.compData.bindingType == "/iot/device/dictList?pageNum=1&pageSize=999999&categoryId=5" || this.compData
				.bindingType == "/iot/device/dictList?pageNum=1&pageSize=999999&categoryId=4") {
				this.shangshuizhiShow = true;
			}
			//水质显示5级阈值
			if (this.compData.bindingType == "/iot/device/dictList?pageNum=1&pageSize=999999&categoryId=8") {
				this.shuizhiShow = true;
			}
		},
		mounted() {
			this.getData();
		},
		beforeDestroy() {},
		methods: {
			async costPlannedAmountChange(value1, value2) {
				this.beginTime = value1
				this.endTime = value2
				console.log("beginTime" + this.beginTime)
				console.log("endTime" + this.endTime)
			},
			getlist() {
				this.dataFlag = false;
				this.$request({
					url: `${window.$baseUrl_hz_bz}/api/guanqu/historyRecordById?devId=${this.compData.bindingId}&categoryId=${this.compData.categoryId}&beginTime=${this.beginTime}&endTime=${this.endTime}`,
					type: 'get',
				}).then((val) => {
					this.historyData = [];
					this.dataFlag = true;

					for (let item of val.data) {
						if (item.title.indexOf(this.compData.deviceName) !== -1) {
							this.historyData.push(item);
						}
					}


					if (this.fdec == true) {
						this.fdecname = this.historyData[this.dakaidexiabiao].title;
						this.fdecvalueList = this.historyData[this.dakaidexiabiao].valueList;
						this.fdecdateList = this.historyData[this.dakaidexiabiao].dateList;
						console.log("执行方法", this.dakaidexiabiao)
					}
				});
			},

			dianjiecharts(data, index) {
				this.fdecname = data.title;
				this.fdecvalueList = data.valueList;
				this.fdecdateList = data.dateList;
				console.log(data)
				this.dakaidexiabiao = index
				console.log(this.dakaidexiabiao)
				this.fdec = true;
			},



			getData() {
				this.resDataFlag = false;
				console.log(this.compData)
				//墒情显示阀值
				// if (this.compData.serialNumber == "11658701333306") {
				// 	this.shangqingShow = true;
				// }


				this.$request({
					url: `${window.$baseUrl_hz_bz}/api/guanqu/lastRecordBySn?serialNumber=${this.compData.serialNumber}`,
					type: 'get',
				}).then((val) => {
					let data = []
					for (let item of val.data) {
						if (item.name.indexOf(this.compData.deviceName) !== -1) {
							data.push(item);
						}
					}
					this.resData = data;
					var yuanname = this.compData.deviceName
					if (yuanname.indexOf("温度")) {
						yuanname = yuanname.replace("温度", "");
					}
					const replacements = {
						[yuanname]: ''
					};
					for (let i = 0; i < this.resData.length; i++) {
						this.resData[i].name = this.resData[i].name.replace(new RegExp(yuanname, 'g'), function(
							matched) {
							return replacements[matched];
						});
					}
					console.log("运行状态运行状态运行状态运行状态运行状态运行状态运行状态运行状态运行状态")
					for (let i = 0; i < this.resData.length; i++) {
						if (this.resData[i].name == '运行状态' || this.resData[i].name == '故障状态') {
							if (this.resData[i].value == '运行' || this.resData[i].value == '正常' || this.resData[i]
								.value == '启动') {
								this.resData[i].yunxingzhuangtai = 'yunxing'
							} else {
								this.resData[i].yunxingzhuangtai = 'tingzhi'
							}
						} else {
							this.resData[i].yunxingzhuangtai = ''
						}
					}


					this.resDataFlag = true;
				});




				// this.$request({
				// 	url: `${window.$baseUrl_hz_bz}/api/guanqu/lastRecordById?devId=${this.compData.bindingId}`,
				// 	type: 'get',
				// }).then((val) => {
				// 	this.resData = val.data;
				// 	this.resDataFlag = true;
				// });
			},

			handleClickbzpt(data) {
				console.log("当前被点击的id" + data)
				this.bzptId = data;
				this.bzpt = true;
			},
			/**
			 * 关闭点击
			 */
			closeClick() {
				this.$emit('on-close')
			},

		}
	}
</script>

<style scoped>
	.x-tc-page-bg {
		background: url("~@/assets/common/tanChuang.png") no-repeat;
		background-size: 100% 100%;
		width: 1132px;
		height: 763px;
		position: fixed;
		top: 100px;
		left: 400px;
		z-index: 99999;
		margin-left: 55px;
	}

	.x-tc-page-twobg {
		background: url("~@/assets/common/tanChuang.png") no-repeat;
		background-size: 100% 100%;
		width: 1132px;
		height: 763px;
		position: fixed;
		top: 100px;
		left: 400px;
		z-index: 99999;
		margin-left: 55px;
	}

	.x-tc-title {
		font-size: 22px;
		position: absolute;
		left: 80px;
		top: 15px;
		color: white;
		letter-spacing: 5px;
	}

	.x-close {
		width: 50px;
		height: 50px;
		position: absolute;
		right: 30px;
		cursor: pointer;
	}

	.x-tc-cnt {
		height: 640px;
		width: 1050px;
		overflow: auto;
	}

	.x-card {
		width: 230px;
		height: 270px;
		margin-right: 33px;
		float: left;
	}

	.x-card:nth-child(4n) {
		margin-right: 0px;
	}

	.x-card-cnt {
		width: 250px;
		height: 230px;
	}

	.x-sm-title-bg {
		background: url("~@/assets/ZhiNengXiTong/title.png") no-repeat;
		background-size: 100% 100%;
		width: 220px;
		height: 35px;
		display: flex;
		align-items: center;
		padding-left: 20px;
		font-family: douyuFont-2;
		font-size: 14px;
	}

	.x-table-box {
		height: 680px;
		overflow-y: auto;
		overflow-x: hidden;
		font-size: 14px
	}

	.x-table-title-list-bg {
		width: 98%;
		margin-left: 1%;
		text-align: center;
		line-height: 40px;
		height: 40px;
	}

	.x-table-title-list-bg:nth-child(2n) {
		background: #ffffff10;
	}

	.x-table-title-list-bg:hover {
		background: #ffffff26;
	}

	.dikuang {

		float: left;
		margin-left: 0px;
		margin-top: 0px;
		width: 60px;
		height: 20px;
		border-radius: 10%;
		font-size: 10px;
		line-height: 21px;
		text-align: center;
		font-size: 13px;

	}
</style>