<template>
    <div class="x-page" >
        <div class="x-btn-close" @click="closeClick"></div>
        <div class="x-title">{{ resData[0].title}}</div>
        <div class="x-time">{{ nowDate }}</div>

        <div style="width: 85%;margin-left: 10%;position: absolute;top: 140px;text-align: left;line-height: 35px">
            <div class="flex">
                <div class="flex-sub">泵前水位：<span class="x-value">{{resData[2]["泵前水位"]}}</span></div>
                <div class="flex-sub">泵前水深：<span class="x-value">{{resData[3]["泵前水深"]}}</span></div>
            </div>

            <div class="flex">
                <div class="flex-sub">启泵水位：<span class="x-value">{{resData[4]["启泵水位"]}}</span></div>
                <div class="flex-sub">泥沙含量：<span class="x-value">{{resData[5]["泥沙含量"]}}</span></div>
            </div>

            <div class="flex">
                <div class="flex-sub">水温：<span class="x-value">{{resData[6]["水温"]}}</span></div>
                <div class="flex-sub"></div>
            </div>


<!--            <div class="flex">-->
<!--                <div class="flex-sub" style="text-align: center"><span class="">推动水利工程标准化管理,助力水利治理能力现代化</span></div>-->
<!--            </div>-->

        </div>
    </div>
</template>

<script>
export default {
    name: "x-ShuJuMianBan_BengQian",

    data() {
        return {
            resData: [],
            nowDate: '',
        }
    },

    created() {
    },

    mounted() {
        this.initData();

        setInterval(() => {
            this.timestampToTime();
        }, 1000);
    },

    methods: {
        initData(){
            // 广告牌
            this.$request({
                url: `${window.$baseUrl_hz_bz}/led/getVirtualLedShow`,
                type: 'get',
                params:{
                    virtualDeviceName:'路庄泵站泵前看板'
                }
            }).then((val) => {
                let data = val.rows
                this.resData = data;
                // window.ue.web.change_yongzhen_led(
                //     data[0].title,
                //     data[1].time,
                //     `实时水位:${data[2]["实时水位"]}`, `可纳降雨:${data[2]["可纳降雨"]}`,
                //     `设计水位:${data[3]["设计水位"]}`, `汛限水位:${data[3]["汛限水位"]}`,
                //     `可充库容:${data[4]["可充库容"]}`, `实时库容:${data[4]["实时库容"]}`,
                //     `可用库容:${data[5]["可用库容"]}`, `可用天数:${data[5]["可用天数"]}`,
                //     `实时水温:${data[6]["实时水温"]}`, `实时水质:${data[6]["实时水质"]}`,
                //     `24h雨量:${data[7]["24h雨量"]}`, `空气温度:${data[7]["空气温度"]}`,
                //     `出库流量:${data[8]["出库流量"]}`, `入库流量:${data[8]["入库流量"]}`,
                //     `位移监测:${data[9]["位移监测"]}`, `渗压监测:${data[9]["渗压监测"]}`,
                //     data[10].tail,
                // );
            });
        },

        timestampToTime() {
          let date = new Date();//时间戳为10位需*1000，时间戳为13位的话不需乘1000
          let Y = date.getFullYear() + '-';
          let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1):date.getMonth()+1) + '-';
          let D = (date.getDate()< 10 ? '0'+date.getDate():date.getDate())+ ' ';
          let h = (date.getHours() < 10 ? '0'+date.getHours():date.getHours())+ ':';
          let m = (date.getMinutes() < 10 ? '0'+date.getMinutes():date.getMinutes()) + ':';
          let s = date.getSeconds() < 10 ? '0'+date.getSeconds():date.getSeconds();
          this.nowDate = Y + M + D + h + m + s;
      },
        closeClick() {
            this.$emit('on-close')
        }
    }
};
</script>

<style scoped>
.x-page{
    background: url("~@/assets/YongZhenShuiKu/组件03.png") no-repeat;
    background-size: 100% 100%;
    width: 650px;
    height: 500px;
    color: white;
    font-family: 'Source Han Sans CN';
    position: fixed;
    top: 100px;
    left: calc(50% - 325px);
    z-index: 99999;
    margin-left: 55px;
}

.x-title{
    position: absolute;
    top: 35px;
    font-size: 22px;
    width: 100%;
    letter-spacing: 2px;
}

.x-time{
    position: absolute;
    top: 100px;
    font-size: 18px;
    width: 100%;
    color: #08f0ff;
}

.x-value{
    color: #2df280;
}

.x-btn-close {
    width: 50px;
    height: 50px;
    position: absolute;
    right: 27px;
    top: 30px;
    z-index: 9999;
}

</style>
