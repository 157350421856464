<template>
    <div style="z-index: 999999">
<!--        老龙桥节制闸闸前的渠道信息-->
        <x-lao-long-qiao-zha-qian v-if="menuType === '0'" @on-close="closeClick"></x-lao-long-qiao-zha-qian>
        <!--        老龙桥节制闸闸前看板-->
        <x-lao-long-qiao-zha-qian-kan-ban v-if="menuType === '1'" @on-close="closeClick"></x-lao-long-qiao-zha-qian-kan-ban>
        <!--        胜利水库入库泵房泵前看板-->
        <x-sheng-li-shui-ku-beng-qian-kan-ban v-if="menuType === '2'" @on-close="closeClick"></x-sheng-li-shui-ku-beng-qian-kan-ban>
        <!--        胜利水库入库泵房泵前-->
        <x-sheng-li-shui-ku-beng-qian v-if="menuType === '3'" @on-close="closeClick"></x-sheng-li-shui-ku-beng-qian>
    </div>
</template>

<script>
import XLaoLongQiaoZhaQian from "@/views/luzhuangguanqu_home/components/x-popup-comp/x-LaoLongQiaoZhaQian.vue";
import XLaoLongQiaoZhaQianKanBan
    from "@/views/luzhuangguanqu_home/components/x-popup-comp/x-LaoLongQiaoZhaQianKanBan.vue";
import XShengLiShuiKuBengQianKanBan
    from "@/views/luzhuangguanqu_home/components/x-popup-comp/x-ShengLiShuiKuBengQianKanBan.vue";
import XShengLiShuiKuBengQian from "@/views/luzhuangguanqu_home/components/x-popup-comp/x-ShengLiShuiKuBengQian.vue";

export default {
    name: "x-popup-comp",
    components: {XShengLiShuiKuBengQian, XShengLiShuiKuBengQianKanBan, XLaoLongQiaoZhaQianKanBan, XLaoLongQiaoZhaQian},
    props: ['menuId'],
    data() {
        return {
            menuType: '',
        }
    },

    created() {
        this.menuType = this.menuId;
        console.log(  this.menuType)
    },

    mounted() {
    },

    methods: {
        closeClick(){
            this.$emit('on-close')
        }
    }
};
</script>

<style>

</style>
