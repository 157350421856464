import request from '@/utils/request'

// 查询水闸基础信息列表
export function listSluice(query) {
  return request({
    url: '/water/sluice/list',
    method: 'get',
    params: query
  })
}

// 查询水闸基础信息详细
export function getSluice(id) {
  return request({
    url: '/water/sluice/' + id,
    method: 'get'
  })
}

// 新增水闸基础信息
export function addSluice(data) {
  return request({
    url: '/water/sluice',
    method: 'post',
    data: data
  })
}

// 修改水闸基础信息
export function updateSluice(data) {
  return request({
    url: '/water/sluice',
    method: 'put',
    data: data
  })
}

// 删除水闸基础信息
export function delSluice(id) {
  return request({
    url: '/water/sluice/' + id,
    method: 'delete'
  })
}

// 查询具体水闸阀门列表
export function SluiceWater(query) {
  return request({
    url: '/water/sluice/getSluiceDeviceDataBySluiceId',
    method: 'get',
    params: query
  })
}

// 查询具体水闸阀门列表
export function SluiceDetails(query) {
  return request({
    url: '/water/sluice/getSluiceMontiorByDeviceId',
    method: 'get',
    params: query
  })
}
// 查询具体水闸阀门列表
export function RealTimeSluiceData(query) {
  return request({
    url: '/water/sluice/getRealTimeSluiceData',
    method: 'get',
    params: query
  })
}


// 闸口远程控制
export function SluiceControl(query) {
  return request({
    url: '/water/sluice/remoteControlSluice',
    method: 'get',
    params: query
  })
}

// 云台控制
export function SluiceControlPan(query) {
  return request({
    url: '/api/haikcamera/panTiltControl',
    method: 'get',
    params: query
  })
}
// 云台控制
export function SluiceControlPantwo(query) {
  return request({
    url: '/api/haikcamera/ptzsControlling',
    method: 'get',
    params: query
  })
}

// 历史弹窗数据
export function SluiceHistoryData(query) {
  return request({
    url: '/water/sluice/getSluiceHistoryData',
    method: 'get',
    params: query
  })
}

// 操作记录弹窗数据
export function SluiceRecordsList(query) {
  return request({
    url: '/base/records/list',
    method: 'get',
    params: query
  })
}
