<template>
  <div>
    <div className="x-tc-cnt flex flex-wrap">
      <div style="position: absolute;right: 30px">
        <span> 当前流量：<span className="text-green-2">2 m³/s</span></span>
      </div>
      <div style="width: 100%;height: 500px;margin-left: 20px" ref="LiuLiangRef2"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "x-real-time",
  props: ['deviceId'],
  data() {
    return {}
  },
  created() {

  },
  mounted() {
    this.initLiuLiangRef();
  },
  destroyed() {
  },

  methods: {
    // 初始化流量Ref
    initLiuLiangRef() {
      let myChart = this.$echarts.init(this.$refs.LiuLiangRef2, null);
      let xLabel = this.$xUtil.getDate7();
      let L0 = ['0', '0.3', '0.22', '0.85', '0.50', '0.40', '0.40'];
      let L1 = ['0.50', '0.30', '0.72', '0.35', '0.80', '0.60', '0.50'];
      let option = {
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          top: '70px',
          left: '80px',
          right: '40px',
          bottom: '15%',
          // containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLine: {
              //坐标轴轴线相关设置。数学上的x轴
              show: true,
              lineStyle: {
                color: '#233653',
              },
            },
            axisLabel: {
              //坐标轴刻度标签的相关设置
              textStyle: {
                color: "#ffffff",
                padding: 10,
                fontSize: 12,
              },
              formatter: function (data) {
                return data;
              },
            },
            axisTick: {
              show: false,
            },
            data: xLabel,
          },
        ],
        yAxis: [
          {
            nameTextStyle: {
              color: '#7ec7ff',
              fontSize: 12,
              padding: 10,
            },
            min: 0,
            splitLine: {
              show: true,
              lineStyle: {
                color: '#2b528a',
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#233653',
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#ffffff",
                padding: 10,
              },
              formatter: function (value) {
                if (value === 0) {
                  return value;
                }
                return value;
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: 'L1',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 0,
            smooth: true,
            lineStyle: {
              normal: {
                width: 2,
                color: 'rgba(77,192,36,1)', // 线条颜色
              },
            },
            itemStyle: {
              color: 'rgba(77,192,36,1)',
            },
            tooltip: {
              show: true,
            },
            areaStyle: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new this.$echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: 'rgba(77,192,36,.3)',
                      },
                      {
                        offset: 1,
                        color: 'rgba(77,192,36, 0)',
                      },
                    ],
                    false
                ),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
            data: L1,
          }
        ],
      };
      myChart.setOption(option)
    },

  }
}
</script>

<style scoped>
.x-tc-cnt {
  width: 100%;
  height: 730px;
}

.x-xiaoDian {
  width: 31px;
  height: 31px;
  background: url("~@/assets/YongZhenShuiKu/小点.png") no-repeat;
  background-size: 100% 100%;
}
</style>
