/**
* @Author liYang
* @Date 2022/10/2 22:43
* @Description: TODO
* @Version 1.0
*/
<template>
	<div>
		<div style="position: relative;left: 25px">
			<div class="flex">
				<div>
					<div class="x-aq-bg">
						<img src="@/assets/common/anquan.png" style="position: absolute;left: 20px;top: 15px;">
						<div class="margin-left-xl">
							<div>综合安全状态</div>
							<div class="douyuFont text-lg">A级</div>
						</div>
					</div>

					<div class="x-aq-bg" @click="handleClickbjsj(2, 0)">
						<img src="@/assets/common/报警.png" style="position: absolute;left: 20px;top: 15px;">
						<div class="margin-left-xl">
							<div>报警数量</div>
							<div class="douyuFont text-lg">{{ this.gaojingshu.baojing }}</div>
						</div>
					</div>

					<div class="x-aq-bg" @click="handleClickbjsj(3, 0)">
						<img src="@/assets/common/警示.png" style="position: absolute;left: 20px;top: 15px;">
						<div class="margin-left-xl">
							<div>预警数量</div>
							<div class="douyuFont text-lg">{{ this.gaojingshu.yvjing }}</div>
						</div>
					</div>
				</div>

				<div style="position: relative">
					<div class="w-96">
						<video class="x-ab-sm-video" src="@/assets/video/扫描.webm" muted autoplay loop
							ref="videoTreeRef"></video>
						<video class="x-ab-sm-video" src="@/assets/video/盾牌.webm" muted autoplay loop
							ref="videoTreeRef"></video>
					</div>

					<div class="x-aq-jb-bg">
						A级
					</div>
				</div>
			</div>

		</div>
		<baojingshijian ref="dialog" v-if="bjsj" :bjsjId="bjsjId" :shebeiId="shebeiId" :chuanedshuikuId="chuanedshuikuId"
			 @on-point="bjsj = false">
		</baojingshijian>

		<!--    <x-bao-jing-shu-liang></x-bao-jing-shu-liang>-->
	</div>
</template>

<script>
import {
	gaojingshuliang
} from "@/api/shuiku/gaojingshuliang";
import XBaoJingShuLiang from "@/components/x-AnQuanFenXi/components/x-baoJingShuLiang";
import baojingshijian from "@/views/ShuNao/sqcbtanchuang/baojingshijian";
export default {
	name: "x-AnQuanFenXi",
	components: {
		baojingshijian,
		XBaoJingShuLiang
	},
	props: {
		associatedSluiceId: {
			type: Number,
			default: undefined
		},
		associatedRiverId: {
			type: Number,
			default: undefined
		},
		associatedPumphouseId: {
			type: Number,
			default: undefined
		},
		associatedIrrigatedAreaId: {
			type: Number,
			default: undefined
		},
		associatedReservoirId: {
			type: Number,
			default: undefined
		},
		associatedChannelId: {
			type: Number,
			default: undefined
		},
		deviceId: {
			type: Number,
			default: undefined
		},
		// chaxuntiaojian: {
		// 	type: Object,
		// 	default: undefined
		// },
	},
	// props: ['chaxuntiaojian'],
	data() {
		return {
			chuanedshuikuId:this.associatedReservoirId,
			shebeiId: {},
			bjsj: false,
			bjsjId: null, //选中的id
			gaojingshu: {
				baojing:0,
				yvjing:0,
			},
			chaxuntiaojian: {
				associatedSluiceId: this.associatedSluiceId,
				associatedRiverId: this.associatedRiverId,
				associatedPumphouseId: this.associatedPumphouseId,
				associatedIrrigatedAreaId: this.associatedIrrigatedAreaId,
				associatedReservoirId: this.associatedReservoirId,
				associatedChannelId: this.associatedChannelId,
				deviceId: this.deviceId,
			}
		}
	},

	created() { },

	mounted() {
		this.getgaojingshuliang()
	},

	beforeDestroy() {

	},

	methods: {
		handleClickbjsj(data) {
			console.log("当前被点击的id" + data)
			this.bjsjId = data;
			this.bjsj = true;
		},
		getgaojingshuliang() {
			try{
				gaojingshuliang(this.chaxuntiaojian).then(response => {
					this.gaojingshu = response.data;
				})
			}catch(e){
				//TODO handle the exception
			}
		},
	}
}
</script>

<style scoped>
.x-aq-bg {
	width: 217px;
	height: 79px;
	line-height: 35px;
	background: url("~@/assets/common/安全研制.png") no-repeat;
	background-size: 100% 100%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.x-aq-jb-bg {
	width: 92px;
	height: 39px;
	background: url("~@/assets/common/安全级别.png") no-repeat;
	background-size: 100% 100%;
	position: absolute;
	bottom: 10px;
	left: 75px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.x-ab-sm-video {
	width: 340px;
	height: 340px;
	position: absolute;
	left: -47px;
	mix-blend-mode: revert;
	top: -69px;
}
</style>
