<template>
	<el-dialog :visible.sync="bzpt" width="0px" append-to-body class="el-dialog-bzpt" :before-close="handleClickbzpt">

		<div>
			<div class="x-tc-page-twobg">
				<!-- 	<div class="x-tc-title douyuFont">
					白泽智慧水利平台
				</div> -->
				<img src="../../../assets/ShengLiShuiKu/关闭.png"
					style="width: 54px;height: 44px;float: left;margin-left: 1896px;margin-top: 26px;position: absolute;z-index: 9999;"
					@click="closeClick" />
				<!-- <div class="x-close " @click="closeClick"></div> -->
				<div class="x-tc-cnt">
					<iframe src="https://product.datall.cn/service/sdk/nolog/sdsksyg/" class="pdf_iframe"></iframe>
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		name: "index",
		components: {},
		props: {
			bzptId: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				// 弹出层标题
				bzpt: true,
				yujing: this.bzptId,



			}
		},

		created() {

		},

		mounted() {
			console.log("+++++" + this.yujing)
		},
		beforeDestroy() {

		},
		created() {},
		methods: {

			handleClickbzpt() {
				this.$emit('on-point')
			},

			closeClick() {
				this.$emit('on-point')
			},
			yujingInfo: function(id) {
				this.yujing = id;
				console.log("+++点击的id++" + this.yujing)
			},


		}
	}
</script>
<style scoped>
	.x-bzpt-cnt-bg-2 {
		background: url("~@/assets/ShuNao/18.png") no-repeat;
		background-size: 100% 100%;
		width: 100px;
		margin-left: 32px;
		margin-top: 15px;
		height: 100px;
		mix-blend-mode: screen;
		position: relative;
		letter-spacing: 3px;
		float: left;
	}

	.x-sh-value {
		position: absolute;
		bottom: 55px;
		width: 100%;
		text-align: center;
		float: left;
	}

	/* .x-sh-title {
		position: absolute;
		bottom: -35px;
		width: 100%;
		text-align: center;
		font-size: 14px;
		white-space: nowrap;
	} */
</style>


<style scoped>
	.x-tc-page-bg {
		background: url("~@/assets/common/tanChuang.png") no-repeat;
		background-size: 100% 100%;
		width: 1132px;
		height: 813px;
		position: fixed;
		top: 100px;
		left: 400px;
		z-index: 99999;
	}

	.x-tc-page-twobg {
		background-size: 100% 100%;
		width: 1984px;
		height: 929px;
		position: fixed;
		top: 0px;
		left: -20px;
		z-index: 999999;
	}

	.x-tc-title {
		font-size: 22px;
		position: absolute;
		left: 80px;
		top: 15px;
		color: white;
		letter-spacing: 5px;
	}

	.x-close {

		width: 50px;
		height: 50px;
		position: absolute;
		right: 30px;
		cursor: pointer;
	}


	.x-tc-cnt {
		width: 1920px;
		height: 100vh;
		position: absolute;
		left: 20px;
		top: 0px;
		overflow: hidden;
	}

	.pdf_iframe {
		width: 100%;
		height: 100%;
	}
</style>