<template>
	<div style="pointer-events: auto;color:#fff">
		<x-dialog-comp :title="videoSrc.name" @on-close="$emit('on-close')">
			<x-title-btn :menu-data.sync="titleMenuData"></x-title-btn>
			<template v-if="yingyanShowBt">
				<img src="../../assets/ShengLiShuiKu/beijingjing.png"
					style="float: left;margin-top: -37px; margin-left: 189px;height: 35px;width: 153px;">
				<span
					style="float: left;margin-top: -37px; margin-left: 189px;line-height: 33px;font-size: 15px;height: 35px;width: 153px;"
					@click="openClickYingYan()">打开鹰眼</span>
				</img>
			</template>

			<jiankongbofang v-show="titleMenuData[0].checkType" :ID="videoSrc.bindingId" :yuntaikongzhi="2" :duijiangkongzhi="2"
				style="width: 100%;height: 100%;">
			</jiankongbofang>
			<!-- <video ref="videoRef" v-show="titleMenuData[0].checkType" style="width: 100%;height: 100%;object-fit: fill"></video> -->

			<div v-if="titleMenuData[1].checkType">
				<shijianxuanze :beginTime="beginTime" :endTime="endTime" :getlist="getquanbulist"
					@change="costPlannedAmountChange">
				</shijianxuanze>
				<div style="top: 100px; width: 96%;margin-left: 2%;">
					<div class="x-table-title-bg x-tab-title-bg" style="width:100%;text-align: center;font-size: 14px">
						<div style="width: 15%;"><strong>时间</strong></div>
						<div style="width: 58%;"><strong>事件详情</strong></div>
						<!-- <div style="flex:1"><strong>事件源</strong></div> -->
						<div style="width: 15%;"><strong>事件类型</strong></div>
						<div style="width: 10%;"><strong>事件等级</strong></div>
					</div>
					<div style="height: 560px;overflow-y: auto;overflow-x: hidden;font-size: 14px" class="cursor">
						<div v-for="(item, index) in getneirongList" ref="resDataListRef" :key="index">
							<!-- <div class="x-table-title-list-bg" @click="openShow(item)"> -->
							<div class="x-table-title-list-bg"
								@click="handleClickcktp(item.alertId,item.message,item.fileUrl,item.alertType,index,item)">
								<div style="width: 15%;">{{ item.happenTime }}</div>
								<div style="width: 60%;
								  white-space: nowrap;
								  text-overflow: ellipsis;
								  overflow: hidden;
								  display: inline-block;
								">{{ item.message }}</div>
								<!-- <div>{{ item.resName }}</div> -->
								<div style="width: 15%;">{{ item.alertName }}</div>
								<div style="width: 10%;color: #ff0000;" v-if="item.eventLevel==1">1级</div>
								<div style="width: 10%;color: #ff7600;" v-if="item.eventLevel==2">2级</div>
								<div style="width: 10%;color: #ffff00;" v-if="item.eventLevel==3">3级</div>
							</div>
						</div>
					</div>
					<el-pagination background layout="prev, pager, next" :total="total" :current-page.sync="currentPage"
						@current-change="getquanbulist">
					</el-pagination>
				</div>

			</div>

			<eventDetails v-if="open" :info="eventInfo" @on-close="open = false"></eventDetails>

			<yingyan v-if="yingyanShow" @on-point="yingyanShow = false"></yingyan>

		</x-dialog-comp>

		<chakantupian ref="dialog" v-if="cktp" :cktpId="cktpId" :cktpwen="cktpwen" :cktpurl="cktpurl" :leixing="leixing"
			:zongitem="zongitem" @on-point="cktp=false">
		</chakantupian>
	</div>
</template>

<script>
	import XDialogComp from "@/components/x-dialog-comp";
	import XDialogCompSmall from "@/components/x-dialog-comp-small";
	import XTitleBtn from "@/components/x-title-btn";
	import jiankongbofang from "@/components/x-video/jiankongbofang";
	import eventDetails from "./event-details";
	import yingyan from "./yingyan";
	import shijianxuanze from "@/components/x-device-comp/shijianxuanze";
	import {
		gaojingshijianlist
	} from "@/api/zongheyutu/gaojingshijianlist";
	import chakantupian from "@/views/ShuNao/sqcbtanchuang/chakantupian";
	export default {
		name: "x-video",
		components: {
			XDialogComp,
			XTitleBtn,
			XDialogCompSmall,
			eventDetails,
			jiankongbofang,
			shijianxuanze,
			yingyan,
			chakantupian,
		},

		props: ['videoSrc'],
		data: () => {
			return {

				cktp: false,
				cktpId: null,
				cktpurl: null,
				cktpwen: null,
				leixing: null,

				yuntaikongzhi: 2,
				name: "",
				titleMenuData: [{
					checkType: true,
					name: '实时监控',
				}, {
					checkType: false,
					name: 'AI视频事件',
				}],
				getneirongList: [],
				// 总条数
				total: 0,
				// 查询参数
				queryParams: {
					pageNum: 1,
					pageSize: 10,
				},
				open: false,
				headList: [{
						id: 1,
						time: '',
						imgUrl: "~@/assets/ZhiNengXiTong/img.png"
					},
					{
						id: 2,
						time: '',
						imgUrl: "~@/assets/ZhiNengXiTong/img_1.png"
					}
				],
				eventInfo: {},
				his: null,
				jishi: 0,
				daojishi: 90,
				dizhiurl: "",

				beginTime: "",
				endTime: '',
				yingyanShowBt: false, //是否显示鹰眼
				yingyanShow: false, //是否显示鹰眼
				currentPage: 1,
			}
		},
		created() {},

		mounted() {
			if (this.videoSrc.bindingId == 343) {
				this.yingyanShowBt = true;
			}
		},

		beforeDestroy() {
			this.videoShow = false;
		},
		destroyed() {
			this.videoShow = false;
		},
		methods: {

			handleClickcktp(data, wen, url, lei, index, item) {
				console.log("当前被点击的id" + data)
				this.cktpId = data;
				this.cktpId = Number(this.cktpId)
				this.cktpwen = wen;
				this.cktpurl = url;
				this.leixing = lei;
				this.leixing = Number(this.leixing);
				this.zongitem = item;
				this.cktp = true;
				// this.getyidu(this.cktpId, index)
			},
			async costPlannedAmountChange(value1, value2) {
				this.beginTime = value1
				this.endTime = value2
				// console.log("beginTime" + this.beginTime)
				// console.log("endTime" + this.endTime)
			},
			/**
			 * 关闭点击
			 */
			closeClick() {
				this.$emit('on-close')
				this.guanbi()
			},
			getquanbulist() {
				gaojingshijianlist({
					deviceId: this.videoSrc.bindingId,
					beginTime: this.beginTime,
					endTime: this.endTime,
					pageNum: this.currentPage,
					pageSize: 10,
				}).then(response => {
					if (response.code == 200) {
						this.getList = response.rows;
						this.getneirongList = response.rows;
						this.total = response.total;
					}
				})
			},
			openShow(item) {
				this.eventInfo = item;
				this.open = !this.open;
			},
			openClickYingYan() {
				this.yingyanShow = true
			}

		}
	}
</script>

<style scoped>
	.anniu {
		width: 120px;
		height: 120px;
		z-index: 999;
		position: absolute;
		margin-top: -40%;
		margin-left: 24px;
	}

	.x-tc-page-bg {
		background: url("~@/assets/common/tanChuang.png") no-repeat;
		background-size: 100% 100%;
		width: 1132px;
		height: 763px;
		position: fixed;
		top: 100px;
		left: 400px;
		z-index: 99999;
		margin-left: 55px;
	}

	.x-tc-title {
		font-size: 22px;
		position: absolute;
		left: 80px;
		top: 15px;
		color: white;
		letter-spacing: 5px;
	}

	.x-close {
		width: 50px;
		height: 50px;
		position: absolute;
		right: 30px;
		cursor: pointer;
	}

	.x-tc-cnt {
		width: 1050px;
		/* margin-left: 25px; */
		height: 700px;
		overflow: auto;
		position: absolute;
		top: 58px;
		overflow: hidden;
		background-color: white;
	}

	.x-table-title-list-bg {
		width: 98%;
		margin-left: 1%;
		text-align: center;
		line-height: 40px;
		height: 40px;
		margin-top: 5px;
	}

	.x-table-title-list-bg div {
		/* flex: 1; */
	}

	.x-table-title-list-bg:hover {
		background: #032e5a;
	}
</style>