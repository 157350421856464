<template>
	<el-dialog :visible.sync="bzpt" width="0px" append-to-body class="el-dialog-bzpt" :before-close="handleClickbzpt">

		<div>

			<div class="x-tc-page-twobg">
				<div class="TanchuangHeader">
					<div class="center">
						<h6>
							<span>
								<img src="@/assets/HeChangShuMei/img/tanchuang/组7拷贝.png">
								十一村节制闸鹰眼
								<img src="@/assets/HeChangShuMei/img/tanchuang/组7拷贝2.png">
							</span>
						</h6>
					</div>
					<img class="close" src="@/assets/HeChangShuMei/img/tanchuang/关闭.png" @click="closeClick">
				</div>
				<div class="x-tc-cnt" v-loading="true">
					<!-- 鹰眼 -->
					<iframe src="/yingyan/" class="pdf_iframe"></iframe>
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		name: "yingyan",
		components: {},
		props: {
			bzptId: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				// 弹出层标题
				bzpt: true,
			}
		},

		created() {

		},

		mounted() {},
		beforeDestroy() {

		},
		created() {},
		methods: {

			handleClickbzpt() {
				this.$emit('on-point')
			},
			closeClick() {
				this.$emit('on-point')
			},
		}
	}
</script>
<style lang="scss" scoped>
	@font-face {
		font-family: '蒙纳简超刚黑';
		src: url('~@/assets/HeChangShuMei/font/蒙纳简超刚黑.ttf');
		font-weight: normal;
		font-style: normal;
	}

	.TanchuangHeader {
		width: 100%;
		height: 45px;
		line-height: 45px;
		clear: both;
		position: relative;
		background: #051e3b;

		.center {
			width: 100%;
			text-align: center;

			h6 {
				padding: 0 0px;
				font-size: 24px;
				color: #FFF;
				font-family: '蒙纳简超刚黑';
				position: relative;
				height: 37px;
				margin: 0;
				text-align: center;

				span {
					z-index: 2;
					background-image: -webkit-linear-gradient(bottom, #4bb1e6, #f4fbff, white);
					background-clip: text;
					color: transparent;
					font-family: '蒙纳简超刚黑';
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					text-align: center;
				}
			}

			img {
				vertical-align: middle;
			}
		}

		.close {
			position: absolute;
			top: 0;
			right: 0;
			width: 16px;
			height: 16px;
			padding: 14px 14px 15px 10px;
			box-sizing: content-box;
		}
	}

	.x-bzpt-cnt-bg-2 {
		background: url("~@/assets/ShuNao/18.png") no-repeat;
		background-size: 100% 100%;
		width: 100px;
		margin-left: 32px;
		margin-top: 15px;
		height: 100px;
		mix-blend-mode: screen;
		position: relative;
		letter-spacing: 3px;
		float: left;
	}

	.x-sh-value {
		position: absolute;
		bottom: 55px;
		width: 100%;
		text-align: center;
		float: left;
	}

	/* .x-sh-title {
		position: absolute;
		bottom: -35px;
		width: 100%;
		text-align: center;
		font-size: 14px;
		white-space: nowrap;
	} */
</style>


<style scoped>
	.x-tc-page-bg {
		background: url("~@/assets/common/tanChuang.png") no-repeat;
		background-size: 100% 100%;
		width: 1132px;
		height: 813px;
		position: fixed;
		top: 100px;
		left: 400px;
		z-index: 99999;
	}

	.x-tc-page-twobg {
		background-size: 100% 100%;
		width: 100%;
		height: 100vh;
		position: fixed;
		top: 0px;
		left: 0px;
		z-index: 99999;
	}

	.x-tc-title {
		font-size: 22px;
		position: absolute;
		left: 80px;
		top: 15px;
		color: white;
		letter-spacing: 5px;
	}

	.x-close {

		width: 50px;
		height: 50px;
		position: absolute;
		right: 30px;
		cursor: pointer;
	}


	.x-tc-cnt {
		width: 100%;
		height: calc( 100vh - 45px );
		position: absolute;
		left: 0px;
		top: 45px;
		overflow: hidden;
	}

	.pdf_iframe {
		width: 100%;
		height: calc( 100vh - 45px );;
	}
</style>