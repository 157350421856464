<template>
    <div class="x-page">
        <div class="x-btn-close" @click="closeClick"></div>
        <div class="x-title">{{resData[0].title}}</div>
        <div class="x-time" style="top: 100px">{{ nowDate }}</div>

        <div style="width: 90%;margin-left: 5%;position: absolute;top: 140px;text-align: left;line-height: 35px">

            <div class="flex ">
                <div class="x-title-left">衬砌参数:</div>
                <div class="flex flex-wrap flex-sub">
                    <div  style="width: 150px" v-for="key of Object.keys(resData[1]['衬砌参数'])">{{ key }}:<span class="x-value">{{resData[1]['衬砌参数'][key]}}</span></div>
                </div>
            </div>

            <div class="flex ">
                <div class="x-title-left">截面参数:</div>
                <div class="flex flex-wrap flex-sub">
                    <div  style="width: 150px" v-for="key of Object.keys(resData[2]['截面参数'])">{{ key }}:<span class="x-value">{{resData[2]['截面参数'][key]}}</span></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "x-LaoLongQiaoZhaQian",

    data() {
        return {
            resData: [],
            nowDate: '',
        }
    },

    created() {
    },

    mounted() {
       this.initData();
        setInterval(() => {
            this.timestampToTime();
        }, 1000 * 3);
    },

    methods: {
        initData(){
            // 广告牌
            this.$request({
                url: `${window.$baseUrl_hz_bz}/led/getVirtualLedShow?virtualDeviceName=路南干渠渠道信息`,
                type: 'get',
            }).then((val) => {
                let data = val.rows
                this.resData = data;
            });
        },

        timestampToTime() {
            let date = new Date();//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let Y = date.getFullYear() + '-';
            let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1):date.getMonth()+1) + '-';
            let D = (date.getDate()< 10 ? '0'+date.getDate():date.getDate())+ ' ';
            let h = (date.getHours() < 10 ? '0'+date.getHours():date.getHours())+ ':';
            let m = (date.getMinutes() < 10 ? '0'+date.getMinutes():date.getMinutes()) + ':';
            let s = date.getSeconds() < 10 ? '0'+date.getSeconds():date.getSeconds();
            this.nowDate = Y + M + D + h + m + s;
        },

        closeClick(){
            this.$emit('on-close')
        }
    }
};
</script>

<style scoped>
.x-page{
    background: url("~@/assets/YongZhenShuiKu/组件03.png") no-repeat;
    background-size: 100% 100%;
    width: 650px;
    height: 500px;
    color: white;
    font-family: 'Source Han Sans CN';
    position: fixed;
    top: 100px;
    left: calc(50% - 325px);
    z-index: 99999;
    margin-left: 55px;
}

.x-title{
    position: absolute;
    top: 40px;
    font-size: 22px;
    width: 100%;
    letter-spacing: 2px;
}

.x-time{
    position: absolute;
    top: 100px;
    font-size: 18px;
    width: 100%;
    color: #08f0ff;
}

.x-value{
    color: #2df280;
}

.x-btn-close{
    width: 50px;
    height: 50px;
    position: absolute;
    right: 27px;
    top: 30px;
    z-index: 9999;
}

.x-title-left{
    width: 100px;
    font-size: 20px;
}

.flex-sub{
    flex: 1;
    /*white-space: nowrap;*/
    //text-overflow: ellipsis;
    //-o-text-overflow: ellipsis;
}
</style>
