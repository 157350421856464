import request from '@/utils/request'

// 查询渗压设备信息列表
export function listOsmoticPressure(query) {
  return request({
    url: '/device/osmoticPressure/list',
    method: 'get',
    params: query
  })
}

// 查询渗压设备信息详细
export function getOsmoticPressure(id) {
  return request({
    url: '/device/osmoticPressure/' + id,
    method: 'get'
  })
}

// 新增渗压设备信息
export function addOsmoticPressure(data) {
  return request({
    url: '/device/osmoticPressure',
    method: 'post',
    data: data
  })
}

// 修改渗压设备信息
export function updateOsmoticPressure(data) {
  return request({
    url: '/device/osmoticPressure',
    method: 'put',
    data: data
  })
}

// 删除渗压设备信息
export function delOsmoticPressure(id) {
  return request({
    url: '/device/osmoticPressure/' + id,
    method: 'delete'
  })
}
