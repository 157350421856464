<template>
	<el-dialog :visible.sync="xiaojiebeiType" width="0px" append-to-body class="el-dialog-xiaojiebeiType"
		:close-on-click-modal="true" :modal="false" :before-close="handleClickxiaojiebeiType">

		<div>
			<div
				:class="this.compData.bindingType == '/elements/jzjcxx/list?pageNum=1&pageSize=99999'?'x-tc-page-xiaojb':'x-tc-page-xiaojbtwo'">
				<div class="x-tcxiaobiao douyuFont">
					{{this.titleName}}
				</div>
				<div class="x-tcguanbi" @click="closeClick">X</div>
				<div
					:class="this.compData.bindingType == '/elements/jzjcxx/list?pageNum=1&pageSize=99999'?'x-tcneik':'x-tcneiktwo'">
					<div>
						<div class="text-left">
							<img src="@/assets/common/基础信息.png" style="width: 250px">
						</div>
						<div class="text-left margin-top"
							v-if="this.compData.bindingType == '/elements/jzjcxx/list?pageNum=1&pageSize=99999'">
							<div class="flex align-center dandukuang">
								<div class="x-xiaoDian"></div>
								<div class="x-kuandu">界桩名称 :</div>
								<div class="margin-left neirongkuandu">{{ xiangqinglist.name  || '--'}}</div>
							</div>
							<div class="flex align-center dandukuang">
								<div class="x-xiaoDian"></div>
								<div class="x-kuandu">界桩编码 :</div>
								<div class="margin-left neirongkuandu">{{ xiangqinglist.code  || '--'}}</div>
							</div>
							<div class="flex align-center dandukuang">
								<div class="x-xiaoDian"></div>
								<div class="x-kuandu">行政区划 :</div>
								<div class="margin-left neirongkuandu">
									{{ getxingzhengquhuaName(xiangqinglist.areaCode)  || '--'}}
								</div>
							</div>
							<div class="flex align-center dandukuang">
								<div class="x-xiaoDian"></div>
								<div class="x-kuandu">类别 :</div>
								<div class="margin-left neirongkuandu">{{ region_type[xiangqinglist?.type]  || '--'}}
								</div>
							</div>
							<div class="flex align-center dandukuang">
								<div class="x-xiaoDian"></div>
								<div class="x-kuandu">界桩类型 :</div>
								<div class="margin-left neirongkuandu">
									{{ jiezhuang_type[xiangqinglist?.identifyType]  || '--'}}
								</div>
							</div>
							<div class="flex align-center dandukuang">
								<div class="x-xiaoDian"></div>
								<div class="x-kuandu">安装时间 :</div>
								<div class="margin-left neirongkuandu">{{ xiangqinglist.installTime  || '--'}}</div>
							</div>

							<div class="flex align-center dandukuang">
								<div class="x-xiaoDian"></div>
								<div class="x-kuandu">维护时间 :</div>
								<div class="margin-left neirongkuandu">{{ xiangqinglist.recentMaintenance  || '--'}}
								</div>
							</div>
							<div class="flex align-center dandukuang">
								<div class="x-xiaoDian"></div>
								<div class="x-kuandu">X坐标 :</div>
								<div class="margin-left neirongkuandu">{{ xiangqinglist.xCoordinate  || '--'}}
								</div>
							</div>
							<div class="flex align-center dandukuang">
								<div class="x-xiaoDian"></div>
								<div class="x-kuandu">Y坐标 :</div>
								<div class="margin-left neirongkuandu">{{ xiangqinglist.yCoordinate  || '--'}}
								</div>
							</div>
							<div class="flex align-center dandukuang">
								<div class="x-xiaoDian"></div>
								<div class="x-kuandu">高程 :</div>
								<div class="margin-left neirongkuandu">{{ xiangqinglist.elevation  || '--'}}
								</div>
							</div>
							<div class="flex align-center dandukuang">
								<div class="x-xiaoDian"></div>
								<div class="x-kuandu">位置 :</div>
								<div class="margin-left neirongkuandu">{{ xiangqinglist.position  || '--'}}
								</div>
							</div>
							<div class="flex align-center dandukuang">
								<div class="x-xiaoDian"></div>
								<div class="x-kuandu">所在类型 :</div>
								<div class="margin-left neirongkuandu">
									{{ getlocationTypeName(xiangqinglist.locationType)  || '--'}}
								</div>
							</div>
							<div class="flex align-center dandukuang">
								<div class="x-xiaoDian"></div>
								<div class="x-kuandu">所在{{optionsRiver[xiangqinglist?.locationType-1].label}} :</div>
								<div class="margin-left neirongkuandu">
									{{ this.fanhuinname}}
								</div>
							</div>
							<div class="flex align-center dandukuang"
								v-if="xiangqinglist.locationType!=null && xiangqinglist.locationType!=''&&xiangqinglist.locationType == 1">
								<div class="x-xiaoDian"></div>
								<div class="x-kuandu">所在岸别 :</div>
								<div class="margin-left neirongkuandu">{{ hh_ab[xiangqinglist.kishibetsu]  || '--'}}
								</div>
							</div>
							<div class="flex align-center dandukuang">
								<div class="x-xiaoDian"></div>
								<div class="x-kuandu">备注信息 :</div>
								<div class="margin-left neirongkuandu">{{ xiangqinglist.remarks  || '--'}}</div>
							</div>
						</div>
						<div class="text-left margin-top"
							v-else-if="this.compData.bindingType == '/water/boundarypoint/list?pageNum=1&pageSize=999999'">
							<div class="flex align-center dandukuang">
								<div class="x-xiaoDian"></div>
								<div class="x-kuandu">名称 :</div>
								<div class="margin-left neirongkuandu">{{ xiangqinglist.name  || '--'}}</div>
							</div>
							<div class="flex align-center dandukuang">
								<div class="x-xiaoDian"></div>
								<div class="x-kuandu">X坐标 :</div>
								<div class="margin-left neirongkuandu">{{ xiangqinglist.xCoordinate  || '--'}}
								</div>
							</div>
							<div class="flex align-center dandukuang">
								<div class="x-xiaoDian"></div>
								<div class="x-kuandu">Y坐标 :</div>
								<div class="margin-left neirongkuandu">{{ xiangqinglist.yCoordinate  || '--'}}
								</div>
							</div>
							<div class="flex align-center dandukuang">
								<div class="x-xiaoDian"></div>
								<div class="x-kuandu">所属水库 :</div>
								<div class="margin-left neirongkuandu">
									{{ getshuikuName(xiangqinglist.reservoirId)  || '--'}}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		</div>
	</el-dialog>
</template>

<script>
	import {
		getJzjcxx, //界桩
		getBillboard, //公示牌
		getBoundarypoint, //界址点
	} from "@/api/station/jiezhuang";
	import {
		listDistrict,
		optionSelect
	} from '@/api/system/district';
	import {
		getDictData
	} from "@/utils/dict";
	import {
		listRivers
	} from "@/api/water/rivers";
	import {
		listLake
	} from "@/api/weather/lake";
	import {
		listReservoir
	} from "@/api/water/reservoir";
	import {
		listIrrigatedArea
	} from "@/api/water/irrigatedArea";
	import {
		listPumpDevice
	} from "@/api/water/pumpDevice";
	import {
		listSluice
	} from "@/api/water/sluice";
	import {
		listChannel
	} from "@/api/water/channel";
	import {
		listPumpHouse
	} from "@/api/water/pumpHouse";
	export default {
		name: "index",
		components: {},
		props: ['compData', 'titleName'],
		data() {
			return {
				loading: true,
				xiaojiebeiType: true,

				optionsLocationCode: [],
				optionSelect: [],
				administrationIdProps: {
					value: 'id',
					label: 'name',
					children: 'children',
					checkStrictly: true, //单选
					expandTrigger: 'hover', //鼠标放上显示下一级
					//multiple: true,//多选
					emitPath: false //返回选中的值  或 返回数组
				},

				optionsRiver: [{
					label: '河流',
					value: 1,
				}, {
					label: '湖泊',
					value: 2
				}, {
					label: '水库',
					value: 3
				}, {
					label: '渠道',
					value: 4
				}, {
					label: '灌区',
					value: 5
				}, {
					label: '水闸',
					value: 6
				}, {
					label: '泵房',
					value: 7
				}],

				xiangqinglist: {},
				shifoujiebei: true,
				region_type: [],
				jiezhuang_type: [],
				identification_plate_type: [],
				hh_ab: [],
				fanhuinname: "",
				reservoirList: [],


			}
		},

		created() {

		},

		mounted() {

			this.getxingzhengquhua()
			getDictData("region_type").then((val) => {
				this.region_type = val;
			});
			getDictData("jiezhuang_type").then((val) => {
				this.jiezhuang_type = val;
				console.log(this.jiezhuang_type)
			});
			getDictData("hh_ab").then((val) => {
				this.hh_ab = val;
			});
			getDictData("identification_plate_type").then((val) => {
				this.identification_plate_type = val;
			});
			if (this.compData.bindingType == '/elements/jzjcxx/list?pageNum=1&pageSize=99999') {
				console.log("打开界碑弹窗:", this.compData.bindingId)
				this.shifoujiebei = true
				this.getjiezhuanglist(this.compData.bindingId)

			} else if (this.compData.bindingType == '/elements/billboard/list?pageNum=1&pageSize=99999') {
				console.log("打开公示牌弹窗:", this.compData.bindingId)
				this.shifoujiebei = false
				this.getgongshipailist(this.compData.bindingId)

			} else if (this.compData.bindingType == '/water/boundarypoint/list?pageNum=1&pageSize=999999') {
				console.log("打开界址点弹窗:", this.compData.bindingId)
				this.shifoujiebei = false
				this.getshuikulist()
				this.getjiezhidianlist(this.compData.bindingId)

			} else if (this.compData.bindingType == '/bzaq/qxdwjl/list?pageNum=1&pageSize=99999') {
				console.log("打开抢险队伍弹窗:", this.compData.bindingId)
			

			}

		},
		beforeDestroy() {

		},
		methods: {

			getshuikuName(id) {
				const position = this.reservoirList.find(
					item => item.id == id
				);
				return position ? position.name : '';
			},
			getshuikulist() {

				listReservoir({
					pageSize: 99999,
					pageNum: 1
				}).then(response => {
					this.reservoirList = response.rows;
					this.irrigatedAreaMap = new Map();
					for (var i = 0; i < this.reservoirList.length; i++) {
						this.irrigatedAreaMap.set(this.reservoirList[i]["id"], this.reservoirList[i]["name"], )
					}
				});
			},
			onCascaderChange(id) {
				console.log(id)
				console.log(this.xiangqinglist.locationType)
				switch (Number(this.xiangqinglist.locationType)) {
					case 1: //河流
						listRivers({
							pageNum: 1,
							pageSize: 99999
						}).then(response => {
							this.optionsLocationCode = response.rows;
							this.heliuMap = new Map();
							for (var i = 0; i < this.optionsLocationCode.length; i++) {
								this.heliuMap.set(this.optionsLocationCode[i]["id"], this.optionsLocationCode[i][
									"name"
								], )
							}
							this.fanhuiname(id)
						});
						break;
					case 2: //湖泊
						listLake({
							pageNum: 1,
							pageSize: 99999
						}).then(response => {
							this.optionsLocationCode = response.rows;
							this.heliuMap = new Map();
							for (var i = 0; i < this.optionsLocationCode.length; i++) {
								this.heliuMap.set(this.optionsLocationCode[i]["id"], this.optionsLocationCode[i][
									"name"
								], )
							}
							this.fanhuiname(id)
						});
						break;
					case 3: //水库
						listReservoir({
							pageNum: 1,
							pageSize: 99999
						}).then(response => {
							this.optionsLocationCode = response.rows;
							this.heliuMap = new Map();
							for (var i = 0; i < this.optionsLocationCode.length; i++) {
								this.heliuMap.set(this.optionsLocationCode[i]["id"], this.optionsLocationCode[i][
									"name"
								], )
							}
							this.fanhuiname(id)
						});
						break;
					case 4: //渠道
						listChannel({
							pageNum: 1,
							pageSize: 99999
						}).then(response => {
							this.optionsLocationCode = response.rows;
							this.heliuMap = new Map();
							for (var i = 0; i < this.optionsLocationCode.length; i++) {
								this.heliuMap.set(this.optionsLocationCode[i]["id"], this.optionsLocationCode[i][
									"name"
								], )
							}
							this.fanhuiname(id)
						});
						break;
					case 5: //灌区
						listIrrigatedArea({
							pageSize: 99999,
							pageNum: 1
						}).then(response => {
							this.optionsLocationCode = response.rows;
							this.heliuMap = new Map();
							for (var i = 0; i < this.optionsLocationCode.length; i++) {
								this.heliuMap.set(this.optionsLocationCode[i]["id"], this.optionsLocationCode[i][
									"name"
								], )
							}
							this.fanhuiname(id)
						});
					case 6: //水闸
						listSluice({
							pageSize: 99999,
							pageNum: 1
						}).then(response => {
							this.optionsLocationCode = response.rows;
							this.heliuMap = new Map();
							for (var i = 0; i < this.optionsLocationCode.length; i++) {
								this.heliuMap.set(this.optionsLocationCode[i]["id"], this.optionsLocationCode[i][
									"name"
								], )
							}
							this.fanhuiname(id)
						});
					case 7: //泵房
						listPumpHouse({
							pageSize: 99999,
							pageNum: 1
						}).then(response => {
							this.optionsLocationCode = response.rows;
							this.heliuMap = new Map();
							for (var i = 0; i < this.optionsLocationCode.length; i++) {
								this.heliuMap.set(this.optionsLocationCode[i]["id"], this.optionsLocationCode[i][
									"name"
								], )
							}
							this.fanhuiname(id)
						});
					default:
						break;
				}
			},
			fanhuiname(id) {
				id = Number(id)
				console.log(id)
				// const position = this.optionsLocationCode.find(
				// 	item => item.id == id
				// );
				for (let i = 0; i < this.optionsLocationCode.length; i++) {
					console.log(this.optionsLocationCode[i].id, id)
					if (this.optionsLocationCode[i].id == id) {
						console.log("+++++++++")
						this.fanhuinname = this.optionsLocationCode[i].name
						// return this.optionsLocationCode[i].name
					}
				}
				// return position ? position.name : '';
			},
			getlocationTypeName(locationType) {
				if (locationType != undefined) {
					return this.optionsRiver.find(option => option.value + '' == locationType).label;
				}
			},

			xiugaitupian(url) {
				let zhuanhuanhou = url.split(',').map(url => url.trim());
				// console.log(zhuanhuanhou)
				return zhuanhuanhou
			},
			getgongshipailist(id) {
				getBillboard(id).then(response => {
					this.xiangqinglist = response.data;
					if (this.xiangqinglist.locationName != null && this.xiangqinglist.locationName != '') {
						console.log("this.xiangqinglist.locationName")
						this.onCascaderChange(this.xiangqinglist.locationName)
					}
				});
			},
			getjiezhuanglist(id) {
				getJzjcxx(id).then(response => {
					this.xiangqinglist = response.data;
					if (this.xiangqinglist.locationName != null && this.xiangqinglist.locationName != '') {
						console.log("this.xiangqinglist.locationName")
						this.onCascaderChange(this.xiangqinglist.locationName)
					}
				});
			},
			getjiezhidianlist(id) {
				getBoundarypoint(id).then(response => {
					this.xiangqinglist = response.data;

				});
			},

			getxingzhengquhua() {
				optionSelect({
					pageNum: 1,
					pageSize: 9999,
				}).then(response => {
					this.optionSelect = response.data;

				});
			},
			getxingzhengquhuaName(codeid) {
				return this.searchByCodeId(this.optionSelect, codeid) || '--';
			},
			searchByCodeId(options, codeid) {
				for (const option of options) {
					if (option.id == codeid) return option.name;
					const childResult = option.children && option.children.length > 0 ?
						this.searchByCodeId(option.children, codeid) :
						null;
					if (childResult) return childResult;
				}
				return null;
			},

			handleClickxiaojiebeiType() {
				this.$emit('on-point')
			},

			closeClick() {
				this.$emit('on-point')
			},


		}
	}
</script>

<style scoped>
	.x-tc-page-bg {
		background: url("~@/assets/common/tanChuang.png") no-repeat;
		background-size: 100% 100%;
		width: 1132px;
		height: 763px;
		position: fixed;
		top: 100px;
		left: 400px;
		z-index: 99999;
		margin-left: 55px;
	}

	.x-tc-page-twobg {
		background: url("~@/assets/common/tanChuang.png") no-repeat;
		background-size: 100% 100%;
		width: 1132px;
		height: 763px;
		position: fixed;
		top: 100px;
		left: 400px;
		z-index: 99999;
		margin-left: 55px;
	}

	.x-tc-title {
		font-size: 22px;
		position: absolute;
		left: 80px;
		top: 15px;
		color: white;
		letter-spacing: 5px;
	}

	.x-close {
		width: 50px;
		height: 50px;
		position: absolute;
		right: 30px;
		cursor: pointer;
	}

	.x-tc-cnt {
		height: 640px;
		width: 1050px;
		overflow: auto;
	}

	.x-card {
		width: 230px;
		height: 270px;
		margin-right: 33px;
		float: left;
	}

	.x-card:nth-child(4n) {
		margin-right: 0px;
	}

	.x-card-cnt {
		width: 250px;
		height: 230px;
	}

	.x-sm-title-bg {
		background: url("~@/assets/ZhiNengXiTong/title.png") no-repeat;
		background-size: 100% 100%;
		width: 220px;
		height: 35px;
		display: flex;
		align-items: center;
		padding-left: 20px;
		font-family: douyuFont-2;
		font-size: 14px;
	}

	.x-table-box {
		height: 680px;
		overflow-y: auto;
		overflow-x: hidden;
		font-size: 14px
	}

	.x-table-title-list-bg {
		width: 98%;
		margin-left: 1%;
		text-align: center;
		line-height: 40px;
		height: 40px;
	}

	.x-table-title-list-bg:nth-child(2n) {
		background: #ffffff10;
	}

	.x-table-title-list-bg:hover {
		background: #ffffff26;
	}

	.zanwushujusptwo {

		color: #218fce;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		font-size: 18px;
		text-align: center;
	}
</style>
<style>
	.youcelunbo .el-carousel__container {
		position: relative;
		width: 650px !important;
	}
</style>
<style scoped>
	.neirongkuandu {
		width: 251px;
	}

	.dialog-buttonstwo {
		text-align: right;
		/* margin-top: 20px; */
		float: right;
		height: 10px;
	}

	.xianshishou {
		cursor: pointer !important;
	}

	.password-error {
		color: red;
		margin-top: 10px;
	}

	.dialog-buttons {
		text-align: right;
		margin-top: 20px;
	}

	.el-input .el-input__icon {
		cursor: pointer;
	}

	.x-info-bg {
		width: 259px;
		height: 82px;
		background: url("~@/assets/YongZhenShuiKu/组 1429.png") no-repeat;
		background-size: 100% 100%;
		position: relative;
	}

	.x-info-title {
		min-width: 186px;
		height: 25px;
		font-size: 16px;
		font-style: italic;
		text-align: left;
		color: #D1D6DF;
		line-height: 32px;
		font-weight: bolder;
		background: linear-gradient(0deg, #ACDDFF 0%, #FFFFFF 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.x-xiaoDian {
		width: 31px;
		height: 31px;
		background: url("~@/assets/YongZhenShuiKu/小点.png") no-repeat;
		background-size: 100% 100%;
		/* 	position: absolute;
		 top: 0;
		 float: left; */
		display: flex;
		align-items: flex-start;
		margin-top: -5px;
	}

	.x-kuandu {
		width: 95px;
		text-align-last: justify;
		/* position: absolute;
		 top: 0;
		 float: left; */
		/* display: flex; align-items: flex-start; */

	}

	.dandukuang {
		/* position: absolute;
		 float: left; */
		align-items: flex-start;
		color: #fff;


	}
</style>
<style scoped>
	.xuanze span {
		background: url("~@/assets/dapingtc/1.png") no-repeat;
		color: #fff;
		float: left;
		width: 265px;
		height: 70px;
		margin-top: 0px;
		margin-left: 5px;
		font-size: 16px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		cursor: pointer;
	}

	.xuanze span.active {
		background: url("~@/assets/dapingtc/2.png") no-repeat;
		text-align: center;
		color: #fff;
		float: left;
		width: 265px;
		height: 70px;
		margin-top: 0px;
		margin-left: 5px;
		font-size: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		cursor: pointer;
	}

	.xuanze {

		width: 835px;
		height: 75px;
		margin-top: 10px;
		/* margin-left: 125px; */
		margin-left: 69px;
		float: left;
		cursor: pointer;
		/* border: #fff solid 1px; */
	}

	.beijingwuse {
		background-color: #0d3955;

	}

	.beijinglanse {
		background-color: #104d7b;

	}

	.biaoti {

		background-color: #104d7b;
		width: 940px;
		height: 61px;
		/* margin-top: 5px; */
		margin-top: 45px;
		margin-left: 72.5px;
		float: left;
		/* border: #fff solid 1px; */

	}

	.bt_span {
		float: left;
		color: #fff;
		margin-top: 0px;
		margin-left: 0px;
		/* width: 188px; */
		height: 61px;
		/* cursor: pointer; */
		/* cursor: inherit; */
		display: block;
		font-size: 18px;
		line-height: 66px;
		font-weight: bold;
		text-align: center
	}

	.nr_span {
		float: left;
		color: #fff;
		margin-top: 0px;
		margin-left: 0px;
		/* width: 188px; */
		height: 54px;
		/* cursor: pointer; */
		/* display: block; */
		font-size: 16px;
		text-align: center;
		/* display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column; */
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		display: inline-block;
		line-height: 55px;
	}

	.neirong {

		/* background-color: #104d7b; */
		width: 940px;
		height: 54px;
		margin-top: 0px;
		margin-left: 0px;
		float: left;
		border-bottom: #1c7bb1 solid 1px;


	}

	.xia {
		width: 940px;
		height: 544px;
		margin-top: 0px;
		margin-left: 72.5px;
		float: left;
		/* border: #fff solid 1px; */
		overflow-x: hidden;
		overflow-y: scroll;
	}

	.fanye {
		width: 940px;
		height: 50px;
		margin-top: 0px;
		margin-left: 72.5px;
		float: left;

	}

	.block {
		height: 46px;
		margin-top: 8px;
		float: right;

	}

	.zuoxia {
		background-color: #104d7b;
		width: 542px;
		height: 68px;
		margin-top: 0px;
		margin-left: 0px;
		float: left;
		border-right: #1c7bb1 solid 2px;

	}

	.youxia {
		background-color: #104d7b;
		width: 542px;
		height: 68px;
		margin-top: 0px;
		margin-left: 0px;
		float: left;
		/* border: #fff solid 1px; */

	}

	.wenzi {

		float: left;
		color: #fff;
		margin-top: 0px;
		margin-left: 0px;
		width: 542px;
		height: 68px;
		/* cursor: pointer; */
		display: block;
		font-size: 20px;
		line-height: 70px;
		/* font-weight: bold; */
		text-align: center
	}
</style>

<style>
	/* .el-icon-close:before {
		content: none !important;
	}

	.el-dialog__headerbtn .el-dialog__close {
		color: #90939900 !important;
	} */
	.el-dialog-xiaojiebeiType .el-dialog__headerbtn {
		display: none !important;
	}
</style>


<style scoped>
	.x-tc-page-xiaojb {
		background: url("~@/assets/HeChangShuMei/img/shuizhatiaokong/beijing.png") no-repeat;
		background-size: 100% 100%;
		width: 346px;
		height: 468px;
		position: fixed;
		top: 50%;
		left: 50%;
		z-index: 99999;
		margin-left: -174px;
		margin-top: -203px;
	}

	.x-tc-page-xiaojbtwo {
		background: url("~@/assets/HeChangShuMei/img/shuizhatiaokong/beijing.png") no-repeat;
		background-size: 100% 100%;
		width: 346px;
		height: 230px;
		position: fixed;
		top: 50%;
		left: 50%;
		z-index: 99999;
		margin-left: -174px;
		margin-top: -98px;
	}

	.x-tcxiaobiao {
		font-size: 16px;
		position: absolute;
		left: 12px;
		top: 15px;
		color: white;
	}

	.x-tcguanbi {
		width: 16px;
		height: 16px;
		position: absolute;
		right: 18px;
		top: 32px;
		font-size: 18px;
		line-height: 17px;
		cursor: pointer;
		color: #fff;
		z-index: 9999;

	}


	.x-tcneik {
		width: 322px;
		height: 425px;
		position: absolute;
		left: 13px;
		top: 39px;
		overflow: hidden;
	}

	.x-tcneiktwo {
		width: 322px;
		height: 190px;
		position: absolute;
		left: 13px;
		top: 39px;
		overflow: hidden;
	}
</style>