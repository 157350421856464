import request from '@/utils/request'

// 查询渠道基础信息列表
export function listChannel(query) {
  return request({
    url: '/water/channel/list',
    method: 'get',
    params: query
  })
}

// 查询渠道基础信息详细
export function getChannel(id) {
  return request({
    url: '/water/channel/' + id,
    method: 'get'
  })
}

// 新增渠道基础信息
export function addChannel(data) {
  return request({
    url: '/water/channel',
    method: 'post',
    data: data
  })
}

// 修改渠道基础信息
export function updateChannel(data) {
  return request({
    url: '/water/channel',
    method: 'put',
    data: data
  })
}

// 删除渠道基础信息
export function delChannel(id) {
  return request({
    url: '/water/channel/' + id,
    method: 'delete'
  })
}
